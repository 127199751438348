import { createColors } from './colors'
import { createFonts } from './fonts'
import { createSpacings, createNewSpacings } from './spacings'
import { createBorderRadius, newBorderRadius } from './borderRadius'
import {
  DefaultTheme,
  createGlobalStyle,
  GlobalStyleComponent,
} from 'styled-components/macro'
import '../../style/normalize.css'
import { inter } from './css-fonts'
import { createNewColors, v3ColorScales } from './newColors'
import { createBorderWidths } from './borderWidths'
import { createPaddings } from './paddings'
import { createFontWeights } from './fontWeights'
import { createMargins, m4 } from './margins'
import { breakpoints } from './breakpoints'
import 'sanitize.css'
export * from './styled.d'

export const createTheme = (): {
  theme: DefaultTheme
  GlobalStyle: GlobalStyleComponent<void, DefaultTheme>
} => {
  // TODO: Make sure fonts and normalize load properly.
  const GlobalStyle = createGlobalStyle`
  ${inter}
  `
  const colors = createColors()
  const fonts = createFonts()
  const spacings = createSpacings()
  const newSpacings = createNewSpacings()
  const borderRadius = createBorderRadius()
  const borderWidths = createBorderWidths()
  const newColors = createNewColors()
  const paddings = createPaddings()
  const fontWeights = createFontWeights()
  const margins = createMargins()

  return {
    theme: {
      v2: {
        colors,
        fonts,
        spacings,
        borderRadius,
      },
      v3: {
        colors: newColors,
        colorScales: v3ColorScales,
        fonts,
        spacings: newSpacings,
        borderRadius: newBorderRadius,
        borderWidths,
        paddings,
        fontWeights,
        margins,
        breakpoints,
      },
      v4: {
        margins: m4(),
      },
    },
    GlobalStyle,
  }
}
