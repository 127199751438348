import { route, param } from './route-creator'
// top level
export const LOGIN = route('login').withQueryParams('banner')
export const FORGOT = route('forgot')
export const REPORTS = '/settings/payout'
export const REPORTS_OLD = '/home/reports'
export const REPORT_EMAILS = `${REPORTS}/emails`
export const REPORT_EMAILS_OLD = '/home/reports/emails'
export const HOME = '/home'
export const STATISTICS = '/statistics'
export const HOME_TAB = route('home', param('tab'))
export const ACHIEVEMENTS = '/achievements'
export const SETTINGS = '/settings'
export const ORGANISATION = '/organisation'
export const GROUPS = '/groups'
export const GROUP = route('group', param('group_id'))
export const CWI = '/cwi'
export const CWI_PRODUCT = route('cwi', param('productId'))
export const MANAGE_ITEM = route(
  'sale',
  param('location'),
  'item'
).withQueryParams('cwi', 'fullprice', 'id', 'active')
export const CREATE_ITEM = route('create')
export const CREATE_ITEM_COPY = route('create', param('copyOfItem'))
export const SALE = route('sale').withQueryParams('tab')
export const INVENTORY = route('sale', 'inventory').withQueryParams('tab')
export const ORDERS = '/orders'
export const SSO = route('sso', param('ssoToken'))
export const ACCOUNT = '/settings/account'
export const ACCOUNT_OLD = '/account'
export const EMAIL_VERIFIED = '/email-verified'
export const ACCEPT_INVITATION = '/accept-invitation'
export const CREATE_SHARED_ACCOUNT = '/create-shared-account'
export const QUICK_START = '/quickstart'
export const SALES_PLANNER = '/salesplanner'
export const KARMA_SCORE = '/karmascore'
export const WHATS_NEW_OLD = '/whats-new'

// settings
export const COMPANY_DETAILS = '/settings/company-details'
export const LOCATION_DETAILS = '/settings/location-details'
export const OPENING_HOURS = '/settings/opening-hours'
export const OPENING_EXCEPTIONS = '/settings/opening-exceptions'
export const PLUGINS = '/settings/plugins'
export const PAYOUT = '/settings/payout'
export const PAYOUT_NEW = '/settings/payout-new'
export const WHATS_NEW = '/settings/whats-new'
export const MANAGE_INVENTORY = route('settings/inventory')
export const MANAGE_INVENTORY_ITEM = route(
  'settings/inventory',
  param('itemId')
)

// Groups / Buisiness units
export const GROUPS_ADD = '/groups/add'
export const GROUP_MEMBER = route(
  'group',
  param('group_id'),
  'member',
  param('location_id')
)
export const GROUP_ACCOUNT = route(
  'group',
  param('group_id'),
  'account',
  param('account')
)
export const GROUP_ADD_MEMBER = route('group', param('group_id'), 'add_member')
export const GROUP_CREATE_ACCOUNT = route(
  'group',
  param('group_id'),
  'create_account'
)

export const GROUP_CREATE_ACCOUNT_NEW = route(
  'group',
  param('group_id'),
  'create_account_new'
)

// Payout
export const PAYOUT_SELECTION = '/settings/payout/selection'
export const PAYOUT_CREATION = '/settings/payout/creation'
export const PAYOUT_DETAILS = route(
  'settings',
  'payout',
  'details',
  param('account')
)

export const ADYEN_PAYOUT_SELECTION = '/settings/payout-new/selection'
export const ADYEN_PAYOUT_CREATION = '/settings/payout-new/creation'
export const ADYEN_PAYOUT_DETAILS = route(
  'settings',
  'payout-new',
  'details',
  param('account')
)

// Organisation
export const ORGANISATION_USERS = '/settings/users'
export const ORGANISATION_USERS_OLD = '/organisation/users'
export const ORGANISATION_CWI = '/settings/cwi'
export const ORGANISATION_CWI_OLD = '/organisation/cwi'
