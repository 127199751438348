import { DateTime } from 'luxon'
import { randomIntFromInterval } from 'utils/numberUtils'
import { getLocationTransactions_location_transactions } from '___generated___/getLocationTransactions'
import { randomImage, randomSentence } from 'apollo/mocks/mockUtils'

const generateOrderItem = () => {
  return {
    count: randomIntFromInterval(2, 10),
    price: 20,
    title: randomSentence({
      amountOfWords: randomIntFromInterval(2, 5),
      wordLength: randomIntFromInterval(3, 7),
    }),
    weight: Math.random() * 12,
    item_id: Math.random() * 124,
    sale_id: Math.random() * 199,
    vat_amount: 12,
    vat_value: 20,
    base_price: Math.random() * 33,
    __typename: 'TransactionItem',
    discount_percentage: 20,
    expiry_date: DateTime.local().toISO(),
    total_price: Math.random() * 22,
    image_url: randomImage(),
  }
}

const generateTransaction = () => {
  const items = Array.from(Array(randomIntFromInterval(1, 4))).map(() =>
    generateOrderItem()
  )
  const shouldHaveRating = Math.random() > 0.5

  const shouldHaveRatingmMotivation = shouldHaveRating && Math.random() > 0.8
  return {
    __typename: 'Transaction',
    amount: Math.random() * 10,
    amount_before_vat: 10,
    currency_code: 'SEK',
    currency_is_prefix: false,
    date_string: DateTime.local().toISO(),
    hasDelivery: Math.random() > 0.5,
    id: `${Math.floor(Math.random() * 99999)}`,
    items_exploded: items,
    meta_data: {
      __typename: 'TransactionMeta',
      cart_id: Math.random() * 31,
      latest_pickup_time: DateTime.local().toISO(),
      location_name: Math.random().toString(36).substring(7),
      timezone: 'Europe/Stockholm',
    },
    name: Math.random().toString(36).substring(7),
    picked_up: Math.random() > 0.5,
    picked_up_date: DateTime.utc().toISO(),
    pickup_date: DateTime.local().toISO(),
    rating: {
      __typename: 'OrderRating',
      score: shouldHaveRating ? null : randomIntFromInterval(1, 5),
      motivation: shouldHaveRatingmMotivation
        ? null
        : randomSentence({
            amountOfWords: randomIntFromInterval(10, 75),
          }),
    },
    state: Math.random() > 0.5 ? 'open' : 'closed',
    vat_amount: 10,
    vat_breakdown: [
      {
        __typename: 'VatBreakdown',
        sum: 10,
        vat: 10,
      },
    ],
    image_url: 'https://source.unsplash.com/featured/?food',
  }
}

export const getMockTransactions = () => {
  const amountToGenerate = randomIntFromInterval(1, 2)
  const amount = Array.from(Array(amountToGenerate))
  return [
    ...amount.map(() => generateTransaction()),
  ] as getLocationTransactions_location_transactions[]
}
