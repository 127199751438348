import { useNotificationBar } from 'design-system/NotificationBar'
import { useIntl } from 'react-intl'
import { randomIntFromInterval } from 'utils/numberUtils'
import { useListenForEvent } from '../hooks/useListenForEvent'

const EMOJI_LIST = ['⭐️', '🥳', '🙌', '🏅', '😘', '😍', '👏', '💪', '👊', '🔥']
const EMOJI_MAX_INDEX = EMOJI_LIST.length - 1
const getRandomEmoji = () => {
  const randomIndex = randomIntFromInterval(0, EMOJI_MAX_INDEX)
  return EMOJI_LIST[randomIndex]
}

export const useListenForFollowers = () => {
  const { showInfoNotification } = useNotificationBar()
  const { formatMessage } = useIntl()
  useListenForEvent('LOCATION_FOLLOWED', 'global.notification', (message) => {
    const {
      payload: { userId },
    } = message
    const emoji = getRandomEmoji()
    showInfoNotification({
      id: `followed.${userId}`,
      message: formatMessage({ id: 'followers.alert.new' }, { emoji }),
    })
  })
}
