import React, { FC } from 'react'
import { CurrentUserProvider } from 'components/UserState/CurrentUserProvider'
import { TutorialProvider } from 'providers/tutorial/TutorialProvider'
import { ModalProvider } from 'providers/modal/ModalProvider'
import { RealtimeProvider } from './realtime/RealtimeProvider'
import { SelectProfile } from 'components/LocationUserManagement/Profile/SelectProfile'
import { NewAchievementCheck } from 'components/Achievement/NewAchievementCheck'
import { ProfileEventRegistration } from 'components/ProfileEvents'
import { GlobalRealtimeListeners } from './realtime/GlobalRealtimeListeners'
import { ReleaseGuideSlideShowOverlay } from 'components/ReleaseGuideSlideShow'

export const AuthenticatedProviders: FC = ({ children }) => {
  return (
    <CurrentUserProvider>
      <ReleaseGuideSlideShowOverlay>
        <TutorialProvider>
          <ModalProvider>
            <SelectProfile>
              <ProfileEventRegistration>
                <NewAchievementCheck>
                  <RealtimeProvider>
                    <GlobalRealtimeListeners>
                      {children}
                    </GlobalRealtimeListeners>
                  </RealtimeProvider>
                </NewAchievementCheck>
              </ProfileEventRegistration>
            </SelectProfile>
          </ModalProvider>
        </TutorialProvider>
      </ReleaseGuideSlideShowOverlay>
    </CurrentUserProvider>
  )
}
