export const toNumberWithSomeSpace = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const toPercentage = (numerator: number, denominator: number) => {
  if (!denominator) {
    return '-'
  }
  if (!numerator) {
    return '0%'
  }
  return `${Math.round((numerator / denominator) * 100)}%`
}

export const roundToTwoDecimals = (n: number) => Math.round(n * 1e2) / 1e2

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randomValueFromArray = (array: any[]) => {
  const pos = randomIntFromInterval(0, array.length - 1)
  return array[pos]
}

export const hashCode = (str: string) => {
  // java String#hashCode
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export const intToHexColor = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()

  return `#${'00000'.substring(0, 6 - c.length) + c}`
}

function intToHsl(number: number) {
  const GOLDEN_ANGLE_APRX = 137.508
  const hue = number * GOLDEN_ANGLE_APRX
  return `hsl(${hue},50%,75%)`
}

export const stringToHexColor = (str: string) => intToHsl(hashCode(str))
