import React from 'react'
import get from 'lodash/get'
import compose from 'lodash/flowRight'
import { Container, Logo, AcceptButton } from './styles'
import { injectIntl } from 'react-intl'
import { graphql } from '@apollo/client/react/hoc'
import { InfoBanner } from '../InfoBanner'
import logo from 'static/Logo/KarmaK.svg'
import * as queries from './queries'
import { H1 } from 'design-system/Typography/H1'
import { Button } from 'design-system/Button'
import { Paragraph } from 'design-system/Typography/Paragraph'

class AsUserBase extends React.Component {
  state = {
    showErrorBanner: false,
  }

  acceptInvitation = () => {
    const { invitationToken, acceptInvitation, history } = this.props

    acceptInvitation({ invitationToken }).then((res) => {
      const success = get(
        res,
        ['data', 'acceptInvitationAuthed', 'success'],
        false
      )
      if (success) {
        history.push('/')
      } else {
        this.setState({ showErrorBanner: true })
      }
    })
  }

  render() {
    const { locationName, intl } = this.props
    const { showErrorBanner } = this.state
    const errorText = intl.formatMessage({
      id: 'errors.general.contactSupport',
    })

    return (
      <>
        {showErrorBanner && <InfoBanner text={errorText} />}
        <Container>
          <Logo src={logo} />
          <H1>
            {intl.formatMessage({ id: 'invitation.header' }, { locationName })}
          </H1>
          <Paragraph color="grey" variant="medium">
            {intl.formatMessage(
              { id: 'invitation.description' },
              { locationName }
            )}
          </Paragraph>
          <AcceptButton>
            <Button
              onClick={this.acceptInvitation}
              actionType="primary"
              colorScheme="positive"
            >
              <Paragraph color="white" variant="medium">
                {intl.formatMessage({ id: 'invitation.button.accept' })}
              </Paragraph>
            </Button>
          </AcceptButton>
        </Container>
      </>
    )
  }
}

const enhance = compose(
  graphql(queries.ACCEPT_INVITATION_AUTHED, {
    props: ({ mutate }) => ({
      acceptInvitation: (variables) =>
        mutate({
          variables: {
            token: variables.invitationToken,
          },
        }),
    }),
  }),
  injectIntl
)
export const AsUser = enhance(AsUserBase)
