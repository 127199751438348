import React, { FC } from 'react'
import useGetProfiles from 'components/LocationUserManagement/hooks/useGetProfiles'
import useSelectProfile from 'components/LocationUserManagement/hooks/useSelectProfile'
import { SelectProfilesMenu } from 'components/Menu/SelectProfileSideMenu.styles'
import { useMenu } from 'components/Menu/Menu'
import { Select } from 'design-system/Select'
import { useIntl } from 'react-intl'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'

const SelectProfileSideMenu: FC = () => {
  const { user } = useCurrentUser()
  const { formatMessage } = useIntl()
  const { canChangeProfile, profiles, loading } = useGetProfiles({
    userId: user.id,
  })
  const { setSelectedProfile, selectedProfileId } = useSelectProfile()
  const { closeMenu } = useMenu()
  if (!canChangeProfile || loading) {
    return <></>
  }
  const handleSelectProfile = (id: string) => {
    setSelectedProfile(id)
    closeMenu()
  }
  return (
    <SelectProfilesMenu>
      <Select
        label={formatMessage({
          id: 'usermanagement.profile.menu.title',
        })}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          event.preventDefault()
          handleSelectProfile(event.target.value)
        }}
        value={selectedProfileId}
      >
        {profiles?.map((profile, key) => {
          return (
            <option value={profile.id} key={key}>
              {profile.name}
            </option>
          )
        })}
      </Select>
    </SelectProfilesMenu>
  )
}

export { SelectProfileSideMenu }
