import React from 'react'
import styled from 'styled-components/macro'
import range from 'lodash/range'

interface Props {
  total: number
  current: number
  dotSize: number
}

interface DotProps {
  size: number
  active: boolean
}

const ProgressDotBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dot = (props: DotProps) => {
  const size = props.active ? props.size * 1.3 : props.size
  return (
    <svg
      viewBox="0 0 10 10"
      width={size}
      height={size}
      style={{ marginRight: props.size / 2 }}
    >
      <circle
        cx="5"
        cy="5"
        r="4"
        fill="#fff"
        opacity={props.active ? 1 : 0.5}
      />
    </svg>
  )
}

export const ProgressDots = (props: Props) => {
  const tatalRange = range(0, props.total)

  return (
    <ProgressDotBar>
      {tatalRange.map((idx) => {
        const isActive = idx === props.current
        return <Dot active={isActive} size={props.dotSize} key={idx} />
      })}
    </ProgressDotBar>
  )
}
