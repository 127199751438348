import { RefObject, useEffect } from 'react'

interface Params {
  ref: RefObject<HTMLElement>
  callback: () => void
  shouldListen: boolean
  onListen?: VoidFunction
}

const useOutsideClick = ({ ref, callback, shouldListen, onListen }: Params) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const clickedElement = e.target as Element
      if (ref.current && !ref.current.contains(clickedElement)) {
        callback()
      }
    }
    if (shouldListen) {
      document.addEventListener('click', handleClick)
      if (onListen) {
        onListen()
      }
    } else {
      document.removeEventListener('click', handleClick)
    }
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [callback, ref, shouldListen, onListen])
}

export default useOutsideClick
