import { hasPermission } from 'utils/permissionTools'

export const hasPayoutPermission = (userPermissionSet: string[] | null) => {
  if (userPermissionSet === null) {
    return false
  }

  return hasPermission(userPermissionSet, [
    'FINANCIAL_INFO_CREATE',
    'FINANCIAL_INFO_READ',
    'FINANCIAL_INFO_UPDATE',
    'FINANCIAL_INFO_DELETE',
  ])
}
