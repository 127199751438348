import React from 'react'
import * as Sentry from '@sentry/react'
import { useIntl } from 'react-intl'
import warning from '../../../static/images/warning.svg'
import { Flex, Icon, Wrapper } from './styles'
import { Span } from 'design-system/Typography/Span'
import { H1 } from 'design-system/Typography/H1'

const ErrorBoundaryComponent: React.FC = ({ children }) => {
  const { formatMessage } = useIntl()
  return (
    <Sentry.ErrorBoundary
      fallback={
        <Wrapper>
          <Flex>
            <Icon src={warning} alt="Warning icon" />
            <H1>
              {formatMessage({
                id: 'errorComponent.title',
              })}
            </H1>
          </Flex>
          <Span>
            {formatMessage({
              id: 'errorComponent.body',
            })}
          </Span>
        </Wrapper>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundaryComponent
