import { ColorScale } from '..'

export const blue: ColorScale = {
  0: '#FFFFFF',
  10: '#EAF4FB',
  20: '#D5E8F7',
  30: '#BFDDF3',
  40: '#AAD2EF',
  50: '#95C6EA',
  60: '#55A5DE',
  70: '#2B8ED6',
  80: '#266FA4',
  90: ' #206394',
  100: '#07395C',
}
