import React, { ChangeEvent, FC, useState } from 'react'
import useCreateProfile from 'components/LocationUserManagement/hooks/useCreateProfile'
import {
  SmallModal,
  ModalButtonRow,
} from 'components/LocationUserManagement/styles'
import { H3 } from 'design-system/Typography/H3'
import { TextField } from 'design-system/TextField'
import { Span } from 'design-system/Typography/Span'
import useActiveLocation from 'components/Menu/hooks/useActiveLocation'
import { Button, ButtonSpinner } from 'design-system/Button'
import { useModal } from 'providers/modal/hooks/useModal'
import { useIntl } from 'react-intl'

const CreateProfileModal: FC = () => {
  const { formatMessage } = useIntl()
  const { createProfile, loading } = useCreateProfile()
  const { location: activeLocation } = useActiveLocation()
  const [name, setName] = useState('')
  const { closeModal } = useModal({ modalContent: null })

  const onCreate = async () => {
    try {
      await createProfile({ name })
      closeModal()
    } catch (e) {}
  }
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }
  const invalidName = name === ''
  return (
    <SmallModal>
      <div>
        <H3>
          {formatMessage(
            { id: 'usermanagement.profile.add.title' },
            { locationName: activeLocation?.name }
          )}
        </H3>
      </div>
      <div>
        <TextField
          label={formatMessage({ id: 'usermanagement.profile.name' })}
          onChange={handleNameChange}
          onEnter={onCreate}
        />
        <ModalButtonRow>
          <Button
            colorScheme="positive"
            onClick={onCreate}
            disabled={loading || invalidName}
          >
            {loading && <ButtonSpinner />}
            <Span>{formatMessage({ id: 'common.save' })}</Span>
          </Button>
          <Button colorScheme="secondary" onClick={closeModal}>
            <Span>{formatMessage({ id: 'common.cancel' })}</Span>
          </Button>
        </ModalButtonRow>
      </div>
    </SmallModal>
  )
}

export { CreateProfileModal }
