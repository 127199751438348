import React from 'react'
import { NewColor } from 'design-system/theme/newColors'
import { Header, HeaderProps } from './Header'

interface Props extends Omit<HeaderProps, 'size'> {
  color?: NewColor
  id?: string
}

const H1: React.FC<Props> = ({ children, color = 'black', id, ...props }) => {
  return (
    <Header size="h1" color={color} id={id} {...props}>
      {children}
    </Header>
  )
}

export { H1 }
