import styled from 'styled-components/macro'

export interface FieldWrapperProps {
  fluid: boolean
}
export const FieldWrapper = styled.div<FieldWrapperProps>`
  ${({ fluid }) => !fluid && 'display: inline-block;'}
`

export type FieldSize = 'small' | 'medium' | 'large'
interface InputProps {
  fieldSize?: FieldSize
  hasLeading: boolean
  hasTrailing: boolean
}
const FieldSizeToStyle = ({ fieldSize }: InputProps) => {
  if (!fieldSize) return 'height: 40px;'
  switch (fieldSize) {
    case 'small':
      return 'height: 30px;'
    case 'medium':
      return 'height: 40px;'
    case 'large':
      return 'height: 50px;'
    default:
      return null
  }
}

interface WrapperProps {
  size?: number
  hasFocus?: boolean
  locked?: boolean
}
export const StyledInputWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  background-color: ${({ theme }) => theme.v3.colors.white};
  color: ${({ theme }) => theme.v3.colors.black};
  border: none;
  margin: 0 2px 2px 2px; /* Because box shadow might be cut off if no margin to the sides */
  box-shadow: ${({ theme, hasFocus, locked }) => {
    if (hasFocus) {
      return `0 0 0 2px ${theme.v3.colors.black}, 0 2px 4px rgba(0, 0, 0, 0.16)`
    }
    if (locked) {
      return `0 0 0 2px ${theme.v3.colors.locked}, 0 2px 4px rgba(0, 0, 0, 0.16)`
    }
    return '0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.16)'
  }};
  ${({ size }) => !size && 'width: calc(100% -4px);'}/* 4px from the margins */
`

export const StyledInput = styled.input<InputProps>`
  padding-left: ${({ hasLeading }) => (hasLeading ? '8px' : '16px')};
  padding-right: ${({ hasTrailing }) => (hasTrailing ? '8px' : '16px')};
  outline: none;
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  background-color: ${({ theme }) => theme.v3.colors.white};
  border: none;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  font-size: 14px;
  ${FieldSizeToStyle}
`

export const FieldLabels = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${({ theme }) => theme.v3.margins.typography.headings.small};
  & > *:first-child {
    flex: 1;
  }
`

export const FieldLeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
`

export const FieldTrailing = styled.div<{ locked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  ${({ theme, locked }) => {
    if (locked) {
      return `color: ${theme.v3.colors.locked}`
    }
  }};
`

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Message = styled.span`
  color: ${({ theme }) => theme.v2.colors.grayscale[80]};
`
