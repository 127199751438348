import React from 'react'
import { DefaultTheme } from 'styled-components/macro'
import {
  LabelContainer,
  StyledSelect,
  MessageContainer,
  Message,
} from './styles'
import { Span } from 'design-system/Typography/Span'

type Ref = HTMLSelectElement
export interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  theme?: DefaultTheme
  label?: string
  error?: string
  message?: string
  id?: string
  locked?: boolean
}

export const Select = React.forwardRef<Ref, Props>(
  (
    { theme, error, message, label, children, id, locked, disabled, ...rest },
    ref
  ) => (
    <>
      {label && (
        <LabelContainer>
          <Span fontWeight={600}>{label}</Span>
        </LabelContainer>
      )}
      <StyledSelect
        disabled={disabled || locked}
        locked={locked}
        id={id}
        ref={ref}
        {...rest}
      >
        {children}
      </StyledSelect>
      {error ? (
        <MessageContainer>
          <Message error>{error}</Message>
        </MessageContainer>
      ) : message ? (
        <MessageContainer>
          <Message>{message}</Message>
        </MessageContainer>
      ) : null}
    </>
  )
)
