import * as Sentry from '@sentry/react'
import { FC } from 'react'

export const sentryCaptureException = <T>(component: FC<T>, err: any) => {
  Sentry.withScope((scope) => {
    scope.setExtras({ component: component.name })
    Sentry.captureException(err)
  })
}

export const sentryCaptureExceptionInFunction = (
  functionName: string,
  err: any
) => {
  Sentry.withScope((scope) => {
    scope.setExtras({ function: functionName })
    Sentry.captureException(err)
  })
}
