import { DateTime } from 'luxon'

const HOLIDAY = ({
  isHandled = false,
  name = 'Special Holiday',
  weeksFromNow = 3,
} = {}) => {
  const start = DateTime.local()
  return {
    __typename: 'Holiday',
    date: start.plus({ week: weeksFromNow }).toFormat('yyyy-MM-dd'),
    name,
    isHandled,
  }
}

export default HOLIDAY
