import { ColorScale } from '..'

export const yellow: ColorScale = {
  0: '#FFFFFF',
  10: '#FEF6E6',
  20: '#FEEDCE',
  30: '#FDE3B5',
  40: '#FED894',
  50: '#FBCD7A',
  60: '#FABF53',
  70: '#F8A30A',
  80: '#E28903',
  90: ' #CD7C02',
  100: '#814E00',
}
