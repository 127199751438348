import { ISODate } from '@karmalicious/ktl-iso-date'
import { ExceptionalSaleTimes, ISaleTimes } from 'components/SaleTimes/types'
import { DateTime, Interval } from 'luxon'
import { DayOfWeek } from './daysOfWeek'
import { assertValidType } from './isValidType'

export const isSameDay = (d1: DateTime, d2: DateTime) => {
  return (
    d1.hasSame(d2, 'year') && d1.hasSame(d2, 'month') && d1.hasSame(d2, 'day')
  )
}

export const isDateToday = (d1: DateTime) => {
  const today = DateTime.local()
  return isSameDay(today, d1)
}

export const getTomorrowISODate = () => {
  return DateTime.local().plus({ day: 1 }).toISODate() as ISODate
}

export const getTodaysISODate = () => {
  return DateTime.local().toISODate() as ISODate
}

export const getWeekdayForISODate = (date: ISODate): DayOfWeek => {
  const asDateTime = DateTime.fromISO(date, { locale: 'en' })
  return asDateTime.weekdayLong.toLowerCase() as DayOfWeek
}

export const getSaleTimesWithExceptions = (
  date: ISODate,
  saleTimes: ISaleTimes,
  exceptionalSaleTimes: ExceptionalSaleTimes[]
): ISaleTimes => {
  const exception = dateHasExceptionalSaleTimes(date, exceptionalSaleTimes)
  if (exception) {
    return { ...exception, weekday: saleTimes.weekday }
  }
  return saleTimes
}

export const getFirstDayOfCurrentMonth = (): ISODate => {
  const unverified = DateTime.local().startOf('month').toISODate()
  return assertValidType(unverified, ISODate)
}

export const dateHasExceptionalSaleTimes = (
  date: ISODate,
  exceptionalSaleTimes: ExceptionalSaleTimes[]
) => {
  const dateAsDateTime = DateTime.fromISO(date)
  const [firstMatch] = exceptionalSaleTimes.filter((exception) => {
    const interval = Interval.fromDateTimes(
      DateTime.fromISO(exception.start_date),
      DateTime.fromISO(exception.end_date)
    )
    return interval.contains(dateAsDateTime)
  })
  return firstMatch
}

/**
 * Returns yyyy-MM-dd of all dates between two dates, including the ones entered
 */
export const getDatesBetween = (startDate: DateTime, endDate: DateTime) => {
  let dateArray: string[] = []
  let currentDate = startDate
  while (currentDate <= endDate) {
    dateArray = [...dateArray, currentDate.toFormat('yyyy-MM-dd')]
    currentDate = currentDate.plus({ days: 1 })
  }
  return dateArray
}
