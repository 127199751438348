import { Panel } from 'design-system/Panel'
import styled from 'styled-components/macro'

export const ItemImage = styled.img`
  width: 100%;
  height: 100%;
`

export const ItemImageWrapper = styled.div`
  grid-area: image;
  align-self: stretch;
`

export const ItemPanel = styled(Panel)`
  margin-bottom: ${({ theme }) => theme.v3.margins.content.spacing.medium};
  display: grid;
  align-items: center;
  grid-template-columns: 50px 150px auto;
  grid-template-rows: 50px auto;
  column-gap: ${({ theme }) => theme.v3.margins.content.spacing.small};
  row-gap: ${({ theme }) => theme.v3.margins.content.spacing.small};
  grid-template-areas:
    'image header header'
    'description description description'
    'category category toggle';
`
export const ItemHeader = styled.div`
  grid-area: header;
  display: flex;
  align-self: stretch;
  justify-self: stretch;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: ${({ theme }) => theme.v3.margins.content.spacing.small};
  }
`

export const ItemDescription = styled.div`
  grid-area: description;
  display: flex;
  flex-direction: column;
  /* margin-left: ${({ theme }) => theme.v3.margins.content.spacing.medium}; */
`

export const ItemTagList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.small};
`

export const ItemTag = styled.div`
  padding: ${({ theme }) => theme.v3.paddings.badge.dense};
  background-color: ${({ theme }) => theme.v3.colors.mint};
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
`

export const ItemActionWrapper = styled.div`
  grid-area: toggle;
  align-items: center;
  display: flex;
  justify-self: flex-end;
  & > * {
    margin-left: ${({ theme }) => theme.v3.margins.content.spacing.small};
  }
`
export const ItemSelctionWrapper = styled.div`
  grid-area: category;
`
