import React from 'react'
import { getSvgProps } from './utils'
import { DesignSystemIcon } from './types'

export const Pastry: DesignSystemIcon = (props) => {
  return (
    <svg viewBox="0 0 30 30" {...getSvgProps(props)}>
      <path
        d="M19 6.001C17.609 6.001 15.587 6.384 13.723 7.316C13.892 7.496 14 7.734 14 8C14 8.552 13.552 9 13 9C12.568 9 12.209 8.723 12.069 8.34C10.318 9.678 9 11.667 9 14.508C9 16.771 10.76 20.999 15 20.999C18.093 20.999 19.853 18.13 19.987 15.998C19.441 15.99 19 15.548 19 15C19 14.601 19.238 14.262 19.576 14.102C19.081 13.06 18.102 12 16.387 12C15.545 12 14 12.876 14 14.308C14 15.661 15.017 15.975 15.221 16.025C15.752 16.154 16.089 16.687 15.968 17.22C15.847 17.753 15.323 18.091 14.793 17.979C13.827 17.773 12 16.743 12 14.308C12 11.639 14.555 10 16.387 10C20.216 10 22 13.423 22 15.737C22 16.15 21.951 16.585 21.862 17.028C21.909 17.021 21.951 17 22 17C22.552 17 23 17.448 23 18C23 18.552 22.552 19 22 19C21.734 19 21.495 18.892 21.316 18.723C20.91 19.624 20.32 20.483 19.561 21.195C19.82 21.375 20 21.661 20 22C20 22.552 19.552 23 19 23C18.544 23 18.175 22.689 18.055 22.272C17.177 22.726 16.158 22.999 15 22.999C10.738 22.999 8.261 19.909 7.377 16.924C7.261 16.971 7.134 17 7 17C6.448 17 6 16.552 6 16C6 15.448 6.448 15 7 15C7.008 15 7.015 15.004 7.023 15.005C7.011 14.837 7 14.669 7 14.508C7 13.162 7.247 11.981 7.653 10.93C7.273 10.788 7 10.429 7 10C7 9.448 7.448 9 8 9C8.209 9 8.392 9.079 8.553 9.189C11.092 5.377 16.147 4.001 19 4.001C19.291 4.001 19.583 4.017 19.873 4.04C18.383 3.377 16.737 3 15 3C8.373 3 3 8.373 3 15C3 21.627 8.373 27 15 27C21.627 27 27 21.627 27 15C27 11.978 25.875 9.225 24.031 7.115C22.698 6.589 20.806 6.001 19 6.001ZM19 9C18.448 9 18 8.552 18 8C18 7.448 18.448 7 19 7C19.552 7 20 7.448 20 8C20 8.552 19.552 9 19 9ZM24 14C23.448 14 23 13.552 23 13C23 12.448 23.448 12 24 12C24.552 12 25 12.448 25 13C25 13.552 24.552 14 24 14Z"
        style={{ stroke: 'transparent' }}
        fill={props.color}
      />
    </svg>
  )
}
