import { gql } from '@apollo/client'

export const SET_USER = gql`
  mutation setUser($id: ID!) {
    setUser(id: $id) @client
  }
`

export const GET_ACTIVE_USER = gql`
  query getActiveUser {
    activeUser @client {
      id
    }
  }
`

export const SET_ACTIVE_LOCATION = gql`
  mutation setActiveLocation($locationId: ID!) {
    setActiveLocation(id: $locationId) {
      success
    }
  }
`
