import { useQuery } from '@apollo/client'
import {
  getLocationInvitations,
  getLocationInvitationsVariables,
} from '___generated___/getLocationInvitations'
import { GET_INVITATIONS } from 'components/LocationUserManagement/queries'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { roles as UserRoles } from 'utils/userRole'

const useUserManagement = () => {
  const { locationId, user } = useCurrentUser()
  const companyAdmin =
    user.role === UserRoles.ADMIN || user.role === UserRoles.KARMELEON
  const { data, loading, error } = useQuery<
    getLocationInvitations,
    getLocationInvitationsVariables
  >(GET_INVITATIONS, {
    skip: !companyAdmin, // Will this cause errors?
    variables: { locationId },
  })

  const { users: allUsers, invitations } = data?.location ?? {}
  const sharedAccount = user.isSharedAccount
    ? user
    : allUsers?.find((user) => user?.isSharedAccount)
  const users = allUsers?.filter((user) => user && !user.isSharedAccount)
  const pendingSharedAccount = invitations?.find(
    (invitation) => invitation?.isSharedAccount
  )
  const pendingInvitations = invitations?.filter(
    (invitation) => !invitation?.isSharedAccount
  )
  return {
    sharedAccount,
    pendingSharedAccount,
    users,
    pendingInvitations,
    loading,
    error,
  }
}

export default useUserManagement
