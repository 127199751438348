import React, { useCallback, useEffect, useState } from 'react'
import { createContext } from 'react'

export type AppStateStatus = 'active' | 'background' | 'inactive'

type AppStateHandler = {
  key: string
  callback: (status: AppStateStatus) => void
}

interface AppStateContextValue {
  addHandler: (handler: AppStateHandler) => void
  removeHandler: (key: string) => void
}

export const AppStateContext = createContext<AppStateContextValue>({
  addHandler: () => {},
  removeHandler: () => {},
})

export const AppStateProvider: React.FC = ({ children }) => {
  const [handlers, setHandlers] = useState<AppStateHandler[]>([])

  useEffect(() => {
    const W = window as any
    W.handleAppStateChange = (status: AppStateStatus) => {
      handlers.map((h) => h.callback(status))
    }
  }, [handlers])

  const addHandler = useCallback(
    (newHandler: AppStateHandler) => {
      setHandlers((handlers) => {
        const oldHandlers = handlers.filter((h) => h.key !== newHandler.key)
        return [...oldHandlers, newHandler]
      })
    },
    [setHandlers]
  )
  const removeHandler = useCallback(
    (key: string) => {
      setHandlers((handlers) => handlers.filter((h) => h.key !== key))
    },
    [setHandlers]
  )

  return (
    <AppStateContext.Provider value={{ addHandler, removeHandler }}>
      {children}
    </AppStateContext.Provider>
  )
}
