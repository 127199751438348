import React, { FC } from 'react'
import { useScreenSize } from 'design-system/theme/breakpoints'

interface Props {
  children: (props: {
    width: Number
    height: number
    small: boolean
    large: boolean
  }) => JSX.Element | JSX.Element[]
}

export class Media extends React.Component<Props> {
  state = { width: 0, height: 0 }
  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    const { width, height } = this.state
    const small = width < 1000
    const large = !small
    return <>{this.props.children({ width, height, small, large })}</>
  }
}

export const SmallScreen: FC = ({ children }) => {
  const screenSize = useScreenSize()
  if (screenSize === 'small') {
    return <>{children}</>
  }
  return <></>
}

export const LargeScreen: FC = ({ children }) => {
  const screenSize = useScreenSize()
  if (screenSize !== 'small') {
    return <>{children}</>
  }
  return <></>
}
