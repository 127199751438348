import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { HeaderProps } from 'design-system/Typography/Header'

interface Props {
  bottomMargin?: HeaderProps['bottomMargin']
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
}

export const H1Style = styled.h1<Props>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.v2.fonts.default};
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -0.02em;
  margin: 0 0
    ${({ theme, bottomMargin }) =>
      bottomMargin ? theme.v4.margins.typography.headings[bottomMargin] : 0};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  &:hover a {
    display: inline-block;
  }
`

export const H2Style = styled.h2<Props>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.v2.fonts.default};
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.02em;
  margin: 0 0
    ${({ theme, bottomMargin }) =>
      bottomMargin ? theme.v4.margins.typography.headings[bottomMargin] : 0};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  &:hover a {
    display: inline-block;
  }
`

export const H3Style = styled.h3<Props>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.v2.fonts.default};
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  margin: 0 0
    ${({ theme, bottomMargin }) =>
      bottomMargin ? theme.v4.margins.typography.headings[bottomMargin] : 0};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  &:hover a {
    display: inline-block;
  }
`

export const H4Style = styled.h4<Props>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.v2.fonts.default};
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.02em;
  margin: 0 0
    ${({ theme, bottomMargin }) =>
      bottomMargin ? theme.v4.margins.typography.headings[bottomMargin] : 0};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  &:hover a {
    display: inline-block;
  }
`

export const H5Style = styled.h5<Props>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.v2.fonts.default};
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.02em;
  margin: 0 0
    ${({ theme, bottomMargin }) =>
      bottomMargin ? theme.v4.margins.typography.headings[bottomMargin] : 0};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  &:hover a {
    display: inline-block;
  }
`

export const Anchor = styled(Link)`
  margin-left: 5px;
  display: none;
  text-decoration: none;
  color: ${({ theme }) => theme.v3.colors.grey};
  &:hover {
    color: ${({ theme }) => theme.v3.colors.black};
  }
`
