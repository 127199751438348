import React from 'react'
import { NewColor } from 'design-system/theme/newColors'
import { Header, HeaderProps } from './Header'

interface Props extends Omit<HeaderProps, 'size'> {
  color?: NewColor
  id?: string
  className?: string
}

const H5: React.FC<Props> = ({
  children,
  color = 'black',
  id,
  className,
  ...props
}) => {
  return (
    <Header size="h5" color={color} id={id} className={className} {...props}>
      {children}
    </Header>
  )
}

export { H5 }
