import React, { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import Loading from '../components/Loading'
import { RestrictedRoute } from './RestrictedRoute'
import * as paths from './paths'
import { ConfirmNewSharedAccount } from 'pages/ConfirmNewSharedAccount'
import { ACHIEVEMENTS, HOME_TAB, KARMA_SCORE } from './paths'
import { lazyWithPreload } from 'routes/lazyImport'

const Login = lazyWithPreload(() => import('../pages/Login'))
const ForgotPassword = lazyWithPreload(() => import('../pages/ForgotPassword'))
const Statistics = lazyWithPreload(() => import('../pages/Statistics'))
const Sale = lazyWithPreload(() => import('../pages/Sale'))
const ManageItem = lazyWithPreload(() => import('../pages/ManageItem'))
export const CreateItem = lazyWithPreload(
  () => import('../pages/CreateItem/CreateItemPage')
)
const Settings = lazyWithPreload(() => import('../pages/Settings'))
const Transactions = lazyWithPreload(() => import('../pages/Transactions'))
const SSO = lazyWithPreload(() => import('../pages/Dashboard/SSO'))
const VerifyEmail = lazyWithPreload(() => import('../pages/VerifyEmail'))
const AcceptInvitation = lazyWithPreload(
  () => import('../pages/AcceptInvitation')
)
const CwiForm = lazyWithPreload(() => import('../pages/CwiForm'))
const QuickStartSale = lazyWithPreload(
  () => import('../pages/QuickStartSale/index.js')
)
const Basic = lazyWithPreload(() => import('../pages/Basic'))
const KarmaScore = lazyWithPreload(() => import('../pages/KarmaScore'))

const preloadRoutes = async () => {
  await Promise.all([
    Sale.preload(),
    Settings.preload(),
    Transactions.preload(),
    ManageItem.preload(),
    QuickStartSale.preload(),
    KarmaScore.preload(),
    Statistics.preload(),
  ])
}

const Routes = () => {
  preloadRoutes().then()
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Redirect from="/payment" to={paths.PAYOUT} />
        <Redirect from="/payout" to={paths.PAYOUT} />
        <Redirect from="/payout-new" to={paths.PAYOUT_NEW} />
        <Redirect exact from="/settings/general" to={paths.PLUGINS} />
        <Redirect exact from="/reports/emails" to={paths.REPORT_EMAILS} />
        <Redirect exact from="/reports" to={paths.REPORTS} />
        <Redirect
          exact
          from={paths.REPORT_EMAILS_OLD}
          to={paths.REPORT_EMAILS}
        />
        <Redirect exact from={paths.REPORTS_OLD} to={paths.REPORTS} />
        <Redirect exact from={KARMA_SCORE} to={ACHIEVEMENTS} />
        <Redirect
          exact
          from={HOME_TAB.create({ tab: 'karmascore' })}
          to={ACHIEVEMENTS}
        />
        <Redirect
          exact
          from={paths.ORGANISATION_USERS_OLD}
          to={paths.ORGANISATION_USERS}
        />
        <Redirect from="/home" to={paths.STATISTICS} />
        <Redirect exact from={paths.ACCOUNT_OLD} to={paths.ACCOUNT} />
        <Redirect exact from={paths.ORGANISATION} to={paths.SETTINGS} />
        <Redirect
          exact
          from={paths.ORGANISATION_USERS_OLD}
          to={paths.ORGANISATION_USERS}
        />
        <Redirect
          exact
          from={paths.ORGANISATION_CWI_OLD}
          to={paths.ORGANISATION_CWI}
        />
        <Redirect exact from={paths.WHATS_NEW_OLD} to={paths.WHATS_NEW} />
        <RestrictedRoute
          path={paths.LOGIN.template()}
          restrict={['unauthenticated']}
          component={Login}
        />
        <RestrictedRoute
          path={paths.FORGOT.template()}
          restrict={['unauthenticated']}
          component={ForgotPassword}
        />
        <RestrictedRoute
          path={paths.STATISTICS}
          restrict={['authenticated']}
          component={Statistics}
        />
        <RestrictedRoute
          path={[paths.SETTINGS, paths.GROUPS, paths.GROUP.template()]}
          restrict={['authenticated']}
          component={Settings}
        />
        <RestrictedRoute
          restrict={['authenticated']}
          path={[paths.CWI_PRODUCT.template(), paths.CWI]}
          component={CwiForm}
        />
        <RestrictedRoute
          restrict={['pro']}
          path={paths.MANAGE_ITEM.template()}
          component={ManageItem}
        />
        <RestrictedRoute
          restrict={['pro']}
          path={paths.CREATE_ITEM_COPY.template()}
          component={CreateItem}
        />
        <RestrictedRoute
          restrict={['pro']}
          path={paths.CREATE_ITEM.template()}
          component={CreateItem}
        />
        <RestrictedRoute
          restrict={['pro']}
          path={paths.SALE.template()}
          component={Sale}
        />
        <RestrictedRoute
          restrict={['pro']}
          path={paths.INVENTORY.template()}
          component={Sale}
        />
        <RestrictedRoute
          restrict={['authenticated']}
          path={paths.ORDERS}
          component={Transactions}
        />
        <Route path={paths.SSO.template()} component={SSO} />
        <Route path={paths.EMAIL_VERIFIED} component={VerifyEmail} />
        <Route path={paths.ACCEPT_INVITATION} component={AcceptInvitation} />
        <Route
          path={paths.CREATE_SHARED_ACCOUNT}
          component={ConfirmNewSharedAccount}
        />
        <RestrictedRoute
          restrict={['authenticated']}
          path={paths.QUICK_START}
          component={QuickStartSale}
        />
        <RestrictedRoute
          restrict={['basic']}
          path={paths.SALES_PLANNER}
          component={Basic}
        />
        <RestrictedRoute
          restrict={['authenticated']}
          path={paths.ACHIEVEMENTS}
          component={KarmaScore}
        />
        <Redirect to={paths.LOGIN.template()} />
      </Switch>
    </Suspense>
  )
}

export default Routes
