import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Flex = styled.div`
  display: flex;
  align-items: center;
`
export const Icon = styled.img`
  height: 20px;
  width: 20px;
  padding-right: 5px;
`
