import styled from 'styled-components/macro'

export const LocaleSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LocaleSelect = styled.select`
  border: none;
  background: transparent;
`
