import { gql, useQuery } from '@apollo/client'
import { getActivePayoutAccount } from '___generated___/getActivePayoutAccount'
import sumBy from 'lodash/sumBy'
import { hasPayoutPermission } from 'pages/Payout/hasPayoutPermission'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'

const GET_ACTIVE_PAYOUT_ACCOUNT = gql`
  query getActivePayoutAccount($storefront: Boolean) {
    current {
      location {
        payoutAccountActive(storefront: $storefront) {
          id
          vatId
          taxId
          name
          kind
          balance {
            amount
            currency
          }
          externalAccount {
            bankName
            last4
            routingNumber
            status
          }
          status {
            chargesEnabled
            payoutsEnabled
            currentDeadlineTs
            currentlyDue
            disabledReason
            eventuallyDue
            pastDue
            pendingVerification
            available
          }
        }
      }
    }
  }
`
const CENTS_TO_WHOLE = 100

const useActivePayoutAccounts = ({
  storefront,
}: { storefront?: boolean } = {}) => {
  const { user } = useCurrentUser()
  const hasPayoutAccess = hasPayoutPermission(user.permission_set)
  const { data, loading, error } = useQuery<getActivePayoutAccount>(
    GET_ACTIVE_PAYOUT_ACCOUNT,
    {
      skip: !hasPayoutAccess,
      variables: {
        storefront,
      },
    }
  )
  const totalBalance =
    sumBy(data?.current.location?.payoutAccountActive?.balance, 'amount') /
    CENTS_TO_WHOLE
  const account = data?.current.location?.payoutAccountActive
  const hasInvalidPayoutStatus =
    !loading &&
    totalBalance > 0 &&
    !account?.status?.payoutsEnabled &&
    hasPayoutAccess

  return {
    account,
    totalBalance,
    loading,
    error,
    hasAccess: hasPayoutAccess,
    hasInvalidPayoutStatus,
  }
}

export default useActivePayoutAccounts
