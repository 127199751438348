const ALL_TUTORIALS = {
  FIRST_TIME_VIDEO: {
    id: 'video-welcome-first-time',
  },
  FIRST_TIME_CREATE_ITEM_NAVIGATE: {
    id: 'first-time-item-create-pro-navigate',
  },
  FIRST_TIME_CREATE_ITEM_START: {
    id: 'first-time-sale-pro-create',
  },
  FIRST_TIME_START_SALE_NAVIGATE: {
    id: 'first-time-sale-pro-start-navigate',
  },
  FIRST_TIME_ADD_PRODUCT_TO_SALE: {
    id: 'first-time-sale-pro-add',
  },
  FIRST_TIME_EDIT_OPENING_HOURS: {
    id: 'sale-set-opening-hours-1',
  },
  FIRST_TIME_START_SALE_GO_LIVE: {
    id: 'start-sale-pro',
  },
  FIRST_TIME_SALE_PRO_SEARCH: {
    id: 'first-time-sale-pro-search',
  },
  BASIC_TUTORIAL_GETTING_STARTED_ID: {
    id: 'basic-tutorial-getting-started',
  },
  BASIC_TUTORIAL_ITEM_DESCRIPTION_ID: {
    id: 'basic-tutorial-item-description',
  },
  BASIC_TUTORIAL_ITEM_AMOUNT_ID: {
    id: 'basic-tutorial-item-amount',
  },
  BASIC_TUTORIAL_ITEM_PRICE_ID: {
    id: 'basic-tutorial-item-price',
  },
  BASIC_TUTORIAL_ITEM_ALLERGENS_ID: {
    id: 'basic-tutorial-item-allergens',
  },
  BASIC_TUTORIAL_OPENING_HOURS_ID: {
    id: 'basic-tutorial-opening-hours',
  },
  BASIC_TUTORIAL_BUSINESS_TYPE_ID: {
    id: 'basic-tutorial-business-type',
  },
  CREATE_SHARED_ACCOUNT: {
    id: 'create-shared-account',
  },
} as const

type Keys = keyof typeof ALL_TUTORIALS
export type TutorialId = typeof ALL_TUTORIALS[Keys]['id']

export { ALL_TUTORIALS }
