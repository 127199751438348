import { Decoder } from 'io-ts'

export function isValidType<T>(
  value: unknown,
  decoder: Decoder<unknown, T>
): value is T {
  return decoder.decode(value)._tag === 'Right'
}

export const assertValidType = <T>(
  value: unknown,
  decoder: Decoder<unknown, T>
): T => {
  const decoded = decoder.decode(value)
  if (decoded._tag === 'Left') {
    throw new Error(`Expected ${decoder.name} but got ${JSON.stringify(value)}`)
  }
  return decoded.right
}
