import { useQuery } from '@apollo/client'
import { Status, SalePeriod } from '___generated___/globalTypes'
import { ItemsOnSaleQuery, SaleTimes } from 'pages/Sale/types'
import { GET_ITEMS_ON_SALE } from 'pages/Sale/queries'
import { DateTime } from 'luxon'
import { getItemsOnSale_location_currency } from '___generated___/getItemsOnSale'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { ISODate } from '@karmalicious/ktl-iso-date'
import { getTodaysISODate } from 'utils/dateUtils'
import { useListenForEvent } from 'providers/realtime/hooks/useListenForEvent'

interface UseSaleQueryArgs {
  locationId?: string
  saleDate?: ISODate
}

export const useSaleQuery = ({
  locationId: providedLocationId,
  ...props
}: UseSaleQueryArgs = {}) => {
  const saleDate = props.saleDate || getTodaysISODate()
  const {
    user: { activeLocation: currentLocationId },
  } = useCurrentUser()
  const locationId = providedLocationId || currentLocationId
  const saleQuery = useQuery<ItemsOnSaleQuery.Data, ItemsOnSaleQuery.Variables>(
    GET_ITEMS_ON_SALE,
    {
      fetchPolicy: 'cache-first',
      variables: { locationId, saleDate },
    }
  )
  // Sale item counts have been updated, refresh query
  useListenForEvent('PAYMENT_CREATED', 'useSaleQuery.refetch', () => {
    saleQuery.refetch()
  })
  const saleQueryRefetch = async () => {
    await saleQuery?.refetch()
  }

  const saleStatus = saleQuery.data?.location?.sale?.status ?? Status.closed
  const saleExists = !!saleQuery.data?.location?.sale?.id ?? false
  const itemsOnSaleCount =
    saleQuery.data?.location?.sale?.numberOfItemsOnSale ?? 0
  const itemsOnSale = (saleQuery.data?.location?.sale?.activeItems ?? []).map(
    (item) => {
      const saleInfo =
        item.saleInfo?.filter((si) => {
          const somethingAdded = si.originalCount > 0
          const saleHasNotPassed =
            DateTime.fromISO(si.expiryDate).toISODate() >= saleDate
          return somethingAdded && saleHasNotPassed
        }) ?? []
      return {
        ...item,
        saleInfo,
      }
    }
  )
  const surplusItemsOnSale = itemsOnSale.filter((item) => !item.full_price)
  const {
    total: itemsOnSaleIndividualCount,
    totalSold: itemSoldIndividualCount,
    surplus: surplusOnSaleTodayCount,
  } = itemsOnSale.reduce(
    (acc, item) => {
      if (item.full_price) {
        const fpiSoldCount = item.saleInfo.reduce((acc, saleInfo) => {
          return acc + saleInfo.soldCount
        }, 0)
        return {
          total: acc.total + 1,
          surplus: acc.surplus,
          fpi: acc.fpi + 1,
          totalSold: acc.totalSold + fpiSoldCount,
        }
      }
      const {
        onSale: surplusOnSaleTodayCount,
        sold: surplusSoldTodayCount,
      } = item.saleInfo.reduce(
        (acc, saleInfo) => {
          return {
            onSale: acc.onSale + saleInfo.availableCount,
            sold: acc.sold + saleInfo.soldCount,
          }
        },
        {
          onSale: 0,
          sold: 0,
        }
      )
      return {
        total: acc.total + surplusOnSaleTodayCount,
        surplus: acc.surplus + surplusOnSaleTodayCount,
        fpi: acc.fpi,
        totalSold: acc.totalSold + surplusSoldTodayCount,
      }
    },
    {
      total: 0,
      surplus: 0,
      fpi: 0,
      totalSold: 0,
    }
  )
  const hasShortExpiry = saleQuery.data?.location?.has_short_expiry ?? false
  const hasBundleItemsFlag = saleQuery.data?.location?.has_item_bundles ?? false
  const hasFullprice = saleQuery.data?.location?.hasFullprice ?? false
  const hasBundledItemsForSale = itemsOnSale.some(
    (i) => i.is_bundled_item && i.saleInfo.some((si) => si.originalCount > 0)
  )
  const {
    sale_start_local: saleStart,
    sale_end_local: saleEnd,
    pickup_start_local: pickupStart,
    pickup_end_local: pickupEnd,
  } = saleQuery.data?.location?.todaysSaleTimes ?? {}

  const canBundleItems =
    hasBundleItemsFlag &&
    saleStatus === Status.undecided &&
    !hasBundledItemsForSale

  const saleTimes: SaleTimes = {
    saleStart,
    saleEnd,
    pickupStart,
    pickupEnd,
  }
  const currency =
    saleQuery.data?.location?.currency ??
    ({
      symbol: 'kr',
      is_prefix: false,
      code: 'SEK',
    } as getItemsOnSale_location_currency)
  const allSaleTimes = saleQuery.data?.location?.saleTimes ?? []

  const todaysWeekDay = DateTime.local().setLocale('en-US').weekdayLong
  const todaysSaleTimes =
    allSaleTimes.filter(
      (saleTime) =>
        saleTime?.weekday.toLowerCase() === todaysWeekDay.toLowerCase()
    ) ?? []
  const todaysSaleTimesGrouped = todaysSaleTimes.reduce(
    (acc, saleTime) => {
      return {
        ...acc,
        [saleTime?.period ? `${saleTime?.period}` : 'allday']: {
          saleEnd: saleTime?.sale_end_local,
          saleStart: saleTime?.sale_start_local,
          pickupEnd: saleTime?.pickup_end_local,
          pickupStart: saleTime?.pickup_start_local,
        } as SaleTimes,
      }
    },
    {
      [SalePeriod.BREAKFAST]: {} as SaleTimes,
      [SalePeriod.AFTERNOON]: {} as SaleTimes,
      allday: {} as SaleTimes,
    }
  )

  const itemsFullPrice = saleQuery.data?.location?.itemsFullPrice ?? []

  const hasAnyItemsOnSale =
    surplusItemsOnSale.length > 0 || itemsFullPrice.length > 0
  return {
    loading: saleQuery.loading,
    saleExists,
    saleQuery,
    hasShortExpiry,
    canBundleItems,
    hasFullprice,
    hasBundleItemsFlag,
    saleStatus,
    saleQueryRefetch,
    hasBundledItemsForSale,
    itemsOnSaleIndividualCount,
    itemsOnSaleCount,
    itemsOnSale,
    itemSoldIndividualCount,
    saleTimes,
    todaysSaleTimes: todaysSaleTimesGrouped,
    currency,
    itemsFullPrice,
    surplusItemsOnSale,
    surplusOnSaleTodayCount,
    canStartSale: hasAnyItemsOnSale,
  }
}
