import React from 'react'
import styled from 'styled-components/macro'
import { NewColor, useColor } from 'design-system/theme/newColors'
import { Margins4 } from 'design-system/theme/margins'

interface Props {
  color?: NewColor
  customColor?: string
  bottomMargin?: keyof Margins4['typography']['headings']
}

const Style = styled.h1<{ bottomMargin?: Props['bottomMargin'] }>`
  color: ${({ color }) => color};
  font-weight: 900;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin: 0 0
    ${({ theme, bottomMargin }) =>
      bottomMargin ? theme.v4.margins.typography.headings[bottomMargin] : 0};
`

const LargeDisplay: React.FC<Props> = ({
  children,
  color = 'black',
  customColor,
  bottomMargin,
}) => {
  const colorHex = useColor(color)
  return (
    <Style bottomMargin={bottomMargin} color={customColor || colorHex}>
      {children}
    </Style>
  )
}

export { LargeDisplay }
