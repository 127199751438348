import styled from 'styled-components/macro'
import selectCaret from 'static/images/arrow-down.svg'

export const StyledSelect = styled.select<{ locked?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 20px 10px 10px;
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  outline: none;
  appearance: none;
  cursor: pointer;
  font-size: 14px;
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.16);
  background-size: unset;

  :disabled {
    cursor: default;
  }

  ${({ disabled, locked, theme }) => {
    if (locked) {
      return `
        box-shadow: 0 0 0 2px ${theme.v3.colors.locked}, 0 2px 4px rgba(0, 0, 0, 0.16);
      `
    }
    return (
      !disabled &&
      `
    background: url(${selectCaret}) right 10px center no-repeat;
    background-color: ${theme.v3.colors.white};
  `
    )
  }}
  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 1), 0 2px 4px rgba(0, 0, 0, 0.16);
  }
`

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.v3.margins.content.spacing.small};
`

export const Label = styled.label`
  color: ${({ theme }) => theme.v2.colors.grayscale[100]};
  font-family: ${({ theme }) => theme.v2.fonts.bold};
  padding: 0;
  margin: 0;
`

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 27px;
`

interface MessageProps {
  error?: boolean
}
export const Message = styled.p<MessageProps>`
  color: ${({ theme, error }) =>
    error ? theme.v2.colors.yellow[70] : theme.v2.colors.grayscale[80]};
  margin: 0;
  padding: 0;
  line-height: 13px;
`
