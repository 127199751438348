import React, { FC, useEffect } from 'react'
import useGetActiveProfile from 'components/LocationUserManagement/hooks/useGetActiveProfile'
import { useModal } from 'providers/modal/hooks/useModal'
import { NewAchievementsModal } from 'components/Achievement/NewAchievementsModal'
import useMarkCompletedAchievement from 'components/Achievement/hooks/useMarkCompletedAchievement'

interface Props {}

const NewAchievementCheck: FC<Props> = ({ children }) => {
  const { completedUnSeenAchievements } = useGetActiveProfile()
  const { markAsSeen } = useMarkCompletedAchievement()
  const { openModal, isModalOpen } = useModal({
    modalContent: <NewAchievementsModal />,
    onModalClose: markAsSeen,
  })
  useEffect(() => {
    if (completedUnSeenAchievements.length > 0 && !isModalOpen) {
      //openModal()
    }
  }, [openModal, completedUnSeenAchievements, isModalOpen])
  return <>{children}</>
}

export { NewAchievementCheck }
