import React from 'react'
import styled from 'styled-components/macro'

const StyledCard = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background-color: ${({ theme, color }) =>
    color ? color : theme.v3.colorScales.grey[20]};
  border-radius: 10px;
  box-shadow: inset 0 -1px 1px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0);
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
`

const CardTitle = styled.h2`
  font-weight: 400;
  font-size: 26px;
  margin: 0;
`

const CardSubtitle = styled.span<any>`
  color: ${({ theme }) => theme.v3.colorScales.grey[80]};
  margin-bottom: 5px;
`

const CardDescription = styled.p<any>`
  font-size: 16px;
  color: ${({ theme }) => theme.v3.colorScales.grey[70]};
  margin: 0;
`

const CardLabel = styled.span<any>`
  font-weight: 600;
  color: ${({ theme }) => theme.v3.colorScales.grey[70]};
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export class Card extends React.PureComponent<Props> {
  public static Title = CardTitle
  public static Description = CardDescription
  public static Label = CardLabel
  public static Subtitle = CardSubtitle

  render() {
    const { children, ...props } = this.props as any
    return (
      <StyledCard {...props} displayName="Card">
        {this.props.children}
      </StyledCard>
    )
  }
}
