export const getSaleTimes = () => {
  return [
    {
      weekday: 'monday',
      closed: false,
      sale_start_local: '16:45',
      sale_end_local: '20:45',
      pickup_start_local: '16:45',
      pickup_end_local: '20:45',
      period: null,
      __typename: 'SaleTimes',
    },
    {
      weekday: 'monday',
      closed: false,
      sale_start_local: '16:45',
      sale_end_local: '20:45',
      pickup_start_local: '16:45',
      pickup_end_local: '20:45',
      period: 'AFTERNOON',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'monday',
      closed: false,
      sale_start_local: '16:45',
      sale_end_local: '20:45',
      pickup_start_local: '16:45',
      pickup_end_local: '20:45',
      period: 'BREAKFAST',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'tuesday',
      closed: false,
      sale_start_local: '09:00',
      sale_end_local: '21:45',
      pickup_start_local: '09:15',
      pickup_end_local: '21:45',
      period: null,
      __typename: 'SaleTimes',
    },
    {
      weekday: 'tuesday',
      closed: false,
      sale_start_local: '09:00',
      sale_end_local: '21:45',
      pickup_start_local: '09:15',
      pickup_end_local: '21:45',
      period: 'AFTERNOON',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'tuesday',
      closed: false,
      sale_start_local: '09:00',
      sale_end_local: '21:45',
      pickup_start_local: '09:15',
      pickup_end_local: '21:45',
      period: 'BREAKFAST',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'wednesday',
      closed: false,
      sale_start_local: '09:30',
      sale_end_local: '16:00',
      pickup_start_local: '09:30',
      pickup_end_local: '16:45',
      period: null,
      __typename: 'SaleTimes',
    },
    {
      weekday: 'wednesday',
      closed: false,
      sale_start_local: '09:30',
      sale_end_local: '16:00',
      pickup_start_local: '09:30',
      pickup_end_local: '16:45',
      period: 'AFTERNOON',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'wednesday',
      closed: false,
      sale_start_local: '09:30',
      sale_end_local: '16:00',
      pickup_start_local: '09:30',
      pickup_end_local: '16:45',
      period: 'BREAKFAST',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'thursday',
      closed: false,
      sale_start_local: '07:00',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '13:45',
      period: null,
      __typename: 'SaleTimes',
    },
    {
      weekday: 'thursday',
      closed: false,
      sale_start_local: '07:00',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '13:45',
      period: 'AFTERNOON',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'thursday',
      closed: false,
      sale_start_local: '07:00',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '13:45',
      period: 'BREAKFAST',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'friday',
      closed: true,
      sale_start_local: '07:15',
      sale_end_local: '18:00',
      pickup_start_local: '10:00',
      pickup_end_local: '22:00',
      period: null,
      __typename: 'SaleTimes',
    },
    {
      weekday: 'friday',
      closed: true,
      sale_start_local: '07:15',
      sale_end_local: '18:00',
      pickup_start_local: '10:00',
      pickup_end_local: '22:00',
      period: 'AFTERNOON',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'friday',
      closed: true,
      sale_start_local: '07:15',
      sale_end_local: '18:00',
      pickup_start_local: '10:00',
      pickup_end_local: '22:00',
      period: 'BREAKFAST',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'saturday',
      closed: false,
      sale_start_local: '12:30',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '21:00',
      period: null,
      __typename: 'SaleTimes',
    },
    {
      weekday: 'saturday',
      closed: false,
      sale_start_local: '12:30',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '21:00',
      period: 'AFTERNOON',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'saturday',
      closed: false,
      sale_start_local: '12:30',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '21:00',
      period: 'BREAKFAST',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'sunday',
      closed: true,
      sale_start_local: '12:30',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '21:00',
      period: null,
      __typename: 'SaleTimes',
    },
    {
      weekday: 'sunday',
      closed: true,
      sale_start_local: '12:30',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '21:00',
      period: 'AFTERNOON',
      __typename: 'SaleTimes',
    },
    {
      weekday: 'sunday',
      closed: true,
      sale_start_local: '12:30',
      sale_end_local: '18:00',
      pickup_start_local: '13:00',
      pickup_end_local: '21:00',
      period: 'BREAKFAST',
      __typename: 'SaleTimes',
    },
  ]
}
