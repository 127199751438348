import React from 'react'
import styled from 'styled-components/macro'
import { NewColor, useColor } from 'design-system/theme/newColors'
import { Margins4 } from 'design-system/theme/margins'

type Weight = 400 | 500 | 600

interface Props {
  color?: NewColor
  variant?: 'large' | 'medium' | 'small'
  weight?: Weight
  title?: string
  colorHex?: string
  className?: string
  bottomMargin?: keyof Margins4['typography']['headings']
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
}

interface PProps {
  weight: Weight
  bottomMargin?: Props['bottomMargin']
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
}

const P = styled.p<PProps>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.v2.fonts.default};
  font-weight: ${({ weight }) => weight ?? 400};
  margin: 0;
  text-decoration-color: ${({ color }) => color};
  margin-bottom: ${({ theme, bottomMargin }) =>
    bottomMargin ? theme.v4.margins.typography.headings[bottomMargin] : 0};
  text-align: ${({ textAlign }) => textAlign ?? 'inherit'};
`
const Large = styled(P)`
  font-size: 16px;
  line-height: 22px;
`
const Medium = styled(P)`
  font-size: 14px;
  line-height: 20px;
`
const Small = styled(P)`
  font-size: 12px;
  line-height: 18px;
`

const Paragraph: React.FC<Props> = ({
  children,
  variant = 'medium',
  weight = 400,
  color = 'black',
  colorHex,
  title,
  className,
  bottomMargin,
  textAlign,
}) => {
  const themeColor = useColor(color)
  const paragraphColor = colorHex ?? themeColor
  switch (variant) {
    case 'large':
      return (
        <Large
          weight={weight}
          color={paragraphColor}
          title={title}
          className={className}
          bottomMargin={bottomMargin}
          textAlign={textAlign}
        >
          {children}
        </Large>
      )
    case 'medium':
      return (
        <Medium
          weight={weight}
          color={paragraphColor}
          title={title}
          className={className}
          bottomMargin={bottomMargin}
          textAlign={textAlign}
        >
          {children}
        </Medium>
      )
    case 'small':
      return (
        <Small
          weight={weight}
          color={paragraphColor}
          title={title}
          className={className}
          bottomMargin={bottomMargin}
          textAlign={textAlign}
        >
          {children}
        </Small>
      )
    default:
      return (
        <Medium
          weight={weight}
          color={paragraphColor}
          title={title}
          className={className}
          bottomMargin={bottomMargin}
          textAlign={textAlign}
        >
          {children}
        </Medium>
      )
  }
}

export { Paragraph }
