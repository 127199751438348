import styled from 'styled-components/macro'
import { Span } from 'design-system/Typography/Span'
import { LayoutGrid } from 'design-system/LayoutGrid'
import { NewColor } from 'design-system/theme/newColors'

/**
 * Navbar
 */

export const DesktopSpacing = styled.div`
  min-height: 75px;
  width: 100%;
`

export const NavWrapper = styled(LayoutGrid)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 200001;
  border-top: 1px solid ${({ theme }) => theme.v3.colors.lighterGrey};
  background: ${({ theme }) => theme.v3.colors.white};
  box-sizing: border-box;
  height: calc(env(safe-area-inset-bottom) + 50px);
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    height: calc(env(safe-area-inset-bottom) + 75px);
    border-top: none;
    border-bottom: 1px solid ${({ theme }) => theme.v3.colors.lighterGrey};
    top: 0;
  }
`
export const MobileSpacing = styled.div`
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
`

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  grid-area: 1 / 1 / 1 / -1;
  align-items: flex-start;
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    grid-area: 1 / 1 / 1 / 10;
    justify-content: flex-start;
  }
`

export const NavButton = styled.button<{ active: boolean; color: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  color: ${({ color }) => color};
  overflow: hidden;
  flex-direction: column;
  padding: 5px 0;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  background-color: ${({ theme }) => theme.v3.colors.white};
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  ${({ theme }) => theme.v3.breakpoints.small.mediaQueryWithLimit} {
    flex: 1;
  }
  ${({ theme }) => theme.v3.breakpoints.medium.mediaQueryWithLimit} {
    flex: 1;
    flex-direction: row;
  }
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    padding: 0;
    flex-direction: row;
    &:nth-child(n + 2) {
      margin-left: ${({ theme }) =>
        theme.v3.margins.content.spacing.extraLarge};
    }
  }
`

export const NavIconLabel = styled(Span)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  padding: 0;
  ${({ theme }) => theme.v3.breakpoints.medium.mediaQuery} {
    margin-left: ${({ theme }) => theme.v3.margins.typography.inline.medium};
  }
`

export const NavBarLogo = styled.div`
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 5000%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.v3.colors.turtle};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const NavBarLogoImage = styled.img`
  height: 100%;
`

export const NavButtonBadge = styled.div<{ backgroundColor?: NewColor }>`
  display: flex;
  height: 18px;
  min-width: 18px;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  margin-left: ${({ theme }) => theme.v3.margins.content.spacing.small};
  background: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.v3.colors[backgroundColor] : theme.v3.colors.jade};
  & > * {
    margin: 0 ${({ theme }) => theme.v3.paddings.badge.dense};
  }
  ${({ theme }) => theme.v3.breakpoints.small.mediaQuery} {
    position: absolute;
    top: 2px;
    right: 0;
  }
`

export const NonActionNavButtonBadge = styled.div`
  border: 1px solid ${({ theme }) => theme.v3.colors.black};
  display: flex;
  height: 18px;
  min-width: 18px;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  margin-left: ${({ theme }) => theme.v3.margins.content.spacing.small};
  background-color: ${({ theme }) => theme.v3.colors.white};
  & > * {
    margin: 0 ${({ theme }) => theme.v3.paddings.badge.dense};
  }
  ${({ theme }) => theme.v3.breakpoints.small.mediaQuery} {
    position: absolute;
    top: 2px;
    right: 0;
  }
`

export const NavBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    flex-direction: row;
  }
`

export const DesktopContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

export const MobileContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

export const MobileNavHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.v4.margins.content.medium} 0;
  z-index: 10;
`

export const MobileNavHeaderOpenMenu = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.v4.margins.content.medium};
`
