import React, { FC, CSSProperties } from 'react'
import Spinkit, { SpinnerProps } from 'react-spinkit'
import styled from 'styled-components/macro'
import { NewColor, useColor } from 'design-system/theme/newColors'

type SpinnerType = SpinnerProps['name']

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
interface Props {
  loading: boolean
  type?: SpinnerType
  style?: CSSProperties
  color?: NewColor
}

export const Spinner: FC<Props> = ({
  loading,
  type = 'circle',
  color = 'karma',
  style = {},
}) => {
  const spinColor = useColor(color)
  return (
    <Flex>
      <Spinkit
        name={type}
        fadeIn="none"
        color={spinColor}
        style={{
          ...style,
          display: loading ? 'block' : 'none',
        }}
      />
    </Flex>
  )
}
