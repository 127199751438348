import React, { FC } from 'react'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { UpdatePasswordPrompt } from '../UpdatePasswordPrompt'
import LocalState from '../LocalState'
import ErrorBoundaryComponent from 'components/ErrorBoundary/Component'
import Loading from '../LoadingPage'
import { getUserState } from '___generated___/getUserState'
import { TUserState } from './types'
import { serveSaveRedirect } from 'utils/redirect'

export const GET_USER = gql`
  query getUserState($id: ID!) {
    user(userId: $id, useToken: false) {
      id
      username
      email
      name
      created_date
      updated_password
      isSharedAccount
      location {
        id
        uuid
        name
        created_date
        currency {
          symbol
          is_prefix
          code
        }
        country {
          id
          alpha2
        }
        role
        permission_set
        beta {
          food_preferences
          cwi
          new_dashboard
        }
        hasAutosale
        hasFullprice
        has_short_expiry
        betaFlags {
          key
          value
        }
        dvh
        company {
          id
          plan
        }
        hasPod
        karmaBasic
        loyalty_discount
        noSurplusToday
        betaClient
        timezone {
          id
          timezone
        }
        supportedPaymentProviders
      }
    }
  }
`
interface Props {
  render: (userState: TUserState) => JSX.Element | null | JSX.Element[]
}

const UserState: FC<Props> = ({ render }) => {
  return (
    <LocalState
      render={({ loading, user }) => {
        if (loading || !user) {
          return <Loading />
        }

        if (!user) {
          throw new Error('Missing user object')
        }

        return (
          <Query<getUserState>
            query={GET_USER}
            skip={!user.id}
            variables={{ id: user.id }}
          >
            {({ loading, error, data }) => {
              if (error) {
                throw error
              }
              if (loading || !data || !data.user || !data.user.location) {
                return <Loading />
              }
              const activeLocation = data.user.location.id
              const { role, permission_set, betaClient } = data.user.location
              const redirect = serveSaveRedirect(
                Boolean(betaClient),
                window.location.host
              )
              if (redirect) {
                window.location.assign(`https://${redirect}`)
              }

              const enhancedUser = Object.assign({}, data.user, {
                role,
                permission_set: permission_set as string[] | null,
                activeLocation,
              })
              const userLocation = enhancedUser.location
              window.analytics.updateProfile({
                name: enhancedUser.name,
                email: enhancedUser.email,
                user_id: enhancedUser.id,
                companies: userLocation
                  ? [
                      {
                        id: userLocation.id,
                        name: userLocation.name,
                        karma_company_id: userLocation.company?.id,
                      },
                    ]
                  : [],
              })

              if (!enhancedUser.updated_password) {
                return <UpdatePasswordPrompt id={enhancedUser.id} />
              }
              return (
                <ErrorBoundaryComponent>
                  {render({
                    error,
                    user: enhancedUser,
                  })}
                </ErrorBoundaryComponent>
              )
            }}
          </Query>
        )
      }}
    />
  )
}

export default UserState
