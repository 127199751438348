import React from 'react'
import { SettingsCardParagraph } from './styles'
import { H3 } from 'design-system/Typography/H3'
import { Paragraph } from 'design-system/Typography/Paragraph'
import { Spinner } from 'design-system/Spinner'

interface Props {
  title?: string
  titleId?: string
  text?: string
  color?: string
  loading?: boolean
}

const SettingsCard: React.FC<Props> = ({
  title,
  titleId,
  text,
  loading,
  children,
}) => {
  return (
    <div>
      {title && <H3 id={titleId}>{title}</H3>}
      {text && (
        <SettingsCardParagraph>
          <Paragraph>{text}</Paragraph>
        </SettingsCardParagraph>
      )}
      {loading ? (
        <Spinner
          loading={true}
          style={{ width: '60px', height: '60px' }}
          color="black"
          type="circle"
        />
      ) : (
        children && children
      )}
    </div>
  )
}

export default SettingsCard
