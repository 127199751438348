import { ISODate } from '@karmalicious/ktl-iso-date'
import { Button } from 'design-system/Button'
import get from 'lodash/get'
import {
  DateDismissibleKey,
  DismissibleKey,
  useLocalStorageContext,
} from 'providers/LocalStorageProvider'
import React from 'react'
import { useIntl } from 'react-intl'
import {
  CustomerCommunicationButtonRow,
  CustomerCommunicationButtonWrapper,
  CustomerCommunicationContent,
  CustomerCommunicationPanel,
  CustomerCommunicationWrapper,
} from './styles'

type AdditionalAction = {
  onClick: () => void
  text: string
}

type Props = {
  content: React.ReactNode
  dismissText?: string
  additionalActions?: AdditionalAction[]
} & (
  | {
      kind: 'One-Off'
      communicationKey: DismissibleKey
    }
  | {
      kind: 'Recurring'
      date: ISODate
      locationId: string
      communicationKey: DateDismissibleKey
    }
)

const useIsDismissed = (props: Props) => {
  const { state } = useLocalStorageContext()
  if (props.kind === 'One-Off') {
    return state.dismissibles[props.communicationKey] || false
  }
  return get(
    state.dateDismissables,
    [props.communicationKey, props.locationId, props.date],
    false
  )
}
const useDismissCommunication = (props: Props) => {
  const { dispatch } = useLocalStorageContext()
  if (props.kind === 'One-Off') {
    return () => {
      dispatch({
        type: 'SET_DISMISSIBLE',
        payload: {
          key: props.communicationKey,
          isDismissed: true,
        },
      })
    }
  }
  return () => {
    dispatch({
      type: 'DISMISS_FOR_DATE',
      payload: {
        locationId: props.locationId,
        date: props.date,
        key: props.communicationKey,
      },
    })
  }
}

export const CustomerCommunication: React.FC<Props> = (props) => {
  const { content, dismissText } = props
  const additionalActions = props.additionalActions || []
  const { formatMessage } = useIntl()
  const isDismissed = useIsDismissed(props)
  const dismiss = useDismissCommunication(props)
  if (isDismissed) {
    return null
  }

  return (
    <CustomerCommunicationWrapper>
      <CustomerCommunicationPanel>
        <CustomerCommunicationContent>{content}</CustomerCommunicationContent>
        <CustomerCommunicationButtonRow>
          <CustomerCommunicationButtonWrapper>
            <Button
              color="white"
              textColor="black"
              height="medium"
              onClick={dismiss}
            >
              {dismissText || formatMessage({ id: 'common.got-it' })}
            </Button>
          </CustomerCommunicationButtonWrapper>
          {additionalActions.map((action) => (
            <CustomerCommunicationButtonWrapper key={action.text}>
              <Button
                color="white"
                textColor="black"
                height="medium"
                onClick={action.onClick}
              >
                {action.text}
              </Button>
            </CustomerCommunicationButtonWrapper>
          ))}
        </CustomerCommunicationButtonRow>
      </CustomerCommunicationPanel>
    </CustomerCommunicationWrapper>
  )
}
