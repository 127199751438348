import jwtDecode from 'jwt-decode'

export const isTokenValid = (authToken: string) => {
  const tokenExpiry = jwtDecode(authToken) as any
  if (Date.now() / 1000 < tokenExpiry.exp) {
    return true
  }
  return false
}

export const isUserAuthenticated = (token: string) => {
  if (token && token.length > 0 && isTokenValid(token)) {
    return true
  }

  return false
}
