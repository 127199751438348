import React, { FunctionComponent } from 'react'

import './styles.css'

interface Props {
  className?: string
  closeClassName?: string
  closeAriaLabel?: string
  cssModule?: object
  color?: string
  isOpen?: boolean
  toggle?: any
  tag?: any
  transitionAppearTimeout?: number
  transitionEnterTimeout?: number
  transitionLeaveTimeout?: number
}

export const Alert: FunctionComponent<Props> = ({
  className,
  closeClassName,
  closeAriaLabel = 'Close',
  tag: Tag = 'div',
  color = 'success',
  isOpen = true,
  toggle,
  children,
  transitionAppearTimeout = 150,
  transitionEnterTimeout = 150,
  transitionLeaveTimeout = 150,
  ...attributes
}) => {
  const classes = `${className} alert alert-${color} ${
    toggle ? 'alert-dismissible' : ''
  }`
  const closeClasses = `close ${closeClassName}`

  if (!isOpen) {
    return null
  }

  return (
    <Tag {...attributes} className={classes} role="alert">
      {toggle ? (
        <button
          type="button"
          className={closeClasses}
          aria-label={closeAriaLabel}
          onClick={toggle}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      ) : null}
      {children}
    </Tag>
  )
}

export default Alert
