import { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import useSelectProfile from 'components/LocationUserManagement/hooks/useSelectProfile'
import {
  registerProfileEvent,
  registerProfileEventVariables,
} from '___generated___/registerProfileEvent'
import { ProfileEventType } from '___generated___/globalTypes'

const RegisterProfileEvent = gql`
  mutation registerProfileEvent($profileEvent: ProfileEvent!) {
    registerProfileEvent(profileEvent: $profileEvent) {
      success
    }
  }
`

export const useSendAppLoaded = () => {
  const { selectedProfileId } = useSelectProfile()
  const [mutation] = useMutation<
    registerProfileEvent,
    registerProfileEventVariables
  >(RegisterProfileEvent)

  useEffect(() => {
    if (!selectedProfileId) {
      return
    }
    mutation({
      variables: {
        profileEvent: {
          type: ProfileEventType.APP_LOADED,
        },
      },
    })
  }, [mutation, selectedProfileId])
}
