import { gql } from '@apollo/client'

export const GET_SALE = gql`
  query getSale($locationId: ID!, $saleDate: ISODate) {
    location(locationId: $locationId) {
      id
      dvh
      has_short_expiry
      hasAutosale
      todaysSaleTimes {
        closed
      }
      currency {
        symbol
        is_prefix
        code
      }
      itemsFullPrice {
        id
        title
        price
        category
        created_date
        usage_frequency
        image_url
        active
        full_price
        reference_item_id
        base_price
        saleInfo {
          saleItemId
          expiryDate
          expired
          soldCount
          originalCount
          availableCount
          weight
        }
      }
      sale(date: $saleDate) {
        id
        day
        numberOfItemsOnSale
        status
        start_date
        end_date
        pickup_date
        activeItems {
          id
          title
          price
          category
          created_date
          usage_frequency
          pinned
          is_bundled_item
          image_url
          full_price
          saleInfo {
            saleItemId
            expiryDate
            expired
            soldCount
            originalCount
            availableCount
            weight
            period
          }
        }
      }
    }
  }
`

export const GET_ITEMS_ON_SALE = gql`
  query getItemsOnSale($locationId: ID!, $saleDate: ISODate) {
    location(locationId: $locationId) {
      id
      has_short_expiry
      has_item_bundles
      hasFullprice
      currency {
        symbol
        is_prefix
        code
      }
      sale(date: $saleDate) {
        id
        day
        status
        numberOfItemsOnSale
        activeItems {
          id
          title
          image_url
          price
          full_price
          saleInfo {
            saleItemId
            expiryDate
            expired
            soldCount
            originalCount
            availableCount
            weight
            period
            createdDate
          }
          is_bundled_item
          variant {
            ... on WeightedItemVariant {
              displayUnit
              type
            }
            ... on GenericItemVariant {
              type
            }
            ... on DeliveryItemVariant {
              type
            }
            ... on TipItemVariant {
              type
            }
          }
        }
      }
      saleTimes {
        weekday
        closed
        sale_start_local
        sale_end_local
        pickup_start_local
        pickup_end_local
        period
      }
      itemsFullPrice {
        id
        title
        price
        category
        created_date
        usage_frequency
        image_url
        nonSurplusAvailable
        full_price
        reference_item_id
        base_price
        saleInfo {
          saleItemId
          expiryDate
          expired
          soldCount
          originalCount
          availableCount
          weight
        }
      }
      todaysSaleTimes {
        pickup_start_local
        pickup_end_local
        sale_start_local
        sale_end_local
        closed
        weekday
      }
    }
  }
`

export const ADD_WEIGHTED_SALE_ITEM = gql`
  mutation addWeightedSaleItem($input: NewSaleItemInput!) {
    updateSingleSaleItem(input: $input) {
      id
      sale_id
    }
  }
`

export const REMOVE_SALE_ITEM = gql`
  mutation removeSaleItem($saleItemId: ID!) {
    removeUnsoldSaleItem(saleItemId: $saleItemId) {
      success
    }
  }
`
