import { useMutation, useQuery } from '@apollo/client'
import { LargeModal } from 'components/Modal/styles'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { H2 } from 'design-system/Typography/H2'
import { Paragraph } from 'design-system/Typography/Paragraph'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CategoryTagKey } from '___generated___/globalTypes'
import {
  Data,
  getPendingDeliverectImportsQuery,
  handleDeliverectImportQuery,
  MutationVars,
  Vars,
} from './queries'
import { ItemList, ModalFooter } from './DeliverectItemImportModal.styles'
import { toConstantValueMap } from 'utils/dataStructures'
import { Button } from 'design-system/Button'
import { ImportItem } from './ImportItem'
import partition from 'lodash/partition'
import { useDeliverectImportReducer } from './reducer'
import { sentryCaptureException } from 'utils/exceptionUtils'
import { ImportStatus } from './Statuses'
import { useIntl } from 'react-intl'

export const DeliverectItemImportModal: React.FC = () => {
  const {
    locationId,
    currency: { code: currencyCode },
  } = useCurrentUser()
  const { formatMessage } = useIntl()
  const { data } = useQuery<Data, Vars>(getPendingDeliverectImportsQuery, {
    fetchPolicy: 'network-only',
    variables: { locationId },
  })
  const [handleImportMutation] = useMutation<{}, MutationVars>(
    handleDeliverectImportQuery
  )
  const items = useMemo(() => data?.location?.pendingDeliverectImports ?? [], [
    data,
  ])
  const [itemCategoryMap, setItemCategoryMap] = useState<
    Record<string, CategoryTagKey>
  >({})
  const [itemAcceptedMap, setItemAcceptedMap] = useState<
    Record<string, boolean>
  >({})
  const [requestState, requestDispatch] = useDeliverectImportReducer()

  useEffect(() => {
    const acceptedMap = toConstantValueMap(items, (i) => i.id, false)
    const categoryMap = toConstantValueMap(
      items,
      (i) => i.id,
      CategoryTagKey.FULL_MEAL
    )
    setItemCategoryMap(categoryMap)
    setItemAcceptedMap(acceptedMap)
  }, [setItemCategoryMap, setItemAcceptedMap, items])

  const setItemCategory = useCallback(
    (importItemId: string, category: CategoryTagKey) => {
      setItemCategoryMap((old) => ({ ...old, [importItemId]: category }))
    },
    [setItemCategoryMap]
  )
  const acceptItem = useCallback(
    (importItemId: string) => {
      setItemAcceptedMap((old) => ({ ...old, [importItemId]: true }))
    },
    [setItemAcceptedMap]
  )
  const rejectItem = useCallback(
    (importItemId: string) => {
      setItemAcceptedMap((old) => ({ ...old, [importItemId]: false }))
    },
    [setItemAcceptedMap]
  )
  const acceptSelected = async () => {
    requestDispatch('SEND_REQUEST')
    const [acceptedImportIds, rejectedImportIds] = partition(
      items.map((i) => i.id),
      (id) => itemAcceptedMap[id]
    )

    const rejectedItems = rejectedImportIds.map((importId) => ({ importId }))
    const acceptedItems = acceptedImportIds.map((importId) => ({
      importId,
      category: itemCategoryMap[importId] || CategoryTagKey.FULL_MEAL,
    }))
    try {
      await handleImportMutation({
        variables: {
          locationId,
          input: {
            rejectedItems,
            acceptedItems,
          },
        },
      })
      requestDispatch('SUCCEED_IMPORT')
    } catch (error) {
      requestDispatch('FAIL')
      sentryCaptureException(DeliverectItemImportModal, error)
    }
  }
  const rejectAll = async () => {
    requestDispatch('SEND_REQUEST')
    const rejectedItems = items.map((i) => ({ importId: i.id }))
    const acceptedItems: MutationVars['input']['acceptedItems'] = []
    try {
      await handleImportMutation({
        variables: {
          locationId,
          input: {
            rejectedItems,
            acceptedItems,
          },
        },
      })
      requestDispatch('SUCCEED_REJECT')
    } catch (error) {
      requestDispatch('FAIL')
      sentryCaptureException(DeliverectItemImportModal, error)
    }
  }

  const numberOfImportedItems = items.filter((i) => itemAcceptedMap[i.id])
    .length

  if (requestState.state === 'SENDING_REQUEST') {
    return <ImportStatus.Loading />
  }
  if (requestState.state === 'FAILED') {
    return <ImportStatus.Failed />
  }
  if (requestState.state === 'SUCCESS') {
    return <ImportStatus.Success target={requestState.target} />
  }
  return (
    <LargeModal style={{ textAlign: 'center' }}>
      <H2>{formatMessage({ id: 'deliverect.import.modal.title' })}</H2>

      <Paragraph textAlign="left">
        {formatMessage({ id: 'deliverect.import.modal.description' })}
      </Paragraph>
      <Paragraph textAlign="left" weight={600}>
        {formatMessage({ id: 'deliverect.import.modal.allergenWarning' })}
      </Paragraph>

      <ItemList>
        {items.map((item) => (
          <ImportItem
            key={item.id}
            item={item}
            currencyCode={currencyCode}
            accepted={itemAcceptedMap[item.id] || false}
            category={itemCategoryMap[item.id] || CategoryTagKey.FULL_MEAL}
            acceptItem={acceptItem}
            rejectItem={rejectItem}
            setItemCategory={setItemCategory}
          />
        ))}
      </ItemList>
      <ModalFooter>
        <Button textColor="black" ghost onClick={rejectAll}>
          {formatMessage({ id: 'deliverect.import.modal.rejectAll' })}
        </Button>
        <Button
          colorScheme="positive"
          disabled={numberOfImportedItems === 0}
          onClick={acceptSelected}
        >
          {formatMessage(
            { id: 'deliverect.import.modal.doImport' },
            { numberOfImportedItems }
          )}
        </Button>
      </ModalFooter>
    </LargeModal>
  )
}
