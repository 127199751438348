import React, { ButtonHTMLAttributes, FC } from 'react'
import { TextButtonWrapper } from 'design-system/Button/TextButton.styles'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const TextButton: FC<Props> = ({ type, onClick, children, ...props }) => {
  return (
    <TextButtonWrapper type={type ?? 'button'} onClick={onClick} {...props}>
      {children}
    </TextButtonWrapper>
  )
}

export { TextButton }
