import { useContext, useEffect, useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import { NotificationContext } from './context'
import { Actions, NotificationState, Notification } from './types'

export const useNotificationBar = () => {
  const [state, dispatch] = useContext(NotificationContext) as [
    NotificationState,
    React.Dispatch<Actions>
  ]

  const notifications = useMemo(
    () =>
      sortBy(
        Object.keys(state.openNotifications).map(
          (key) => state.openNotifications[key]
        ),
        (notification) => notification.createdAt
      ),
    [state]
  )

  const fns = useMemo(() => {
    const showInfoNotification = (
      notificationBase: Omit<Notification, 'type'>
    ) => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { ...notificationBase, type: 'info' },
      })
    }
    const showWarningNotification = (
      notificationBase: Omit<Notification, 'type'>
    ) => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { ...notificationBase, type: 'warning' },
      })
    }

    const hideNotification = (id: string) => {
      dispatch({
        type: 'HIDE_NOTIFICATION',
        payload: { id },
      })
    }
    return {
      showInfoNotification,
      showWarningNotification,
      hideNotification,
    }
  }, [dispatch])

  return {
    notifications,
    ...fns,
  }
}

export const useShowNotificationOnCondition = (
  notification: Notification,
  condition: boolean
) => {
  const [, dispatch] = useContext(NotificationContext) as [
    NotificationState,
    React.Dispatch<Actions>
  ]
  const { id, message, type } = notification
  useEffect(() => {
    if (condition) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { id, message, type },
      })
    } else {
      dispatch({
        type: 'HIDE_NOTIFICATION',
        payload: { id },
      })
    }
  }, [condition, dispatch, id, message, type])
}
