import { useMutation } from '@apollo/client'
import { SET_ACTIVE_LOCATION } from 'apollo/queries/user'
import {
  GET_LOCATIONS,
  GET_ACTIVE_LOCATION_MENU,
} from 'components/Menu/queries'
import { GET_USER } from 'components/UserState'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { LOCATION_ACCESS_QUERY } from 'components/LocationSelector'
import {
  setActiveLocation as IsetActiveLocation,
  setActiveLocationVariables,
} from '___generated___/setActiveLocation'

const useSetActiveLocation = () => {
  const { user } = useCurrentUser()
  const [setActiveLocation, { loading, error }] = useMutation<
    IsetActiveLocation,
    setActiveLocationVariables
  >(SET_ACTIVE_LOCATION, {
    refetchQueries: [
      {
        query: GET_LOCATIONS,
      },
      { query: GET_USER, variables: { id: user.id } },
      { query: LOCATION_ACCESS_QUERY },
      { query: GET_ACTIVE_LOCATION_MENU },
    ],
  })

  return { setActiveLocation, loading, error }
}

export default useSetActiveLocation
