import React, { FC } from 'react'
import { NewColor } from 'design-system/theme/newColors'
import { FoodBadgeWrapper } from 'components/CreateItemForm/FoodBadge.styles'
import { Span } from 'design-system/Typography/Span'

interface Props {
  text: string
  textColor?: NewColor
  background?: NewColor
}

const FoodBadge: FC<Props> = ({ text, textColor, background }) => {
  return (
    <FoodBadgeWrapper backgroundColor={background}>
      <Span fontWeight={600} variant="small" color={textColor ?? 'plum'}>
        {text}
      </Span>
    </FoodBadgeWrapper>
  )
}

export { FoodBadge }
