import { randomValueFromArray } from 'utils/numberUtils'
import { AchievementKind, AchievementTarget } from '___generated___/globalTypes'
import { randomSentence, randomString } from 'apollo/mocks/mockUtils'
import { DateTime } from 'luxon'

const ACHIEVEMENT = ({
  claimedAt = Math.random() < 0.75 ? DateTime.utc().toISO() : null,
  id = Math.floor(Math.random() * 9999),
  kind = randomValueFromArray(Object.values(AchievementKind)),
  title = {
    __typename: 'Title',
    en: randomString({ suffix: 'en_title' }),
    fr: randomString({ suffix: 'fr_title' }),
    sv: randomString({ suffix: 'sv_title' }),
  },
  points = Math.floor(Math.random() * 666),
  description = {
    __typename: 'Description',
    en: [
      ...randomSentence(),
      `${Math.random().toString(36).substring(7)}_en_desc`,
    ],
    fr: [
      ...randomSentence(),
      `${Math.random().toString(36).substring(7)}_fr_desc`,
    ],
    sv: [
      ...randomSentence(),
      `${Math.random().toString(36).substring(7)}_sv_desc`,
    ],
  },
  achievementTarget = randomValueFromArray(Object.values(AchievementTarget)),
  seenByCurrentProfile = Math.random() > 0.9,
} = {}) => {
  return {
    __typename: 'Achievement',
    id,
    achievementKind: kind,
    title,
    points,
    description,
    claimedAt,
    achievementTarget,
    seenByCurrentProfile,
    instanceId: claimedAt ? Math.floor(Math.random() * 9999) : null,
  }
}

export default ACHIEVEMENT
