export interface Margins {
  base: {
    all: '4px'
  }
  content: {
    spacing: {
      small: '8px'
      medium: '16px'
      large: '24px'
      extraLarge: '40px'
    }
  }
  typography: {
    headings: {
      small: '8px'
      medium: '14px'
      large: '28px'
    }
    icons: {
      right: '4px'
    }
    inline: {
      medium: '8px'
      large: '16px'
    }
  }
}

export const createMargins = (): Margins => ({
  base: {
    all: '4px',
  },
  content: {
    spacing: {
      small: '8px',
      medium: '16px',
      large: '24px',
      extraLarge: '40px',
    },
  },
  typography: {
    headings: {
      small: '8px',
      medium: '14px',
      large: '28px',
    },
    icons: {
      right: '4px',
    },
    inline: {
      medium: '8px',
      large: '16px',
    },
  },
})

export interface Margins4 {
  content: {
    small: '8px'
    medium: '16px'
    large: '24px'
    extraLarge: '32px'
    mega: '48px'
  }
  typography: {
    headings: {
      small: '8px'
      medium: '16px'
      large: '24px'
      extraLarge: '32px'
    }
  }
}

export const m4 = (): Margins4 => ({
  content: {
    small: '8px',
    medium: '16px',
    large: '24px',
    extraLarge: '32px',
    mega: '48px',
  },
  typography: {
    headings: {
      small: '8px',
      medium: '16px',
      large: '24px',
      extraLarge: '32px',
    },
  },
})
