import React from 'react'
import {
  SideBarItemBadge,
  SidebarItemLabel,
  SidebarItemWrapper,
} from 'components/Menu/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { Span } from 'design-system/Typography/Span'
import { NewColor } from 'design-system/theme/newColors'
import { useMenu } from 'components/Menu/Menu'

type Props = {
  toPath: string
  icon: React.ReactNode
  title: string
  badgeText?: React.ReactNode
  badgeColor?: NewColor
  indentedBadge?: boolean
  hasAccess: boolean
}

export const SettingSidebarItem = ({
  toPath,
  icon,
  title,
  badgeText,
  badgeColor,
  indentedBadge,
  hasAccess,
}: Props) => {
  const { pathname: currentUrl } = useLocation()
  const { closeMenu } = useMenu()
  const history = useHistory()
  const handleClick = () => {
    history.push(toPath)
    closeMenu()
  }
  const subDirectory = toPath.split('/').pop() ?? ''
  const isActive =
    toPath &&
    currentUrl.startsWith(toPath) &&
    currentUrl.split('/').includes(subDirectory)
  if (!hasAccess) {
    return null
  }
  return (
    <SidebarItemWrapper onClick={handleClick} active={!!isActive}>
      {icon}
      <SidebarItemLabel color="inherit" fontWeight={600}>
        {title}
      </SidebarItemLabel>
      {!!badgeText && (
        <SideBarItemBadge
          isIndented={!!indentedBadge}
          backgroundColor={badgeColor}
        >
          <Span color="white" variant="small">
            {badgeText}
          </Span>
        </SideBarItemBadge>
      )}
    </SidebarItemWrapper>
  )
}
