import React from 'react'
import styled from 'styled-components/macro'
import { Spinner } from 'design-system/Spinner'
import { NewColor } from 'design-system/theme/newColors'

interface ToggleSwitchProps {
  wrapper: {
    width: number
    height: number
  }
  ball: {
    size: number
    padding: number
  }
  activeColor?: string
  disabled?: boolean
}

const ToggleSwitch = styled.label<ToggleSwitchProps>`
  position: relative;
  display: inline-block;
  width: ${({ wrapper }) => wrapper.width}px;
  height: ${({ wrapper }) => wrapper.height}px;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};

  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    ${({ disabled }) => disabled && 'cursor: not-allowed;'};
  }

  .slider:before {
    position: absolute;
    content: '';
    height: ${({ ball }) => ball.size}px;
    width: ${({ ball }) => ball.size}px;
    left: ${({ ball }) => ball.padding}px;
    bottom: ${({ ball }) => ball.padding}px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: ${({ wrapper }) => wrapper.height}px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: ${({ activeColor, theme }) =>
      activeColor || theme.v3.colors.jade};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(${({ ball }) => ball.size}px);
  }
  input:disabled {
    cursor: not-allowed;
  }
`

interface Props {
  active: boolean
  onToggle?: () => void
  onActive?: () => void
  onChange?: (active: boolean) => void
  onInactive?: () => void
  activeColor?: string
  scale?: number
  disabled?: boolean
  loading?: boolean
  loadingColor?: NewColor
  id?: string
}

const ToggleButton: React.FC<Props> = ({
  id,
  active,
  onToggle,
  onActive,
  onInactive,
  onChange,
  activeColor,
  scale = 1,
  disabled,
  loading,
  loadingColor,
}) => {
  const toggle = () => {
    onChange && onChange(!active)
    onToggle && onToggle()
    active && onInactive && onInactive()
    !active && onActive && onActive()
  }

  const wrapper = {
    height: scale * 34,
    width: scale * 60,
  }
  const ball = {
    size: scale * 26,
    padding: scale * 4,
  }
  return (
    <ToggleSwitch
      activeColor={activeColor}
      wrapper={wrapper}
      ball={ball}
      disabled={disabled}
    >
      {loading ? (
        <Spinner
          loading={true}
          style={{ width: wrapper.height, height: wrapper.height }}
          color={loadingColor ?? 'black'}
          type="circle"
        />
      ) : (
        <>
          <input
            id={id ?? 'hidden-toggle-switch'}
            type="checkbox"
            checked={active}
            onChange={toggle}
            disabled={disabled || loading}
          />
          <span className="slider round" />
        </>
      )}
    </ToggleSwitch>
  )
}

export { ToggleButton }
