import React from 'react'
import qs from 'query-string'
import * as AcceptedInvitation from '../../components/AcceptedInvitation'
import { useIntl } from 'react-intl'
import { KarmaHead } from '../../KarmaHead'
import { RouteComponentProps, useHistory } from 'react-router'

interface Props extends RouteComponentProps {}

export const ConfirmNewSharedAccount: React.FC<Props> = (props) => {
  const intl = useIntl()
  const params = qs.parse(props.location.search)
  const history = useHistory()
  const { email = '', location_name = '', name = '', invitation_token } = params
  if (
    typeof invitation_token !== 'string' ||
    typeof email !== 'string' ||
    typeof location_name !== 'string' ||
    typeof name !== 'string'
  ) {
    history.replace('/')
    return null
  }

  const invitationToken = decodeURIComponent(invitation_token)

  return (
    <>
      <KarmaHead title={intl.formatMessage({ id: 'account.title' })} />
      <AcceptedInvitation.NewSharedAccount
        email={email}
        locationName={location_name}
        name={name}
        invitationToken={invitationToken}
      />
    </>
  )
}
