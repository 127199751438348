import useUserManagement from 'components/LocationUserManagement/hooks/useUserManagement'
import {
  CREATE_PROFILE,
  GET_PROFILES,
} from 'components/LocationUserManagement/queries'
import { useMutation } from '@apollo/client'
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createProfile,
  createProfileVariables,
} from '___generated___/createProfile'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { useNotificationBar } from 'design-system/NotificationBar'
import { useIntl } from 'react-intl'

const useCreateProfile = () => {
  const { formatMessage } = useIntl()
  const { locationId } = useCurrentUser()
  const { sharedAccount } = useUserManagement()
  const { showWarningNotification } = useNotificationBar()
  const [createProfileMutation, { loading, error }] = useMutation<
    createProfile,
    createProfileVariables
  >(CREATE_PROFILE, {
    refetchQueries: [
      {
        query: GET_PROFILES,
        variables: { locationId, userId: sharedAccount?.id ?? '' },
      },
    ],
  })
  const createProfile = async ({ name }: { name: string }) => {
    if (!sharedAccount) {
      showWarningNotification({
        id: `create_profile_error-${name}-no-shared-account`,
        message: formatMessage({
          id: 'usermanagement.profile.error.create',
        }),
      })
      throw Error('No shared account')
    }
    try {
      return await createProfileMutation({
        variables: {
          input: {
            name,
          },
          locationId,
          userId: sharedAccount.id,
        },
      })
    } catch (e) {
      showWarningNotification({
        id: `create_profile_error-${name}-${sharedAccount.id}`,
        message: formatMessage({
          id: 'usermanagement.profile.error.create',
        }),
      })
      throw e
    }
  }
  return {
    createProfile,
    loading,
    error,
  }
}

export default useCreateProfile
