import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { Span } from 'design-system/Typography/Span'
import { NewColor } from 'design-system/theme/newColors'

export const SidebarBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  opacity: 0.5;
  z-index: 200001;
`

export const SidebarMenu = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  background-color: ${({ theme }) => theme.v3.colors.white};
  z-index: 200002;
`

export const SidebarList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom, 20px);
  flex: 1 0 0;
  background-color: ${({ theme }) => theme.v3.colorScales.grey[10]};
`

export const DesktopMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 190px;
`

export const SidebarListContainer = styled.div`
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const SidebarDivider = styled.div<{ maximize?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.v3.colorScales.grey[30]};
  padding: 5px 0;
  width: 100%;
  ${({ maximize }) => maximize && 'flex: 1'};
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SideBarItemProps {
  active?: boolean
  noHover?: string
  onClick?: VoidFunction
}
export const SidebarItemWrapper = styled.button<SideBarItemProps>`
  position: relative;
  margin: 0;
  outline: 0;
  border: none;
  text-align: left;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) =>
    active ? theme.v3.colors.white : 'transparent'};
  border-top: 1px solid ${({ theme }) => theme.v3.colorScales.grey[30]};
  padding: ${({ theme }) => {
    const { medium, large } = theme.v4.margins.content
    return `${medium} 0 ${medium} ${large}`
  }};
  color: ${({ active, theme }) =>
    active ? theme.v3.colorScales.pink[80] : theme.v3.colors.darkGrey};
  &:hover {
    cursor: ${({ noHover }) => noHover || 'pointer'};
  }
  &:focus {
    background-color: ${({ noHover, theme }) =>
      noHover || theme.v3.colorScales.grey[30]};
  }
`

export const SidebarItemLabel = styled(Span)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  :nth-child(n + 2) {
    margin-left: ${({ theme }) => theme.v4.margins.content.small};
  }
`

export const SideBarItemBadge = styled.div<{
  backgroundColor?: NewColor
  isIndented: boolean
}>`
  display: flex;
  height: 18px;
  min-width: 18px;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 4px;
  margin-left: ${({ theme, isIndented }) =>
    isIndented
      ? theme.v3.margins.content.spacing.medium
      : theme.v3.margins.content.spacing.small};
  background: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.v3.colors[backgroundColor] : theme.v3.colors.grey};
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.2);
  & > * {
    margin: 0 ${({ theme }) => theme.v3.paddings.badge.dense};
  }
`

export const ActionBar = styled.div`
  padding: 8px 8px 4px 8px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2001;
`
export const PaginationRow = styled.div`
  display: flex;
  padding: ${({ theme }) => {
    const { small, medium } = theme.v3.margins.content.spacing
    return `0 ${small} ${medium}`
  }};
  justify-content: flex-end;
  align-items: center;
`

export const PageNumber = styled.button<{ active?: boolean }>`
  margin: 0 5px;
  padding: 0;
  outline: none;
  border: none;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
`

export const SidebarSearchWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.v3.margins.content.spacing.medium} 4px;
  box-sizing: border-box;
`

export const SubMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    padding-left: 32px;
  }
`

export const SidebarSubMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const SidebarSubMenuLabel = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const LocationPickerNoResult = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.v3.margins.typography.headings.large};
`

export const ChatItemImage = styled.img`
  width: 30px;
  height: 30px;
`
