import styled, { css } from 'styled-components/macro'
import { NewColor, useColor } from 'design-system/theme/newColors'
import { Margins4 } from 'design-system/theme/margins'

const fontSizeLarge = css`
  font-size: 16px;
  line-height: 22px;
`

const fontSizeMedium = css`
  font-size: 14px;
  line-height: 20px;
`

const fontSizeSmall = css`
  font-size: 10px;
  line-height: 14px;
`

interface Props {
  color?: NewColor | 'inherit'
  variant?: 'small' | 'medium' | 'large'
  fontWeight?: number
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
  textDecoration?: 'underline' | 'line-through'
  bottomMargin?: keyof Margins4['typography']['headings']
}
export const Span = styled.span<Props>`
  color: ${({ color }) =>
    color === 'inherit' ? 'inherit' : useColor(color ?? 'black')};
  font-family: ${({ theme }) => theme.v2.fonts.default};
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  text-align: ${({ textAlign }) => textAlign ?? 'initial'};
  text-decoration: ${({ textDecoration }) => textDecoration ?? 'none'};
  margin-bottom: ${({ theme, bottomMargin }) =>
    bottomMargin ? theme.v4.margins.typography.headings[bottomMargin] : 0};
  ${({ variant }) => {
    if (variant === 'small') return fontSizeSmall
    else if (variant === 'medium') return fontSizeMedium
    else if (variant === 'large') return fontSizeLarge
    else return fontSizeMedium
  }}
`
