import SALE_TIME_EXCEPTION from 'apollo/mocks/data/saleTimeException'
import { completedBasicKarmaScore } from 'apollo/mocks/data/karmaScoreTasks'
import { getSaleTimes } from 'apollo/mocks/data/saleTimes'
import { getMockTransactions } from 'apollo/mocks/data/transactions'
import { USER_NO_LOCATION } from 'apollo/mocks/data/retailUser'
import PROFILE from 'apollo/mocks/data/profile'
import { randomString } from 'apollo/mocks/mockUtils'
import { DateTime } from 'luxon'
import { CompanyPlan, UserRole } from '___generated___/globalTypes'
import { getUserState_user_location_betaFlags } from '___generated___/getUserState'

const LOCATION = ({ id = '11111111' }) =>
  ({
    __typename: 'Location',
    id,
    uuid: '8694495d-fc28-44ad-876e-a24cb56bb0bb',
    name: 'mock store',
    created_date: '2002-02-02',
    locationCategory: null,
    karmaScore: {
      __typename: 'KarmaScore',
      ...completedBasicKarmaScore(),
    },
    saleTimes: getSaleTimes(),
    transactions: getMockTransactions(),
    exceptionalSaleTimes: [
      SALE_TIME_EXCEPTION({
        weeksFromNow: 3,
        closed: true,
        name: 'In three weeks',
      }),
    ],
    profiles: [
      PROFILE({
        id: 100000,
        name: 'StaticProfile1',
        isSharedAccountProfile: true,
      }),
      PROFILE({
        id: 200000,
        name: 'StaticProfile2',
        isSharedAccountProfile: true,
      }),
      PROFILE({
        id: 300000,
        name: 'StaticProfile3',
        isSharedAccountProfile: true,
      }),
      PROFILE({
        id: 8,
        name: 'StaticProfile3',
        isSharedAccountProfile: false,
      }),
    ],
    users: [
      ...Array.from(Array(20)).map(() =>
        USER_NO_LOCATION({
          id: Math.floor(Math.random() * 9999),
          locationId: id,
        })
      ),
      USER_NO_LOCATION({ id: 420, locationId: id, isShared: false }),
    ],
    invitations: [
      {
        __typename: 'LocationInvitation',
        email: randomString({ suffix: '@karma.mock' }),
        expiry_date: DateTime.utc().plus({ week: 1 }),
        id: randomString(),
        role_id: randomString(),
        isSharedAccount: true,
      },
      {
        __typename: 'LocationInvitation',
        email: randomString({ suffix: '@karma.mock' }),
        expiry_date: DateTime.utc().plus({ week: 1 }),
        id: randomString(),
        role_id: randomString(),
        isSharedAccount: false,
      },
      {
        __typename: 'LocationInvitation',
        email: randomString({ suffix: '@karma.mock' }),
        expiry_date: DateTime.utc().plus({ week: 1 }),
        id: randomString(),
        role_id: randomString(),
        isSharedAccount: false,
      },
    ],
    currency: {
      __typename: 'Currency',
      symbol: 'kr',
      is_prefix: false,
      code: 'SEK',
    },
    country: {
      __typename: 'Country',
      id: '333',
      alpha2: '1',
    },
    role: UserRole.ADMIN,
    permission_set: null,
    beta: {
      __typename: 'Beta',
      food_preferences: true,
      cwi: true,
      new_dashboard: true,
    },
    hasAutosale: true,
    hasFullprice: true,
    has_short_expiry: true,
    betaFlags: [] as getUserState_user_location_betaFlags[],
    dvh: true,
    company: {
      __typename: 'Company',
      id: '888',
      plan: CompanyPlan.PRO,
    },
    loyalty_discount: false,
    noSurplusToday: false,
    hasPod: true,
    karmaBasic: false,
  } as const)

export default LOCATION
