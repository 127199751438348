import { useEffect } from 'react'

interface Props {
  shouldDisable: boolean
}

const useDisableBodyScroll = ({ shouldDisable }: Props) => {
  useEffect(() => {
    // Prevent the background from scrolling when modal is open
    const body = document.querySelector('body')
    if (!body) {
      return
    }
    if (shouldDisable) {
      body.style.overflow = 'hidden'
    } else {
      body.style.overflow = 'initial'
    }
    return () => {
      body.style.overflow = 'initial'
    }
  }, [shouldDisable])
}

export default useDisableBodyScroll
