import { useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import { Language } from '___generated___/globalTypes'
import { setUserLangVariables } from '___generated___/setUserLang'
import { Locale } from '../types'

const setUserLangQuery = gql`
  mutation setUserLang($language: Language!) {
    setLanguageForUser(language: $language) {
      success
    }
  }
`

type Vars = setUserLangVariables

export const useSetUserLang = () => {
  const [mutation] = useMutation<unknown, Vars>(setUserLangQuery)
  return (locale: Locale) => {
    const language = Language[locale]
    mutation({
      variables: {
        language,
      },
    })
  }
}
