import { ApolloError, gql, useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import includes from 'lodash/includes'
import { InventoryQuery } from 'pages/Sale/types'
import { useState } from 'react'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'

const QUERY_INVENTORY = gql`
  query queryInventory($locationId: ID!, $queryInput: InventoryQueryInput!) {
    location(locationId: $locationId) {
      id
      queryInventory(input: $queryInput) {
        totalItems
        items {
          id
          title
          ingredients
          active
          price
          is_bundled_item
          base_price
          vat_id
          discount_percentage
          image_url
          category
          images {
            name
            type
            url
            size
          }
          dates {
            sold_count
            available_count
            original_count
            expiry_date
            expiry_time
            expired
          }
          matabas_product_id
          created_date
          usage_frequency
          pinned
          badges
          full_price
          autosaleAmount
          variant {
            ... on WeightedItemVariant {
              displayUnit
              type
            }
            ... on GenericItemVariant {
              type
            }
            ... on DeliveryItemVariant {
              type
            }
            ... on TipItemVariant {
              type
            }
          }
        }
      }
    }
  }
`

interface Params {
  pageSize?: number
  locationId?: string
  debounceMs?: number
  includeFullpriceItems?: boolean
}

interface ExposedProps {
  error?: ApolloError
  loading: boolean
  items: InventoryQuery.Item[]
  itemTotals: number
  loadMore: () => void
  canLoadMore: boolean
  search: (queryString: string) => void
  queryString: string
  loadingMore: boolean
  loadingSearch: boolean
}

export const useInventoryQuery: (args?: Params) => ExposedProps = ({
  pageSize = 32,
  locationId: providedLocationId,
  debounceMs = 800,
  includeFullpriceItems = false,
} = {}) => {
  const { locationId: currentLocationId } = useCurrentUser()
  const locationId = providedLocationId || currentLocationId
  const [queryString, setQueryString] = useState('')

  const { data, loading, fetchMore, error } = useQuery<
    InventoryQuery.Data,
    InventoryQuery.Variables
  >(QUERY_INVENTORY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      locationId,
      queryInput: {
        offset: 0,
        limit: pageSize,
        queryString,
        includeFullpriceItems,
      },
    },
  })
  const items = data?.location?.queryInventory?.items ?? []
  const itemTotals = data?.location?.queryInventory?.totalItems ?? 0
  const queryStringLowerCase = queryString.toLowerCase()
  const itemsFiltered =
    queryString === ''
      ? items
      : items.filter((i) =>
          includes(i.title?.toLowerCase(), queryStringLowerCase)
        )

  const executeSearch = async (queryString: string) => {
    try {
      await fetchMore({
        variables: {
          locationId,
          queryInput: {
            offset: items.length,
            limit: pageSize,
            queryString,
            includeFullpriceItems,
          },
        },
      })
    } catch (e) {
      console.log('Cant fetch more')
    }
  }

  const executeSearchDebounced = debounce(executeSearch, debounceMs)

  const search = (queryString: string) => {
    setQueryString(queryString)
  }

  const loadMore = async () => {
    await executeSearchDebounced(queryString)
  }

  return {
    search,
    loadMore,
    canLoadMore: itemsFiltered.length < itemTotals,
    items: itemsFiltered,
    queryString,
    itemTotals,
    loading,
    loadingMore: loading,
    loadingSearch: loading,
    error,
  }
}
