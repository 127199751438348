import { ToggleButton } from 'components/Basics'
import Select, { ValueType } from 'react-select'
import { H4 } from 'design-system/Typography/H4'
import { Paragraph } from 'design-system/Typography/Paragraph'
import { Span } from 'design-system/Typography/Span'
import React from 'react'
import { CategoryTagKey } from '___generated___/globalTypes'
import {
  ItemActionWrapper,
  ItemHeader,
  ItemImage,
  ItemImageWrapper,
  ItemPanel,
  ItemSelctionWrapper,
  ItemTag,
  ItemTagList,
  ItemDescription,
} from './ImportItem.styles'
import { DeliverectImportItem } from './types'
import capitalize from 'lodash/capitalize'
import { ItemFallbackImage } from 'design-system/ItemFallbackImage'
import { IntlShape, useIntl } from 'react-intl'

type SelectOption = {
  label: string
  value: CategoryTagKey
}
const getSelectOption = (intl: IntlShape) => (
  category: CategoryTagKey
): SelectOption => {
  const { formatMessage } = intl
  return {
    label: formatMessage({
      id: `allergen.categories.${category}`,
    }),
    value: category,
  }
}
const SELECT_OPTIONS = [
  CategoryTagKey.BREAD,
  CategoryTagKey.DRINK,
  CategoryTagKey.FULL_MEAL,
  CategoryTagKey.GROCERY,
  CategoryTagKey.SNACK,
  CategoryTagKey.SWEETS,
]

interface Props {
  item: DeliverectImportItem
  category: CategoryTagKey
  accepted: boolean
  currencyCode: string | null
  acceptItem: (importItemId: string) => void
  rejectItem: (importItemId: string) => void
  setItemCategory: (importItemId: string, category: CategoryTagKey) => void
}

export const ImportItem: React.FC<Props> = (props) => {
  const {
    item,
    category,
    accepted,
    setItemCategory,
    acceptItem,
    rejectItem,
    currencyCode,
  } = props
  const importItemId = item.id
  const intl = useIntl()
  const onCategoryChange = (selected: ValueType<SelectOption, false>) => {
    if (!accepted || !selected || !('label' in selected)) {
      return
    }
    const category = selected.value

    setItemCategory(importItemId, category)
  }
  const accept = () => acceptItem(importItemId)
  const reject = () => rejectItem(importItemId)
  const selectOptions = SELECT_OPTIONS.map(getSelectOption(intl))
  const selectValue = getSelectOption(intl)(category)
  const priceString = currencyCode
    ? `${(item.price / 100).toFixed(2)} ${currencyCode}`
    : item.price.toFixed(2)
  const itemImage = item.imageUrl ? (
    <ItemImage src={item.imageUrl} />
  ) : (
    <ItemFallbackImage category={category} height="100%" style={{}} />
  )
  return (
    <ItemPanel>
      <ItemImageWrapper>{itemImage}</ItemImageWrapper>
      <ItemHeader>
        <H4>{item.title}</H4>
        <Span variant="large">{priceString}</Span>
      </ItemHeader>
      <ItemDescription>
        <Paragraph weight={400} textAlign="left">
          {item.description}
        </Paragraph>
        {item.tags.length > 0 && (
          <ItemTagList>
            {item.tags.map((tag) => (
              <ItemTag key={tag}>
                <Span variant="medium">{capitalize(tag)}</Span>
              </ItemTag>
            ))}
          </ItemTagList>
        )}
      </ItemDescription>
      <ItemSelctionWrapper>
        <Select
          value={selectValue}
          options={selectOptions}
          onChange={onCategoryChange}
          isDisabled={!accepted}
        />
      </ItemSelctionWrapper>
      <ItemActionWrapper>
        <Span>
          {intl.formatMessage({ id: 'deliverect.import.modal.importToggle' })}
        </Span>
        <ToggleButton active={accepted} onActive={accept} onInactive={reject} />
      </ItemActionWrapper>
    </ItemPanel>
  )
}
