import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react'
import localForage from 'localforage'
import { AnimatePresence } from 'framer-motion'
import {
  ActionBar,
  DesktopMenuWrapper,
  SidebarBackdrop,
  SidebarMenu,
} from './styles'
import { useHistory } from 'react-router-dom'
import { ActionButton } from 'design-system/ActionButton'
import { Close } from 'design-system/icons/close'
import {
  breakpoints,
  useIsDesktopScreen,
} from 'design-system/theme/breakpoints'
import { MainMenu } from 'components/Menu/MainMenu'
import useDisableBodyScroll from 'utils/useDisableBodyScroll'

interface Props {
  isOpen: boolean
  close: () => void
}

export type MenuContext = {
  navigateTo: (toPath: string) => VoidFunction
  closeMenu: VoidFunction
}
const Context = createContext<MenuContext>({
  navigateTo: (_) => () => null,
  closeMenu: () => null,
})
const Menu: FC<Props> = ({ isOpen, close }) => {
  const isDesktopScreen = useIsDesktopScreen()
  const [, setPadTop] = useState(false)
  const history = useHistory()

  const navigateTo = (path: string) => () => {
    window.analytics.track('navigated_to', {
      path,
    })
    history.push(path)
    close()
  }
  useEffect(() => {
    localForage.getItem('isNativeApp').then((isNativeApp) => {
      if (isNativeApp) {
        setPadTop(true)
      }
    })
  })

  useDisableBodyScroll({
    shouldDisable: isOpen && !isDesktopScreen,
  })

  const startXSideBarAnimation = !isDesktopScreen
    ? breakpoints.medium.maxWidth
    : -450

  if (isDesktopScreen) {
    return (
      <Context.Provider
        value={{
          navigateTo,
          closeMenu: close,
        }}
      >
        <DesktopMenuWrapper>
          <MainMenu />
        </DesktopMenuWrapper>
      </Context.Provider>
    )
  }

  return (
    <Context.Provider
      value={{
        navigateTo,
        closeMenu: close,
      }}
    >
      <AnimatePresence>
        {isOpen && [
          <SidebarBackdrop
            animate="visible"
            initial="hidden"
            variants={{
              visible: {
                opacity: 0.5,
                transition: { duration: 0.3 },
              },
              hidden: {
                opacity: 0,
                transition: {
                  duration: 0,
                },
              },
            }}
            exit={{ opacity: 0 }}
            key="backdrop"
            onClick={close}
          />,
          <SidebarMenu
            animate="visible"
            initial="hidden"
            variants={{
              visible: {
                x: 0,
                opacity: 1,
                transition: { duration: 0.3 },
              },
              hidden: {
                x: startXSideBarAnimation,
                opacity: 0,
                transition: {
                  duration: 0,
                },
              },
            }}
            exit={{
              x: startXSideBarAnimation,
              opacity: 0,
              transition: { duration: 0.3 },
            }}
            key="menu"
          >
            <ActionBar>
              <ActionButton
                size="medium"
                color={{ type: 'gray' }}
                icon={Close}
                onClick={close}
              />
            </ActionBar>
            <MainMenu />
          </SidebarMenu>,
        ]}
      </AnimatePresence>
    </Context.Provider>
  )
}

export const useMenu = (): MenuContext => useContext(Context)

export default Menu
