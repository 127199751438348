import { useQuery } from '@apollo/client'
import { getActiveLocationMenu } from '___generated___/getActiveLocationMenu'
import { GET_ACTIVE_LOCATION_MENU } from 'components/Menu/queries'

const useActiveLocation = () => {
  const { data, loading, error } = useQuery<getActiveLocationMenu>(
    GET_ACTIVE_LOCATION_MENU
  )
  const location = data?.current.location
  return {
    location,
    loading,
    error,
  }
}

export default useActiveLocation
