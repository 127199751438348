import { ColorScale } from '..'

export const grayscale: ColorScale = {
  0: '#FFFFFF',
  10: '#F9F7F7',
  20: '#F4F1F1',
  30: '#EAE6E6',
  40: '#DFD9D9',
  50: '#D2CCCC',
  60: '#C1BABC',
  70: '#9F989A',
  80: '#736C6E',
  90: ' #413C3D',
  100: '#302D2D',
}
