import { useLazyQuery } from '@apollo/client'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { useNotificationBar } from 'design-system/NotificationBar'
import { gql } from '@apollo/client'
import forEach from 'lodash/forEach'
import { useEffect } from 'react'
import {
  newRetailerMessages,
  newRetailerMessagesVariables,
} from '___generated___/newRetailerMessages'
import { useListenForEvent } from '../hooks/useListenForEvent'

export const newRetailerMessagesQuery = gql`
  query newRetailerMessages($locationId: ID!) {
    location(locationId: $locationId) {
      unreadMessages {
        content
      }
    }
  }
`

type Data = newRetailerMessages
type Vars = newRetailerMessagesVariables

export const useListenForRetailerMessages = () => {
  const { locationId } = useCurrentUser()
  const { showInfoNotification } = useNotificationBar()

  const [fetchUnreadMessages] = useLazyQuery<Data, Vars>(
    newRetailerMessagesQuery,
    {
      fetchPolicy: 'network-only',
      variables: { locationId },
      onCompleted: (data) => {
        const textContents =
          data?.location?.unreadMessages?.map((message) => message.content) ??
          []
        forEach(textContents, (textContent) => {
          showInfoNotification({
            id: `retailerMessage.${textContent}`,
            message: textContent,
          })
        })
      },
    }
  )

  useEffect(() => {
    // Always fetch messages on app start or location change
    if (locationId) {
      fetchUnreadMessages()
    }
  }, [locationId, fetchUnreadMessages])
  useListenForEvent('NEW_RETAILER_MESSAGE', 'global.notification', () => {
    fetchUnreadMessages()
  })
}
