import React from 'react'
import { getSvgProps } from './utils'
import { DesignSystemIcon } from './types'

export const Groceries: DesignSystemIcon = (props) => {
  return (
    <svg viewBox="0 0 30 30" {...getSvgProps(props)}>
      <path
        d="M18 1C17.448 1 17 1.448 17 2C17 2.3634 17.204 2.66865 17.4941 2.84375L15.8555 5.57422C15.2955 6.50622 15 7.57511 15 8.66211V9H24V8.66211C24 7.57411 23.7035 6.50622 23.1445 5.57422L21.5059 2.84375C21.796 2.66865 22 2.3634 22 2C22 1.448 21.552 1 21 1H18ZM12.5 4C11.662 4 10.9038 4.30588 10.3008 4.79688L13.2422 6.75781C13.4322 5.98281 13.7266 5.23588 14.1406 4.54688L14.1934 4.45508C13.6894 4.17408 13.118 4 12.5 4ZM7.56055 5.48828C7.13197 5.42153 6.68506 5.62988 6.47656 6.04688L5 9H13L7.97461 5.64844C7.84536 5.56244 7.70341 5.51053 7.56055 5.48828ZM3 11C2.448 11 2 11.448 2 12V14C2 14.552 2.448 15 3 15H3.16602L4.7207 24.3281C4.8817 25.2921 5.71636 26 6.69336 26H23.3047C24.2827 26 25.1163 25.2921 25.2773 24.3281L26.834 15H27C27.552 15 28 14.552 28 14V12C28 11.448 27.552 11 27 11H3ZM11 15C11.553 15 12 15.447 12 16V22C12 22.553 11.553 23 11 23C10.447 23 10 22.553 10 22V16C10 15.447 10.447 15 11 15ZM15 15C15.553 15 16 15.447 16 16V22C16 22.553 15.553 23 15 23C14.447 23 14 22.553 14 22V16C14 15.447 14.447 15 15 15ZM19 15C19.553 15 20 15.447 20 16V22C20 22.553 19.553 23 19 23C18.447 23 18 22.553 18 22V16C18 15.447 18.447 15 19 15Z"
        style={{ stroke: 'transparent' }}
        fill={props.color}
      />
    </svg>
  )
}
