import React from 'react'
import { Spinner } from 'design-system/Spinner'
import useActiveLocation from 'components/Menu/hooks/useActiveLocation'
import {
  ActiveLocationContact,
  ActiveLocationLogo,
  ActiveLocationNoImage,
  ActiveLocationTitles,
  ActiveLocationWrapper,
} from 'components/Menu/ActiveLocation.styles'
import { Span } from 'design-system/Typography/Span'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { getInitials } from 'utils/stringUtils'
import { useIntl } from 'react-intl'

const ActiveLocation: React.FC = () => {
  const { formatNumber, formatMessage } = useIntl()
  const { location: activeLocation, loading, error } = useActiveLocation()
  const { user } = useCurrentUser()

  if (loading) {
    return (
      <Spinner
        loading={true}
        style={{ width: '60px', height: '60px' }}
        color="black"
        type="circle"
      />
    )
  }
  if (error || !activeLocation) {
    return <></>
  }
  const locationLogo = activeLocation?.logo_image_url
  return (
    <ActiveLocationWrapper>
      <ActiveLocationContact>
        <ActiveLocationLogo>
          {locationLogo ? (
            <img src={locationLogo} alt="Store logo" />
          ) : (
            <ActiveLocationNoImage>
              <Span variant="medium" fontWeight={600} color="white">
                {getInitials(activeLocation?.name ?? '')}
              </Span>
            </ActiveLocationNoImage>
          )}
        </ActiveLocationLogo>
        <ActiveLocationTitles>
          <Span fontWeight={600} variant="medium" color="darkGrey">
            {activeLocation.name}
          </Span>
          <Span variant="medium" color="grey">
            {user.name}
          </Span>
        </ActiveLocationTitles>
      </ActiveLocationContact>
      <Span fontWeight={500} color="grey">
        {formatMessage(
          {
            id: 'active-location.followers',
          },
          {
            amount: formatNumber(activeLocation.no_of_followers ?? 0, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
              notation: 'compact',
            }),
          }
        )}
      </Span>
    </ActiveLocationWrapper>
  )
}

export { ActiveLocation }
