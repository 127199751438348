import React from 'react'
import compose from 'lodash/flowRight'
import axios from 'axios'
import { Container, Logo, SignupForm } from './styles'
import { H1 } from 'design-system/Typography/H1'
import { Paragraph } from 'design-system/Typography/Paragraph'
import { Button } from 'design-system/Button'
import { injectIntl } from 'react-intl'
import { InfoBanner } from '../InfoBanner'
import logo from 'static/Logo/KarmaK.svg'
import { LOGIN } from 'routes/paths'
import { TextField } from '../../design-system/TextField'

class CreateUserBase extends React.Component {
  state = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    sending: false,
    registerError: null,
  }

  componentDidMount() {
    this.setState({
      email: this.props.email,
    })
  }

  setField = (field) => (e) => {
    this.setState({
      [field]: e.target.value,
    })
  }

  registerAndAccept = async () => {
    const { invitationToken, history } = this.props
    const { name, email, password } = this.state

    const API_URL = process.env.REACT_APP_API_URL

    const url = `${API_URL}/auth/accept-invitation-new-user`

    if (this.allFieldsValid()) {
      this.setState({ sending: true })
      const { data: body } = await axios(url, {
        method: 'POST',
        data: {
          name,
          email,
          invitationToken,
          password,
        },
      })
      if (body.success) {
        history.push(
          LOGIN.create({ queries: { banner: 'registration_success' } })
        )
      } else {
        this.setState({
          sending: false,
          registerError: (body.error && body.error.code) || 'UNKNOWN',
        })
      }
    }
  }

  emailIsValid = () => {
    const { email } = this.state
    const emailRegex = new RegExp('^[^@]+@[^@]+\\.[^@]*[^.@]$')
    return email === '' || emailRegex.test(email)
  }

  nameIsValid = () => {
    const { name } = this.state
    return name.trim().length >= 3
  }

  passwordTooShort = () => {
    const { password } = this.state
    return password.length < 6
  }

  passwordsDontMatch = () => {
    const { password, confirmPassword } = this.state
    return password !== confirmPassword
  }

  allFieldsValid = () => {
    const passwordIsValid =
      !this.passwordTooShort() && !this.passwordsDontMatch()
    const emailIsValid = this.emailIsValid()
    const nameIsValid = this.nameIsValid()
    return passwordIsValid && emailIsValid && nameIsValid
  }

  getLoginLink = () => {
    const {
      location: { pathname, search },
    } = this.props
    const encodedSearch = encodeURIComponent(pathname + search)
    return `/login?login_redirect_url=${encodedSearch}`
  }

  getRegisterErrorText = () => {
    const { registerError } = this.state
    const { intl } = this.props
    if (registerError === 'INVALID_TOKEN') {
      return intl.formatMessage({ id: 'invitation.signup.errors.invalidToken' })
    }
    if (registerError === 'MISSING_VALUES') {
      return intl.formatMessage({
        id: 'invitation.signup.errors.missingValues',
      })
    }
    return intl.formatMessage({ id: 'errors.general.contactSupport' })
  }

  render() {
    const { locationName, intl } = this.props
    const {
      name,
      email,
      password,
      confirmPassword,
      registerError,
      sending,
    } = this.state

    const loginLink = this.getLoginLink()
    const buttonText = sending
      ? intl.formatMessage({ id: 'invitation.signup.button.sending' })
      : intl.formatMessage({ id: 'invitation.signup.button.register' })

    return (
      <>
        {registerError && <InfoBanner text={this.getRegisterErrorText()} />}
        <Container>
          <Logo src={logo} />
          <H1>
            {intl.formatMessage({ id: 'invitation.header' }, { locationName })}
          </H1>
          <Paragraph color="grey" variant="medium">
            {intl.formatMessage(
              { id: 'invitation.description' },
              { locationName }
            )}
          </Paragraph>
          <a href={loginLink}>
            <Paragraph color="grey" variant="medium">
              {intl.formatMessage({ id: 'invitation.hasAccountLink' })}
            </Paragraph>
          </a>
          <SignupForm onSubmit={(e) => e.preventDefault()}>
            <TextField
              value={name}
              label={intl.formatMessage({ id: 'invitation.signup.name' })}
              onChange={this.setField('name')}
            />
            <TextField
              value={email}
              error={
                !this.emailIsValid() &&
                intl.formatMessage({
                  id: 'invitation.signup.invalidEmail',
                })
              }
              labelText={intl.formatMessage({ id: 'signup.form.mail' })}
              onChange={this.setField('email')}
            />
            <TextField
              type="password"
              value={password}
              label={intl.formatMessage({ id: 'signup.form.password' })}
              error={
                password.length &&
                this.passwordTooShort() &&
                intl.formatMessage({
                  id: 'signup.errors.passwordlength',
                })
              }
              onChange={this.setField('password')}
            />
            <TextField
              type="password"
              value={confirmPassword}
              label={intl.formatMessage({
                id: 'signup.form.confirmpassword',
              })}
              error={
                this.passwordsDontMatch() &&
                intl.formatMessage({
                  id: 'signup.errors.nomatchconfirmpassword',
                })
              }
              onChange={this.setField('confirmPassword')}
            />
            <Button
              colorScheme="positive"
              onClick={this.registerAndAccept}
              disabled={!this.allFieldsValid() || sending}
            >
              <Paragraph variant="medium" color="white">
                {buttonText}
              </Paragraph>
            </Button>
          </SignupForm>
        </Container>
      </>
    )
  }
}

const enhance = compose(injectIntl)

export const CreateUser = enhance(CreateUserBase)
