import { useScreenSize } from 'design-system/theme/breakpoints'
import {
  NavButton,
  NavIconLabel,
  NavButtonBadge,
  NonActionNavButtonBadge,
} from 'components/Navbar/styles'
import React from 'react'
import { Span } from 'design-system/Typography/Span'
import { NewColor, useColor } from 'design-system/theme/newColors'

interface Props {
  onClick: VoidFunction
  icon: React.ReactNode
  label: string
  isActive?: boolean
  badgeText?: React.ReactNode
  badgeIsActionable?: boolean
  badgeColor?: NewColor
}

const NavBarItem = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      onClick,
      icon,
      label,
      isActive = false,
      badgeText,
      badgeIsActionable = true,
      badgeColor,
    },
    ref
  ) => {
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'small'
    const color = useColor(isActive ? 'plum' : 'darkGrey')
    return (
      <NavButton onClick={onClick} active={isActive} color={color} ref={ref}>
        {icon}
        <NavIconLabel
          fontWeight={isActive ? 600 : 400}
          variant={isMobile ? 'medium' : 'large'}
        >
          <Span
            color={isActive ? 'plum' : 'darkGrey'}
            variant={isMobile ? 'small' : 'medium'}
          >
            {label}
          </Span>
        </NavIconLabel>
        {badgeText &&
          (badgeIsActionable ? (
            <NavButtonBadge backgroundColor={badgeColor ?? 'jade'}>
              <Span color="white" variant="small">
                {badgeText}
              </Span>
            </NavButtonBadge>
          ) : (
            <NonActionNavButtonBadge>
              <Span color="black" variant="small">
                {badgeText}
              </Span>
            </NonActionNavButtonBadge>
          ))}
      </NavButton>
    )
  }
)

export { NavBarItem }
