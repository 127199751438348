import React, { useCallback } from 'react'
import { NewColor, useColor } from 'design-system/theme/newColors'
import {
  Anchor,
  H1Style,
  H2Style,
  H3Style,
  H4Style,
  H5Style,
} from './headerStyle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router'
import { Margins4 } from 'design-system/theme/margins'

export interface HeaderProps {
  color?: NewColor
  id?: string
  size: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  className?: string
  bottomMargin?: keyof Margins4['typography']['headings']
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
}

const Header: React.FC<HeaderProps> = ({
  className,
  children,
  color = 'black',
  id,
  size,
  bottomMargin,
  textAlign,
}) => {
  const location = useLocation()
  const measuredRef = useCallback(
    (node) => {
      if (!node) {
        return
      }
      if (location.hash === `#${id}`) {
        node.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    [id, location.hash]
  )
  const colorHex = useColor(color)
  if (size === 'h1') {
    return (
      <H1Style
        ref={measuredRef}
        color={colorHex}
        id={id}
        className={className}
        bottomMargin={bottomMargin}
        textAlign={textAlign}
      >
        {children}
        {id && (
          <Anchor to={`#${id}`}>
            <FontAwesomeIcon icon={faLink} />
          </Anchor>
        )}
      </H1Style>
    )
  }
  if (size === 'h2') {
    return (
      <H2Style
        ref={measuredRef}
        color={colorHex}
        id={id}
        className={className}
        bottomMargin={bottomMargin}
        textAlign={textAlign}
      >
        {children}
        {id && (
          <Anchor to={`#${id}`}>
            <FontAwesomeIcon icon={faLink} />
          </Anchor>
        )}
      </H2Style>
    )
  }
  if (size === 'h3') {
    return (
      <H3Style
        ref={measuredRef}
        color={colorHex}
        id={id}
        className={className}
        bottomMargin={bottomMargin}
        textAlign={textAlign}
      >
        {children}
        {id && (
          <Anchor to={`#${id}`}>
            <FontAwesomeIcon icon={faLink} />
          </Anchor>
        )}
      </H3Style>
    )
  }
  if (size === 'h4') {
    return (
      <H4Style
        ref={measuredRef}
        color={colorHex}
        id={id}
        className={className}
        bottomMargin={bottomMargin}
        textAlign={textAlign}
      >
        {children}
        {id && (
          <Anchor to={`#${id}`}>
            <FontAwesomeIcon icon={faLink} />
          </Anchor>
        )}
      </H4Style>
    )
  }
  return (
    <H5Style
      ref={measuredRef}
      color={colorHex}
      id={id}
      className={className}
      bottomMargin={bottomMargin}
      textAlign={textAlign}
    >
      {children}
      {id && (
        <Anchor to={`#${id}`}>
          <FontAwesomeIcon icon={faLink} />
        </Anchor>
      )}
    </H5Style>
  )
}

export { Header }
