import styled from 'styled-components/macro'

export const SettingsMenuHeader = styled.div`
  margin: ${({ theme }) => {
    const { medium, large } = theme.v4.margins.typography.headings
    return `${large} ${medium}`
  }};
`

export const SettingsMenuList = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`
