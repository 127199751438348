const polyFill = async () => {
  if (window.ResizeObserver) {
    return
  }
  const { ResizeObserver } = await import('@juggle/resize-observer')
  Object.defineProperty(window, 'ResizeObserver', {
    writable: true,
    value: ResizeObserver,
  })
  window.ResizeObserver = ResizeObserver
}

export default polyFill()
