import React, { FC, useRef } from 'react'
import { Paragraph } from 'design-system/Typography/Paragraph'
import {
  TutorialActions,
  TutorialToolTipContainer,
  TutorialToolTipInfo,
  TutoroialToolTipBody,
} from './styles'
import { H5 } from 'design-system/Typography/H5'
import { useIntl } from 'react-intl'
import { Span } from 'design-system/Typography/Span'
import { Button } from 'design-system/Button'
import { usePopper } from 'react-popper'
import useOutsideClick from 'utils/useOutsideClick'

export type TooltipPosition = {
  x: number
  y: number
  vertical: 'above' | 'below'
  horizontal: 'left' | 'right'
}
interface Props extends TooltipPosition {
  title: string
  paragraph: string
  onClick: () => void
  onCancelClick?: VoidFunction
  positiveActionText?: string
  negativeActionText?: string
  target: HTMLElement
}

const TutorialToolTip: FC<Props> = ({
  title,
  paragraph,
  onClick,
  onCancelClick,
  positiveActionText,
  negativeActionText,
  target,
}) => {
  const toolTipelement = useRef(null)
  useOutsideClick({
    ref: toolTipelement,
    callback: onClick,
    shouldListen: true,
  })
  const p = usePopper(target, toolTipelement.current, {
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 10],
        },
      },
    ],
  })
  const { formatMessage } = useIntl()
  return (
    <TutorialToolTipContainer
      ref={toolTipelement}
      style={p.styles.popper}
      {...p.attributes.popper}
    >
      <TutorialToolTipInfo>
        <H5>{title}</H5>
        <TutoroialToolTipBody>
          <Paragraph>{paragraph}</Paragraph>
        </TutoroialToolTipBody>
      </TutorialToolTipInfo>
      <TutorialActions>
        <Button height="medium" colorScheme="positive" onClick={onClick}>
          <Span color="white">
            {positiveActionText || formatMessage({ id: 'common.close' })}
          </Span>
        </Button>
        {negativeActionText && (
          <Button
            height="medium"
            colorScheme="negative"
            onClick={onCancelClick || onClick}
          >
            <Span color="white">{negativeActionText}</Span>
          </Button>
        )}
      </TutorialActions>
    </TutorialToolTipContainer>
  )
}

export { TutorialToolTip }
