import { gql, useLazyQuery } from '@apollo/client'
import { TRANSACTION_DATA_FRAGMENT } from 'components/Orders/queries'
import { useNotificationBar } from 'design-system/NotificationBar'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { ORDERS } from 'routes/paths'
import {
  getOrderData,
  getOrderDataVariables,
} from '___generated___/getOrderData'
import { useListenForEvent } from '../hooks/useListenForEvent'

type Data = getOrderData
type Vars = getOrderDataVariables

const getOrderDataQuery = gql`
  query getOrderData($transactionId: ID!) {
    transaction(transactionId: $transactionId) {
      id
      ...TransactionData
    }
  }
  ${TRANSACTION_DATA_FRAGMENT}
`

export const useListenForOrders = () => {
  const [fetchOrderData, queryResult] = useLazyQuery<Data, Vars>(
    getOrderDataQuery
  )
  const { showInfoNotification } = useNotificationBar()
  const history = useHistory()
  const { formatMessage } = useIntl()

  useListenForEvent('PAYMENT_CREATED', 'listenForOrders', (message) => {
    const transactionId = message.payload.paymentId.toString()
    fetchOrderData({
      variables: {
        transactionId,
      },
    })
  })
  const order = queryResult.data?.transaction
  const orderId = order?.id
  const username = order?.name
  const goToOrders = () => history.push(ORDERS)

  useEffect(() => {
    if (!orderId || !username) {
      return
    }
    const message = formatMessage(
      { id: 'transactions.alert.newOrder' },
      { username }
    )
    showInfoNotification({
      id: `newOrder.${orderId}`,
      message,
      onClick: goToOrders,
    })
  }, [orderId, username]) // eslint-disable-line react-hooks/exhaustive-deps
}
