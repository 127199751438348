import React from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components/macro'
import Spinner from 'react-spinkit'
import { NewColor, useColor } from 'design-system/theme/newColors'

export type ButtonHeight = 'medium' | 'large'
export const buttonHeightMap: Record<ButtonHeight, number> = {
  medium: 36,
  large: 50,
}

export type ColorScheme =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'positive'
  | 'negative'
  | 'warning'
export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  color?: NewColor
  textColor?: NewColor
  ghost?: boolean
  disabled?: boolean
  actionType?: 'primary' | 'secondary'
  colorScheme?: ColorScheme
  height?: ButtonHeight
  loading?: boolean
  transparent?: boolean
  locked?: boolean
}

const StyledButton = styled.button`
  width: 100%;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: none;
  opacity: ${({ disabled }) => (disabled ? '0.7' : 1)};

  /* Some space between child elements */
  & > * + * {
    margin-left: 8px;
  }

  &:hover {
    filter: brightness(95%);
  }
  &:active {
    filter: brightness(90%);
  }
  &:focus {
    outline: 0;
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.v3.colors.black}, 0 2px 4px rgba(0, 0, 0, 0.16)`};
  }
  &:disabled {
    cursor: not-allowed;
  }
`

const getColor = (
  ghost: boolean,
  colorScheme: ColorScheme,
  theme: DefaultTheme,
  textColor?: NewColor
) => {
  if (textColor) {
    return theme.v3.colors[textColor]
  }
  if (ghost) {
    return theme.v3.colors.action
  }
  if (colorScheme) {
    return getTextColorFromScheme(colorScheme, theme)
  }
  return theme.v3.colors.white
}

const getBackColorFromScheme = (
  colorScheme: ColorScheme,
  theme: DefaultTheme
) => {
  switch (colorScheme) {
    case 'primary':
      return theme.v3.colors.black
    case 'secondary':
      return theme.v3.colors.white
    case 'tertiary':
      return theme.v3.colors.lightGrey
    case 'accent':
      return theme.v3.colors.accent
    case 'positive':
      return theme.v3.colors.action
    case 'negative':
      return theme.v3.colors.negative
    case 'warning':
      return theme.v3.colors.warning
    default:
      return theme.v3.colors.black
  }
}

const getTextColorFromScheme = (
  colorScheme: ColorScheme,
  theme: DefaultTheme
) => {
  if (colorScheme === 'secondary' || colorScheme === 'tertiary') {
    return theme.v3.colors.black
  } else {
    return theme.v3.colors.white
  }
}

const getBackgroundColor = (
  colorScheme: ColorScheme,
  ghost: boolean,
  theme: DefaultTheme,
  color?: NewColor,
  transparent?: boolean,
  locked?: boolean
) => {
  if (locked) {
    return theme.v3.colors.locked
  }
  if (ghost) {
    return theme.v3.colors.white
  }
  if (transparent) {
    return 'transparent'
  }
  return color
    ? theme.v3.colors[color]
    : getBackColorFromScheme(colorScheme, theme)
}

const getBorderProps = (
  textColor: NewColor,
  colorScheme: ColorScheme,
  ghost: boolean,
  theme: DefaultTheme
) => {
  if (ghost || colorScheme === 'secondary') {
    return {
      border: `${theme.v3.borderWidths.button} solid ${theme.v3.colors[textColor]}`,
    }
  }
  return {
    border: 'none',
  }
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color,
      textColor,
      ghost = false,
      disabled = false,
      children,
      actionType = 'primary',
      colorScheme = 'primary',
      height = 'large',
      loading = false,
      transparent = false,
      locked = false,
      ...rest
    },
    ref
  ) => {
    const theme: DefaultTheme = useTheme()

    const style: React.CSSProperties = {
      color: getColor(ghost, colorScheme, theme, textColor),
      padding: `0px ${theme.v3.paddings.button.fluffy}`,
      backgroundColor: getBackgroundColor(
        colorScheme,
        ghost,
        theme,
        color,
        transparent,
        locked
      ),
      borderRadius:
        actionType === 'primary'
          ? theme.v3.borderRadius.small
          : theme.v3.borderRadius.large,
      fontWeight: theme.v3.fontWeights.heavy,
      height: `${buttonHeightMap[height]}px`,
      ...getBorderProps(textColor || 'action', colorScheme, ghost, theme),
    }
    return (
      <StyledButton
        {...(rest as any)}
        disabled={disabled || loading || locked}
        style={style}
        ref={ref}
      >
        {loading && <ButtonSpinner hexColor={style.color} />}
        {children}
      </StyledButton>
    )
  }
)

interface Props {
  color?: NewColor
  hexColor?: string
}

export const ButtonSpinner: React.FC<Props> = ({ color, hexColor }) => {
  const spinColor = useColor(color ?? 'white')
  return (
    <Spinner
      name="circle"
      fadeIn="none"
      color={hexColor ?? spinColor}
      style={{
        height: '30px',
        width: '30px',
        marginRight: '10px',
      }}
    />
  )
}
