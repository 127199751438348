import React, { FC } from 'react'
import { getProfilesWithAchievements_location_profiles_achievementGroups_achievements } from '___generated___/getProfilesWithAchievements'
import { H3 } from 'design-system/Typography/H3'
import { Paragraph } from 'design-system/Typography/Paragraph'
import {
  CompletedUnseenAchievementDescription,
  CompletedUnseenAchievementWrapper,
} from 'components/Achievement/CompletedUnseenAchievement.styles'
import { TranslationObjectKey } from 'components/Achievement/types'
import { useIntl } from 'react-intl'

interface Props {
  achievement: getProfilesWithAchievements_location_profiles_achievementGroups_achievements
}

const CompletedUnseenAchievement: FC<Props> = ({ achievement }) => {
  const { locale } = useIntl()
  if (!achievement) {
    return null
  }
  return (
    <CompletedUnseenAchievementWrapper>
      <H3>{achievement.title[locale as TranslationObjectKey]}</H3>
      <CompletedUnseenAchievementDescription>
        <Paragraph>
          {achievement.description[locale as TranslationObjectKey]}
        </Paragraph>
      </CompletedUnseenAchievementDescription>
    </CompletedUnseenAchievementWrapper>
  )
}

export { CompletedUnseenAchievement }
