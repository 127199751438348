import React from 'react'
import Loading from '../Loading'
import { FullPageLoadingContainer } from './styles'

const LoadingPage = () => (
  <FullPageLoadingContainer>
    <Loading />
  </FullPageLoadingContainer>
)

export default LoadingPage
