import React, { ChangeEvent, FC } from 'react'
import Search from 'icons/search'
import { TextField } from 'design-system/TextField'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClearSearchInput } from './SearchInput.styles'

interface Props extends React.HTMLProps<HTMLInputElement> {
  value: string
  onValueChange: (newValue: string) => void
  onFocus?: () => void
  onBlur?: () => void
  customIcon?: React.ReactNode | false
}

export const SearchInput: FC<Props> = ({
  value,
  onValueChange,
  onFocus,
  onBlur,
  customIcon,
  ...props
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onValueChange(e.target.value)
  }
  const handleClear = () => {
    onValueChange('')
  }

  return (
    <TextField
      {...(props as any)}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      leading={customIcon ? customIcon : <Search />}
      trailing={
        <ClearSearchInput disabled={value === ''} onClick={handleClear}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </ClearSearchInput>
      }
      dense
    />
  )
}
