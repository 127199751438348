import { useMutation } from '@apollo/client'
import { MARK_ACHIEVEMENTS_AS_SEEN } from 'components/Achievement/queries'
import useGetActiveProfile from 'components/LocationUserManagement/hooks/useGetActiveProfile'

const useMarkCompletedAchievement = () => {
  const { completedUnSeenAchievements } = useGetActiveProfile()
  const [mutate, { loading }] = useMutation(MARK_ACHIEVEMENTS_AS_SEEN)
  const markAsSeen = async () => {
    await mutate({
      variables: {
        achievementIds: completedUnSeenAchievements.map(
          (ach) => ach.instanceId
        ),
      },
    })
  }
  return {
    markAsSeen,
    loading,
  }
}

export default useMarkCompletedAchievement
