import styled from 'styled-components/macro'

export const BaseCardWrapper = styled.button<{ active: boolean }>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin: 0;
  padding: 0;
  outline: none;
  background: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 33px 80px rgba(0, 0, 0, 0.07), 0 14px 33px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: ${({ theme, active }) =>
    active ? `2px solid ${theme.v3.colors.black}` : 'none'};
`

export const BaseCardHeader = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-top-left-radius: ${({ theme }) => theme.v3.borderRadius.small};
  border-top-right-radius: ${({ theme }) => theme.v3.borderRadius.small};
`

export const BaseCardBody = styled.div`
  position: relative;
  min-height: 56px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: ${({ theme }) => theme.v4.margins.content.small};
  background-color: ${({ theme }) => theme.v3.colorScales.grey[0]};
  border-bottom-left-radius: ${({ theme }) => theme.v3.borderRadius.small};
  border-bottom-right-radius: ${({ theme }) => theme.v3.borderRadius.small};
`
