import { useQuery, useMutation } from '@apollo/client'

import { getTutorialProgress } from '___generated___/getTutorialProgress'

import {
  GET_TUTORIAL_PROGRESS,
  SET_TUTORIAL_COMPLETED,
} from '../tutorialQueries'

import {
  setTutorialProgress,
  setTutorialProgressVariables,
} from '___generated___/setTutorialProgress'

export const useTutorialQuery = () => {
  const { data, loading } = useQuery<getTutorialProgress>(GET_TUTORIAL_PROGRESS)
  const [saveCompletedTutorial] = useMutation<
    setTutorialProgress,
    setTutorialProgressVariables
  >(SET_TUTORIAL_COMPLETED, {
    refetchQueries: [
      {
        query: GET_TUTORIAL_PROGRESS,
      },
    ],
  })
  const completeTutorial = async ({ tutorialId }: { tutorialId: string }) => {
    await saveCompletedTutorial({ variables: { key: tutorialId } })
  }
  return {
    loading,
    data: data?.current.user.tutorialProgress.filter((t) => t.completed) ?? [],
    completeTutorial,
  }
}
