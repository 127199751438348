export const toSetMap = <T>(xs: T[], idMapper: (x: T) => string) =>
  xs.reduce(
    (acc, x) => ({
      ...acc,
      [idMapper(x)]: true as true,
    }),
    {} as { [id: string]: true }
  )

export const toObjectMap = <T>(xs: T[], idMapper: (x: T) => string) =>
  xs.reduce(
    (acc, x) => ({
      ...acc,
      [idMapper(x)]: x,
    }),
    {} as { [id: string]: T }
  )

export const toHashMap = <T>(xs: T[], hashFn: (x: T) => string) =>
  xs.reduce((acc, x) => {
    const hash = hashFn(x)
    const currentBucket = acc[hash] || []
    return {
      ...acc,
      [hash]: [x, ...currentBucket],
    }
  }, {} as { [id: string]: T[] })

export const toConstantValueMap = <T, V>(
  xs: T[],
  idMapper: (x: T) => string,
  value: V
) =>
  xs.reduce(
    (acc, x) => ({
      ...acc,
      [idMapper(x)]: value,
    }),
    {} as { [id: string]: V }
  )
