import { ColorScales } from '..'
import { grayscale } from './grayscale'
import { karma } from './karma'
import { green } from './green'
import { blue } from './blue'
import { yellow } from './yellow'
import { purple } from './purple'
import { flamingo } from './flamingo'

export function createColors(): ColorScales {
  return {
    grayscale,
    karma,
    green,
    blue,
    yellow,
    purple,
    flamingo,
  }
}
