import uniq from 'lodash/uniq'
import React from 'react'
import { TutorialId } from 'providers/tutorial/TUTORIALS'

export interface Tutorial {
  id: TutorialId
  title: string
  paragraph: string
  onClick?: VoidFunction
  positiveActionText?: string
  onCancelClick?: VoidFunction
  negativeActionText?: string
  ref?: React.RefObject<HTMLDivElement>
  isModalTutorial?: false
}

export interface FullscreenTutorial
  extends Pick<Tutorial, 'id' | 'onClick' | 'onCancelClick'> {
  isModalTutorial?: true
}

type State = {
  allIds: string[]
  unCompletedIds: string[]
  completedIds: string[]
  byId: {
    [key: string]: Tutorial
  }
  loading: boolean
}
type Action =
  | { type: 'ADD_TUTORIAL'; payload: { tutorial: Tutorial } }
  | {
      type: 'ADD_FULLSCREEN_TUTORIAL'
      payload: { tutorial: FullscreenTutorial }
    }
  | { type: 'COMPLETE_TUTORIAL'; payload: { id: string } }
  | { type: 'LOAD_COMPLETED_TUTORIALS'; payload: { completedIds: string[] } }

export const tutorialReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'ADD_TUTORIAL': {
      const id = action.payload.tutorial.id
      if (
        state.unCompletedIds.includes(id) ||
        state.completedIds.includes(id) ||
        !action.payload.tutorial?.ref?.current
      ) {
        return state as State
      }
      const newState = {
        ...state,
        allIds: uniq([...state.allIds, id]),
        unCompletedIds: uniq([...state.unCompletedIds, id]),
        byId: {
          ...state.byId,
          [id]: action.payload.tutorial,
        },
      }
      return newState as State
    }
    case 'ADD_FULLSCREEN_TUTORIAL': {
      const id = action.payload.tutorial.id
      if (
        state.unCompletedIds.includes(id) ||
        state.completedIds.includes(id)
      ) {
        return state as State
      }
      const newState = {
        ...state,
        allIds: uniq([...state.allIds, id]),
        unCompletedIds: uniq([...state.unCompletedIds, id]),
        byId: {
          ...state.byId,
          [id]: action.payload.tutorial,
        },
      }
      return newState as State
    }
    case 'COMPLETE_TUTORIAL': {
      const id = action.payload.id
      return {
        ...state,
        unCompletedIds: state.unCompletedIds.filter(
          (uncompletedId) => uncompletedId !== id
        ),
        completedIds: uniq([...state.completedIds, id]),
      } as State
    }
    case 'LOAD_COMPLETED_TUTORIALS': {
      return {
        ...state,
        completedIds: action.payload.completedIds ?? [],
        loading: false,
      } as State
    }
    default:
      return state
  }
}
