import { ScreenSize, useScreenSize } from './breakpoints'
export interface GridParams {
  margin: string
  columns: number
  gutter: string
}

type LayoutGrids = { [BP in ScreenSize]: GridParams }

export const layoutGrids: LayoutGrids = {
  small: { margin: '20px', gutter: '16px', columns: 4 },
  medium: { margin: '36px', gutter: '36px', columns: 8 },
  large: { margin: '64px', gutter: '36px', columns: 12 },
  extraLarge: { margin: '64px', gutter: '36px', columns: 12 },
}

export const useGridParams = () => {
  const screenSize = useScreenSize()
  return layoutGrids[screenSize]
}
