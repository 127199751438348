import styled from 'styled-components/macro'

export const TutorialToolTipContainer = styled.div`
  min-width: 250px;
  background: ${({ theme }) => theme.v3.colors.white};
  padding: ${({ theme }) => theme.v3.margins.content.spacing.medium};
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.07);
  z-index: 2000;
`

export const TutorialToolTipInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const TutoroialToolTipBody = styled.div`
  margin: ${({ theme }) => theme.v3.margins.typography.headings.medium} 0;
  white-space: pre-wrap;
`

export const TutorialActions = styled.div`
  display: flex;
  flex-direction: row;
  & > :nth-child(n + 2) {
    margin-left: ${({ theme }) => theme.v3.margins.content.spacing.small};
  }
`
