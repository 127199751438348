import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;

  margin: auto;
  padding-top: 60px;
  max-width: 600px;
  text-align: center;
  & > * {
    margin-bottom: 20px;
  }
`

export const Logo = styled.img`
  display: flex;
  width: 120px;
  margin-bottom: 20px;
`

export const AcceptButton = styled.div`
  width: 250px;
`

export const SignupForm = styled.form`
  width: 100%;
  @media (min-width: 1001px) {
    width: 70%;
  }
`
