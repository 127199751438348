import { DateTime } from 'luxon'

const SALE_TIME_EXCEPTION = ({
  weeksFromNow = 1,
  closed = false,
  name = '',
}: {
  weeksFromNow?: number
  closed?: boolean
  name?: string
} = {}) => {
  const start = DateTime.local()
  return {
    __typename: 'ExceptionalSaleTime',
    id: Math.random().toString(36).substring(7),
    start_date: start.plus({ week: weeksFromNow }).toFormat('yyyy-MM-dd'),
    end_date: start.plus({ week: weeksFromNow, day: 1 }).toFormat('yyyy-MM-dd'),
    closed: closed,
    name: name,
    pickup_end_local: '18:00',
    pickup_start_local: '17:00',
    sale_end_local: '17:00',
    sale_start_local: '12:00',
  }
}

export default SALE_TIME_EXCEPTION
