import { Types } from '@karmalicious/realtime-sdk'
import { useEffect, useContext } from 'react'
import { RealtimeContext } from '../RealtimeProvider'

export const useListenForEvent = <T extends Types.MessageEvent>(
  event: T,
  id: string,
  callback: Types.OnMessage<T>
) => {
  const context = useContext(RealtimeContext)
  useEffect(() => {
    context.addHandler(event, { id, callback })
    return () => {
      context.removeHandler(event, id)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
