import { Fonts } from './styled'

export function createFonts() {
  const fonts: Fonts = {
    default: "'Inter', sans-serif",
    medium: "'Inter', sans-serif",
    bold: "'Inter', sans-serif",
  }

  return fonts
}
