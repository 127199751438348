import localForage from 'localforage'
import { defaults, resolvers, typeDefs } from './resolvers'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerError,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { RestLink } from 'apollo-link-rest'
import { createPersistedQueryLink } from '@apollo/link-persisted-queries'
import { DateTime } from 'luxon'
import { createApolloCache } from 'apollo/apolloCache'

const restLink = new RestLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
})

const withToken = setContext(async (_, { headers }) => {
  // if you have a cached value, return it immediately
  const karmaStorage = await localForage.getItem<{
    selectedProfileByLocation: {
      lastKnownProfileId: { id: string; expiresAt: string }
    }
  }>('karma-storage')
  const { id: lastKnownProfileId, expiresAt } =
    karmaStorage?.selectedProfileByLocation?.lastKnownProfileId ?? {}
  const hasProfileSelectionExpired = !expiresAt
    ? true
    : DateTime.fromISO(expiresAt) < DateTime.utc()
  const selectedProfileId = lastKnownProfileId &&
    !hasProfileSelectionExpired && {
      'retailer-profile-id': `${lastKnownProfileId}`,
    }

  const userToken = await localForage.getItem<string>('token')
  if (!userToken) {
    return
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${userToken}`,
      ...selectedProfileId,
    },
  }
})

const logOut = async () => {
  const hasToken = await localForage.getItem('token')
  if (!hasToken) {
    return
  }
  await localForage.removeItem('token')
  await localForage.removeItem('karma-storage')
  await cache.reset()
  window.location.reload()
}

// if the API returns a 401 error (Unauthorized) we'll reset the locally cached token
const resetToken = onError(({ networkError, graphQLErrors, response }) => {
  // remove cached token on 401 from the server
  if (networkError && networkError.name === 'ServerError') {
    const e = networkError as ServerError
    if (e.statusCode === 401) {
      logOut().then()
      if (response) {
        response.errors = undefined
      }
    }
  }
  // remove cached token on auth error
  if (
    graphQLErrors &&
    graphQLErrors.find(
      (error) =>
        error.message === 'UNAUTHENTICATED' ||
        error.message === 'NO_LOCATION_ACCESS' ||
        error.message === 'NO_ACTIVE_LOCATION_FOUND_OR_THERES_ERROR'
    )
  ) {
    logOut().then()
    if (response) {
      response.errors = undefined
    }
  }
})

// Persisted Cache
// IntrospectionFragmentMatcher needed for union types
const cache = createApolloCache()
// Lets bootstrap the CachePersistor from it's own module

// finally we'll create the client
export const client = new ApolloClient({
  cache,
  resolvers,
  typeDefs,
  link: ApolloLink.from([
    restLink,
    withToken,
    resetToken,
    createPersistedQueryLink(),
    createUploadLink({
      uri: process.env.REACT_APP_GQL_API_URL,
      credentials: 'same-origin',
    }),
  ]),
  name: process.env.REACT_APP_ENGINE_NAME,
  version: process.env.REACT_APP_ENGINE_VERSION,
})

const writeDefaults = (cache: InMemoryCache) => {
  cache.restore(defaults)
}
const resetCache = async () => writeDefaults(cache)
client.onResetStore(resetCache)
