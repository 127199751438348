import styled from 'styled-components/macro'

export const ModalContainer = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 200002;
`

export const LargeModal = styled.div`
  overflow-y: auto;
  width: 100%;
  max-width: 1000px;
  max-height: 95%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.v3.colors.white};
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  padding: ${({ theme }) => theme.v3.margins.content.spacing.large};
  ${({ theme }) => theme.v3.breakpoints.medium.mediaQuery} {
    width: 80%;
  }
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    width: 60%;
  }
`
