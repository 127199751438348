import React, { FC, createContext, useReducer } from 'react'
import { Modal } from 'components/Modal'

interface ContextState {
  isModalOpen: boolean
  openModal: (modalContent: React.ReactNode) => void
  closeModal: VoidFunction
}
export const ModalContext = createContext<ContextState>({
  isModalOpen: false,
  openModal: () => null,
  closeModal: () => null,
})

type LocalState = {
  isModalOpen: boolean
  modalContent?: React.ReactNode
}
const INITIAL_LOCAL_STATE: LocalState = {
  isModalOpen: false,
}
type Actions =
  | { type: 'OPEN_MODAL'; payload: React.ReactNode }
  | { type: 'CLOSE_MODAL' }
const reducer = (state: LocalState, action: Actions) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        isModalOpen: true,
        modalContent: action.payload,
      }
    case 'CLOSE_MODAL':
      return {
        isModalOpen: false,
      }
    default:
      return state
  }
}

export const ModalProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_LOCAL_STATE)
  const openModal = (modalContent: React.ReactNode) => {
    dispatch({ type: 'OPEN_MODAL', payload: modalContent })
  }
  const closeModal = () => {
    dispatch({ type: 'CLOSE_MODAL' })
  }
  return (
    <ModalContext.Provider
      value={{ isModalOpen: state.isModalOpen, openModal, closeModal }}
    >
      <Modal isOpen={state.isModalOpen} outsideClick={closeModal}>
        {state.modalContent}
      </Modal>
      {children}
    </ModalContext.Provider>
  )
}
