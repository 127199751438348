import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { DeliverectItemImportModal } from 'components/DeliverectItemImportModal'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { useModal } from 'providers/modal/hooks/useModal'
import { useListenForEvent } from '../hooks/useListenForEvent'
import {
  Data,
  getPendingDeliverectImportsQuery,
  Vars,
} from 'components/DeliverectItemImportModal/queries'

export const useListenForDeliverectImports = () => {
  const { locationId } = useCurrentUser()
  const [fetchItemImports] = useLazyQuery<Data, Vars>(
    getPendingDeliverectImportsQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const items = data?.location?.pendingDeliverectImports ?? []
        if (items.length > 0) {
          openModal()
        }
      },
    }
  )
  useListenForEvent('ITEMS_IMPORTED', 'global.openModal', (event) => {
    if (event.payload.source !== 'DELIVERECT') {
      return
    }
    fetchItemImports({ variables: { locationId } })
  })
  const { openModal } = useModal({
    modalContent: <DeliverectItemImportModal />,
  })
  useEffect(() => {
    fetchItemImports({ variables: { locationId } })
  }, [locationId, fetchItemImports])
}
