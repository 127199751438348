import React, { FC } from 'react'
import Loading from 'components/Loading'
import useGetProfiles from 'components/LocationUserManagement/hooks/useGetProfiles'
import useSelectProfile from 'components/LocationUserManagement/hooks/useSelectProfile'
import { stringToHexColor } from 'utils/numberUtils'
import {
  ProfileInitials,
  ProfileSelectFullName,
  ProfileSelectHeader,
  SelectProfileAdd,
  SelectProfileHeader,
  SelectProfileLogo,
  SelectProfiles,
  SelectProfileWrapper,
} from 'components/LocationUserManagement/Profile/SelectProfile.styles'
import { Span } from 'design-system/Typography/Span'
import { LayoutGrid } from 'design-system/LayoutGrid'
import { ColumnLayout } from 'design-system/ColumnLayout/ColumnLayout'
import { useIntl } from 'react-intl'
import { H1 } from 'design-system/Typography/H1'
import { useModal } from 'providers/modal/hooks/useModal'
import { CreateProfileModal } from 'components/LocationUserManagement/SharedAccounts/CreateProfileModal'
import { Button } from 'design-system/Button'
import useUserManagement from 'components/LocationUserManagement/hooks/useUserManagement'
import useActiveLocation from 'components/Menu/hooks/useActiveLocation'
import { BaseCard } from 'design-system/BaseCard/BaseCard'
import { getInitials } from 'utils/stringUtils'
import { LargeDisplay } from 'design-system/Typography/LargeDisplay'

const SelectProfile: FC = ({ children }) => {
  const { formatMessage } = useIntl()
  const { sharedAccount, loading: loadingUsers } = useUserManagement()
  const {
    canChangeProfile,
    profiles,
    loading: loadingProfiles,
    error,
  } = useGetProfiles({
    userId: sharedAccount?.id,
  })
  const {
    selectedProfileId,
    setSelectedProfile,
    loading: loadingLocalStorage,
  } = useSelectProfile()
  const { openModal: openCreateProfileModal } = useModal({
    modalContent: <CreateProfileModal />,
  })
  const {
    location: activeLocation,
    loading: loadingLocation,
  } = useActiveLocation()
  const loading = loadingUsers || loadingProfiles || loadingLocation
  const storeLogo = activeLocation?.logo_image_url
  if (loading || loadingLocalStorage) {
    return <Loading />
  }
  if (error) {
    throw error
  }
  if (!canChangeProfile || selectedProfileId) {
    return <>{children}</>
  }
  return (
    <LayoutGrid>
      <ColumnLayout>
        <SelectProfileHeader>
          {storeLogo && (
            <SelectProfileLogo>
              <img src={storeLogo} alt="Store logo" />
            </SelectProfileLogo>
          )}
          <H1>{formatMessage({ id: 'usermanagement.profile.select' })}</H1>
        </SelectProfileHeader>
        <SelectProfiles>
          {profiles?.map((profile) => {
            const selectProfile = () => setSelectedProfile(profile.id)
            const randomColor = stringToHexColor(`${profile.name}`)
            return (
              <SelectProfileWrapper>
                <BaseCard
                  onClick={selectProfile}
                  key={`select-profile-${profile.id}`}
                  header={
                    <ProfileSelectHeader color={randomColor}>
                      <ProfileInitials>
                        <LargeDisplay customColor={randomColor}>
                          {getInitials(profile.name)
                            .substr(0, 3)
                            .replace(' ', '')}
                        </LargeDisplay>
                      </ProfileInitials>
                    </ProfileSelectHeader>
                  }
                  body={
                    <ProfileSelectFullName>
                      <Span fontWeight={600} textAlign="center" variant="large">
                        {profile.name}
                      </Span>
                    </ProfileSelectFullName>
                  }
                />
              </SelectProfileWrapper>
            )
          })}
        </SelectProfiles>
        <SelectProfileAdd>
          <Button onClick={openCreateProfileModal}>
            <Span color="white">
              {formatMessage({ id: 'usermanagement.profile.select.add' })}
            </Span>
          </Button>
        </SelectProfileAdd>
      </ColumnLayout>
    </LayoutGrid>
  )
}

export { SelectProfile }
