import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
  metaContent?: string
}

const KarmaHead: FC<Props> = ({ title, metaContent }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${title} | ${process.env.REACT_APP_WEBSITE_NAME}`}</title>
      <meta name="description" content={metaContent ?? title} />
    </Helmet>
  )
}

export { KarmaHead }
