import * as t from 'io-ts'

export const TokenContent = t.type({
  exp: t.number,
  user_id: t.number,
  iat: t.number,
})

export type TLocalState = {
  loading: boolean
  user?: { id: string }
}
