import useCurrentUser from './useCurrentUser'

export const useFeatureFlag = (flag: string) => {
  const { user } = useCurrentUser()
  const beta = [
    ...(user.location?.betaFlags ? user.location?.betaFlags : []),
    {
      key: 'fpi',
      value: user.location?.hasFullprice ?? false,
    },
    {
      key: 'autoSale',
      value: user.location?.hasAutosale ?? false,
    },
    {
      key: 'pod',
      value: user.location?.hasPod ?? false,
    },
    { key: 'short_expiry', value: user.location?.has_short_expiry ?? false },
  ]
  return beta.some(({ key, value }) => key === flag && value)
}
