import React, {
  createContext,
  FC,
  useReducer,
  Dispatch,
  useEffect,
  useContext,
} from 'react'
import localforage from 'localforage'
import uniq from 'lodash/uniq'
import merge from 'lodash/merge'
import { SalePeriod } from '___generated___/globalTypes'
import { DateTime } from 'luxon'
import { ISODate } from '@karmalicious/ktl-iso-date'
interface LocalStorageContext {
  state: State
  dispatch: Dispatch<Action>
}

export type DismissibleKey = keyof State['dismissibles']
export type DateDismissibleKey = keyof State['dateDismissables']
type DateDismissable = {
  [locationId: string]: {
    [date: string]: true
  }
}

export type TabKey = keyof State['tabs']
export type UserCompletedTutorial = {
  userId: string
  completedTutorials: string[]
}
export type SelectedProfile = {
  id: string
  expiresAt: string
  selectedAt: string
}
type State = {
  hasInitialized: boolean
  dismissibles: {
    fullPriceInfoBanner: boolean
    reportsMoved: boolean
    invalidPayoutAccount: boolean
  }
  dateDismissables: {
    loyaltyDiscount: DateDismissable
  }
  tutorials: {
    [key: string]: UserCompletedTutorial
  }
  tabs: {
    shortPeriod: SalePeriod | null
  }
  selectedProfileByLocation?: {
    [key: string]: SelectedProfile
  }
}
type Action =
  | { type: 'LOAD_STATE'; payload: State }
  | {
      type: 'SET_DISMISSIBLE'
      payload: { key: DismissibleKey; isDismissed: boolean }
    }
  | {
      type: 'DISMISS_FOR_DATE'
      payload: { key: DateDismissibleKey; locationId: string; date: ISODate }
    }
  | {
      type: 'ADD_COMPLETED_TUTORIAL'
      payload: { userId: string; tutorialId: string }
    }
  | {
      type: 'SET_TAB'
      payload: { tab: TabKey; value: SalePeriod | null }
    }
  | {
      type: 'SELECT_PROFILE'
      payload: { profileId: string; locationId: string }
    }
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'LOAD_STATE':
      return {
        ...action.payload,
        hasInitialized: true,
      }
    case 'SET_DISMISSIBLE':
      const newState = {
        ...state,
        dismissibles: {
          ...state.dismissibles,
          [action.payload.key]: action.payload.isDismissed,
        },
      }
      localforage.setItem('karma-storage', newState)
      return newState
    case 'DISMISS_FOR_DATE': {
      const { date, locationId, key } = action.payload
      const newState = merge({}, state, {
        dateDismissables: {
          [key]: {
            [locationId]: {
              [date]: true,
            },
          },
        },
      })
      localforage.setItem('karma-storage', newState)
      return newState
    }
    case 'ADD_COMPLETED_TUTORIAL': {
      const { userId, tutorialId } = action.payload
      const previouslyCompletedTutorials =
        state.tutorials[userId]?.completedTutorials || []
      const newState = {
        ...state,
        tutorials: {
          ...state.tutorials,
          [userId]: {
            userId,
            completedTutorials: uniq([
              ...previouslyCompletedTutorials,
              tutorialId,
            ]),
          },
        },
      }
      localforage.setItem('karma-storage', newState)
      return newState
    }
    case 'SET_TAB': {
      const { tab, value } = action.payload
      const newState = {
        ...state,
        tabs: {
          ...state.tabs,
          [tab]: value,
        },
      }
      localforage.setItem('karma-storage', newState)
      return newState
    }
    case 'SELECT_PROFILE': {
      const { locationId, profileId } = action.payload
      const selectedAt = DateTime.utc().toISO()
      const expiresAt = DateTime.utc().plus({ minutes: 15 }).toISO()
      const newState = {
        ...state,
        selectedProfileByLocation: {
          ...state.selectedProfileByLocation,
          lastKnownProfileId: {
            id: profileId,
            selectedAt,
            expiresAt,
          },
          [locationId]: {
            id: profileId,
            selectedAt,
            expiresAt,
          },
        },
      }
      localforage.setItem('karma-storage', newState)
      return newState
    }
    default:
      return state
  }
}
const initialState: State = {
  hasInitialized: false,
  dismissibles: {
    fullPriceInfoBanner: false,
    reportsMoved: false,
    invalidPayoutAccount: false,
  },
  dateDismissables: {
    loyaltyDiscount: {},
  },
  tutorials: {},
  tabs: {
    shortPeriod: null,
  },
  selectedProfileByLocation: {
    lastKnownProfileId: {
      expiresAt: '',
      selectedAt: '',
      id: '',
    },
  },
}
const initialContextState: LocalStorageContext = {
  state: initialState,
  dispatch: () => {},
}
const Context = createContext(initialContextState)
export const LocalStorageProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    const updateFromLocalStorage = async () => {
      const state: State | null = await localforage.getItem('karma-storage')
      dispatch({
        type: 'LOAD_STATE',
        payload: { ...initialState, ...state } ?? initialState,
      })
    }
    updateFromLocalStorage()
  }, [])
  const localStorageState = {
    state,
    dispatch,
  }
  return (
    <Context.Provider value={localStorageState}>{children}</Context.Provider>
  )
}

export const useLocalStorageContext = () => {
  return useContext(Context)
}
