import React from 'react'
import UserState from './index'
import { EnhancedUser } from './types'
import Loading from 'components/Loading'

type ContextState = { user: EnhancedUser }
export const Context = React.createContext<ContextState>({
  user: (null as any) as EnhancedUser,
})

const CurrentUserProvider: React.FC = ({ children }) => {
  return (
    <UserState
      render={({ user }) => {
        if (!user) {
          return <Loading />
        }
        return (
          <Context.Provider
            value={{
              user,
            }}
          >
            {children}
          </Context.Provider>
        )
      }}
    />
  )
}

export { CurrentUserProvider, Context as CurrentUserContext }
