import styled from 'styled-components/macro'
import { NewColor } from 'design-system/theme/newColors'

export const FoodBadges = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.v4.margins.content.medium};
  z-index: 5;
  flex-shrink: 0;
  overflow-x: auto;
  width: 100%;
  & ::-webkit-scrollbar {
    display: none;
  }
`

export const FoodBadgeWrapper = styled.div<{
  backgroundColor?: NewColor
}>`
  display: flex;
  height: 26px;
  min-width: 36px;
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  justify-content: center;
  align-items: center;
  padding: 0 ${({ theme }) => theme.v4.margins.content.small};
  background: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.v3.colors[backgroundColor] : theme.v3.colors.white};
  white-space: nowrap;
  flex-shrink: 0;
  & + & {
    margin-left: ${({ theme }) => theme.v3.margins.content.spacing.small};
  }
`
