import React from 'react'
import { NewColor } from 'design-system/theme/newColors'
import { Header, HeaderProps } from './Header'

interface Props extends Omit<HeaderProps, 'size'> {
  color?: NewColor
  className?: string
  id?: string
}

const H4: React.FC<Props> = ({
  children,
  className,
  color = 'black',
  id,
  ...props
}) => {
  return (
    <Header size="h4" color={color} id={id} className={className} {...props}>
      {children}
    </Header>
  )
}

export { H4 }
