import { gql } from '@apollo/client'

export const TRANSACTION_DATA_FRAGMENT = gql`
  fragment TransactionData on Transaction {
    picked_up
    picked_up_date
    pickup_date
    date_string
    hasDelivery
    image_url
    meta_data {
      cart_id
      timezone
      location_name
      latest_pickup_time
    }
    items_exploded {
      count
      price
      title
      weight
      item_id
      sale_id
      vat_value
      vat_amount
      base_price
      total_price
      discount_percentage
      expiry_date
    }
    currency_code
    currency_is_prefix
    vat_amount
    vat_breakdown {
      vat
      sum
    }
    amount_before_vat
    amount
    state
    name
    rating {
      score
      motivation
    }
  }
`
export const GET_TRANSACTIONS = gql`
  query getLocationTransactions(
    $locationId: ID!
    $date: String!
    $limit: Int
    $offset: Int
  ) {
    location(locationId: $locationId) {
      id
      # This supports mocking with
      # "transactions(date: $date, limit: $limit, offset: $offset) @client {"
      transactions(date: $date, limit: $limit, offset: $offset) {
        id
        ...TransactionData
      }
    }
  }
  ${TRANSACTION_DATA_FRAGMENT}
`
