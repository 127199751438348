import styled, { createGlobalStyle } from 'styled-components/macro'

export const GlobalBackdropStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.v3.colors.turtle};
  }
`
export const SlideShowOverlayWrapper = styled.div`
  z-index: 10000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.v3.colors.turtle};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SlideShowOverlayContainer = styled.div`
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.v3.colors.white};
  background-color: ${({ theme }) => theme.v3.colors.turtle};
  display: grid;
  text-align: center;
  grid-auto-rows: minmax(10px, max-content);
  grid-template-columns: auto;
  grid-template-rows: fit-content(150px) auto 110px;
  grid-template-areas:
    'title'
    'main'
    'footer';
  ${({ theme }) => theme.v3.breakpoints.medium.mediaQuery} {
    width: 400px;
    height: 100%;
    max-height: 900px;
    border-radius: 20px;
    padding: 30px 30px 0;
    border: 2px solid #fff;
  }
`

export const SlideShowTitle = styled.div`
  grid-area: title;
  display: flex;

  justify-content: center;
  padding: 20px 0;
`
export const SlideShowMain = styled.div`
  padding-bottom: 10px;
  grid-area: main;
  overflow-y: auto;
`

export const SlideShowFooter = styled.div`
  grid-area: footer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
`

export const ButtonWrapper = styled.div`
  width: 180px;
  margin-bottom: 10px;
`
