import React from 'react'
import { Container, Icon } from './styles'
import warning from '../../static/images/warning.svg'
import { Span } from '../../design-system/Typography/Span'

export const WarningText = ({ text }) => (
  <Container>
    <Icon src={warning} alt="" />
    <Span>{text}</Span>
  </Container>
)
