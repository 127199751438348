import React, { useReducer, createContext } from 'react'
import { createReducer, initialState } from './reducer'
import { NotificationState, Actions } from './types'

const reducer = createReducer({ timestampFunction: Date.now })

export const NotificationContext = createContext([
  {} as NotificationState,
  null,
] as [NotificationState, React.Dispatch<Actions> | null])

export const NotificationProvider: React.FunctionComponent = ({ children }) => {
  const contextValue = useReducer(reducer, initialState)
  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
}
