import styled from 'styled-components/macro'

export const TertiaryButton = styled.button`
  background-color: rgba(0, 0, 0, 0);
  border: none;
  text-decoration: underline;
  text-align: center;
  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
  :active {
    outline: none;
    opacity: 0.6;
  }
  :focus {
    outline: none;
  }
`
