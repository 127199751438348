import { NotificationState, Actions } from './types'
import omit from 'lodash/omit'

export const initialState: NotificationState = {
  openNotifications: {},
}
export const createReducer = (dependencies: {
  timestampFunction: () => number
}) => {
  const { timestampFunction } = dependencies
  const reducer = (
    state: NotificationState,
    action: Actions
  ): NotificationState => {
    switch (action.type) {
      case 'SHOW_NOTIFICATION':
        return {
          openNotifications: {
            ...state.openNotifications,
            [action.payload.id]: {
              ...action.payload,
              createdAt: timestampFunction(),
            },
          },
        }
      case 'HIDE_NOTIFICATION':
        const openNotifications = omit(state.openNotifications, [
          action.payload.id,
        ])
        return {
          openNotifications,
        }
    }
  }
  return reducer
}
