import { useReducer } from 'react'

export type SuccessTarget = 'IMPORT' | 'REJECT_ALL'

type ReducerState =
  | {
      state: 'NOT_SENT'
    }
  | {
      state: 'SENDING_REQUEST'
    }
  | {
      state: 'SUCCESS'
      target: SuccessTarget
    }
  | {
      state: 'FAILED'
    }

type Action = 'SEND_REQUEST' | 'SUCCEED_IMPORT' | 'FAIL' | 'SUCCEED_REJECT'

const reducer = (state: ReducerState, action: Action): ReducerState => {
  if (action === 'SEND_REQUEST' && state.state === 'NOT_SENT') {
    return { state: 'SENDING_REQUEST' }
  }
  if (action === 'SUCCEED_IMPORT' && state.state === 'SENDING_REQUEST') {
    return { state: 'SUCCESS', target: 'IMPORT' }
  }
  if (action === 'SUCCEED_REJECT' && state.state === 'SENDING_REQUEST') {
    return { state: 'SUCCESS', target: 'REJECT_ALL' }
  }
  if (action === 'FAIL' && state.state === 'SENDING_REQUEST') {
    return { state: 'FAILED' }
  }
  return state
}
const initialState: ReducerState = { state: 'NOT_SENT' }

export const useDeliverectImportReducer = () => {
  return useReducer(reducer, initialState)
}
