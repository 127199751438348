import React, { FC, useRef } from 'react'
import { useIntl } from 'react-intl'
import { SidebarDivider, SidebarList, SidebarListContainer } from './styles'
import { ActiveLocation } from 'components/Menu/ActiveLocation'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import {
  faChartArea,
  faCog,
  faConciergeBell,
  faStore,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as paths from 'routes/paths'
import { SidebarItem } from 'components/Menu/SidebarItem'
import { SelectProfileSideMenu } from 'components/Menu/SelectProfileSideMenu'
import useGetProfiles from 'components/LocationUserManagement/hooks/useGetProfiles'
import { SettingsList } from 'pages/Settings/SettingsMenu'
import { useIsDesktopScreen } from 'design-system/theme/breakpoints'
import LocationPicker from 'components/Menu/LocationPicker'
import useActivePayoutAccounts from 'components/Payout/hooks/useActivePayoutAccounts'
import { useTutorial } from 'providers/tutorial/hooks/useTutorial'
import { ALL_TUTORIALS } from 'providers/tutorial/TUTORIALS'
import { useInventoryQuery } from 'pages/Sale/hooks/useInventoryQuery'
import { useHasCompletedTutorial } from 'providers/tutorial/hooks/useHasCompletedTutorial'
import { DateTime } from 'luxon'

export const ICON_STYLE = { width: 20, height: 20, padding: 0 }
const ONE_MONTH_IN_DAYS = 30
const MainMenu: FC = () => {
  const { user } = useCurrentUser()
  const intl = useIntl()
  const { canChangeProfile } = useGetProfiles({
    userId: user.id,
  })
  const startSaleProRef = useRef(null)
  const isDesktopScreen = useIsDesktopScreen()
  const { hasInvalidPayoutStatus } = useActivePayoutAccounts()

  const inventory = useInventoryQuery()

  const hasCompletedPreviousTutorials = useHasCompletedTutorial(
    ALL_TUTORIALS.FIRST_TIME_CREATE_ITEM_START.id
  )
  const hasCompletedNextTutorial = useHasCompletedTutorial(
    ALL_TUTORIALS.FIRST_TIME_START_SALE_GO_LIVE.id
  )
  const isNewLocation =
    DateTime.fromISO(user.location?.created_date ?? '1970').diffNow('days')
      .days > -ONE_MONTH_IN_DAYS

  useTutorial({
    tutorial: {
      ref: startSaleProRef,
      id: ALL_TUTORIALS.FIRST_TIME_START_SALE_NAVIGATE.id,
      title: intl.formatMessage({
        id: `tutorial.${ALL_TUTORIALS.FIRST_TIME_START_SALE_NAVIGATE.id}.title`,
      }),
      paragraph: intl.formatMessage({
        id: `tutorial.${ALL_TUTORIALS.FIRST_TIME_START_SALE_NAVIGATE.id}.paragraph`,
      }),
      positiveActionText: intl.formatMessage({ id: 'common.got-it' }),
    },
    requirements:
      inventory.itemTotals > 0 &&
      hasCompletedPreviousTutorials &&
      !hasCompletedNextTutorial &&
      isNewLocation,
  })

  return (
    <SidebarList>
      <SidebarListContainer>
        <ActiveLocation />
        {canChangeProfile ? <SelectProfileSideMenu /> : <LocationPicker />}
        {isDesktopScreen ? (
          <>
            <SidebarItem
              toPath={paths.SALE.template()}
              icon={<FontAwesomeIcon icon={faStore} style={ICON_STYLE} />}
              title={intl.formatMessage({ id: 'navbar.sale' })}
              forwardRef={startSaleProRef}
            />
            <SidebarItem
              toPath={paths.ORDERS}
              icon={
                <FontAwesomeIcon icon={faConciergeBell} style={ICON_STYLE} />
              }
              title={intl.formatMessage({ id: 'transactions.title' })}
            />
            <SidebarItem
              toPath={paths.STATISTICS}
              icon={<FontAwesomeIcon icon={faChartArea} style={ICON_STYLE} />}
              title={intl.formatMessage({ id: 'stats.title' })}
            />
            <SidebarDivider maximize />
            <SidebarItem
              toPath={paths.SETTINGS}
              icon={<FontAwesomeIcon icon={faCog} style={ICON_STYLE} />}
              title={intl.formatMessage({ id: 'settings.title' })}
              badgeText={
                hasInvalidPayoutStatus
                  ? intl.formatMessage({ id: 'errors.invalid' })
                  : null
              }
              badgeColor="plum"
            />
          </>
        ) : (
          <>
            <SettingsList />
            <SidebarDivider maximize />
          </>
        )}
      </SidebarListContainer>
    </SidebarList>
  )
}

export { MainMenu }
