import React, { FC } from 'react'
import { CustomerCommunication } from 'design-system/CustomerCommunication'
import useActivePayoutAccounts from 'components/Payout/hooks/useActivePayoutAccounts'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { roles as UserRoles } from 'utils/userRole'
import { TextButton } from 'design-system/Button/TextButton'
import { Paragraph } from 'design-system/Typography/Paragraph'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { PAYOUT_DETAILS, PAYOUT_SELECTION } from 'routes/paths'
import { InvalidPayoutCommsContainer } from './InvalidPayoutBanner.styles'
import { Span } from 'design-system/Typography/Span'
import { useLocalStorageContext } from 'providers/LocalStorageProvider'

interface Props {}

const InvalidPayoutBanner: FC<Props> = () => {
  const { account, loading, totalBalance } = useActivePayoutAccounts()
  const { user, currency } = useCurrentUser()
  const { formatMessage, formatNumber } = useIntl()
  const history = useHistory()
  const { dispatch } = useLocalStorageContext()

  if (loading) {
    return null
  }
  if (account?.status?.payoutsEnabled) {
    // Don't show a banner if you have a valid payout account
    return null
  }
  if (totalBalance === 0) {
    return null
  }
  const dismiss = () => {
    dispatch({
      type: 'SET_DISMISSIBLE',
      payload: { key: 'invalidPayoutAccount', isDismissed: true },
    })
  }
  const handleGoToPayment = () => {
    dismiss()
    if (account?.id) {
      history.push(PAYOUT_DETAILS.create({ account: account.id }))
    } else {
      history.push(PAYOUT_SELECTION)
    }
  }
  const role = user.role === UserRoles.STAFF_MEMBER ? 'staff' : 'manager'
  return (
    <CustomerCommunication
      kind="One-Off"
      communicationKey="invalidPayoutAccount"
      dismissText={formatMessage({
        id: 'common.got-it',
      })}
      content={
        <InvalidPayoutCommsContainer>
          <Paragraph variant="large" weight={600}>
            {formatMessage(
              { id: `communications.invalidPayoutAccount.${role}.body` },
              {
                amount: formatNumber(totalBalance, {
                  style: 'currency',
                  currency: currency.code,
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                }),
              }
            )}
          </Paragraph>
          {role === 'manager' && (
            <TextButton onClick={handleGoToPayment}>
              <Span color="darkGrey" variant="large" textDecoration="underline">
                {formatMessage({
                  id: `communications.invalidPayoutAccount.${role}.clickHere`,
                })}
              </Span>
            </TextButton>
          )}
        </InvalidPayoutCommsContainer>
      }
    />
  )
}

export { InvalidPayoutBanner }
