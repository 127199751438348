import { gql } from '@apollo/client'

export const GET_TUTORIAL_PROGRESS = gql`
  query getTutorialProgress {
    current {
      user {
        tutorialProgress {
          key
          completed
        }
      }
    }
  }
`

export const SET_TUTORIAL_COMPLETED = gql`
  mutation setTutorialProgress($key: String!) {
    setTutorialProgress(key: $key, completed: true) {
      success
    }
  }
`
