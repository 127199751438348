import { EnhancedUser } from './../types'
import { useContext } from 'react'
import { CurrentUserContext } from '../CurrentUserProvider'

const DEFAULT_CURRENCY = {
  __typename: 'Currency' as 'Currency',
  code: 'SEK',
  is_prefix: false,
  symbol: 'kr',
}

interface Props {
  user: EnhancedUser
  locationId: string
  currency: typeof DEFAULT_CURRENCY
}

const useCurrentUser = (): Props => {
  const { user } = useContext(CurrentUserContext)
  if (!user) {
    throw Error(
      'No user found. Make sure you are using useCurrentUser after a user provider'
    )
  }
  const currency = {
    __typename: DEFAULT_CURRENCY.__typename,
    is_prefix: user.location?.currency?.is_prefix || DEFAULT_CURRENCY.is_prefix,
    symbol: user.location?.currency?.symbol || DEFAULT_CURRENCY.symbol,
    code: user.location?.currency?.code || DEFAULT_CURRENCY.code,
  }
  return {
    user,
    locationId: user.activeLocation,
    currency,
  }
}

export default useCurrentUser
