import React, { FC } from 'react'

const getTransform = ({
  orientation,
  width,
  height,
}: {
  orientation: Orientation
  width: number
  height: number
}) => {
  const centerX = width / 2
  const centerY = height / 2
  if (orientation === 'right') return `rotate(-90, ${centerX}, ${centerY})`
  if (orientation === 'left') return `rotate(90, ${centerX}, ${centerY})`
  if (orientation === 'up') return `rotate(180, ${centerX}, ${centerY})`
  if (orientation === 'down') return `rotate(0, ${centerX}, ${centerY}) `
}

type Orientation = 'right' | 'left' | 'up' | 'down'

interface Props {
  stroke?: string
  orientation?: Orientation
  scale?: number
}

const Chevron: FC<Props> = ({ stroke, orientation, scale }) => {
  const width = 12 * (scale || 1)
  const height = 12 * (scale || 1)
  const color = stroke || 'currentColor'
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform={getTransform({
          orientation: orientation || 'left',
          width: 12,
          height: 12,
        })}
      >
        <path
          d="M1 4 L6 8 L11 4"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}

export default Chevron
