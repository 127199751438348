import styled from 'styled-components/macro'

export const CompletedAchievementDots = styled.div`
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.large};
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    margin-top: ${({ theme }) => theme.v3.margins.content.spacing.extraLarge};
  }
`

export const CompletedAchievementDot = styled.div<{ isActive: boolean }>`
  padding: 4px;
  border-radius: 50%;
  margin: 0 2px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.v3.colors.grey : theme.v3.colors.lighterGrey};
`

export const CompletedAchievementActions = styled.div`
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.medium};
  display: flex;
  justify-content: center;
  & > :nth-child(n) {
    max-width: 150px;
  }
`
