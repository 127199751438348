import { Locale } from 'providers/IntlProviderWrapper/types'
import { useIntl } from 'react-intl'

export const useLocale = (): Locale => {
  const { locale } = useIntl()
  if (locale === 'sv') {
    return 'sv'
  }
  if (locale === 'fr') {
    return 'fr'
  }
  return 'en'
}
