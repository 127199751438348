import styled from 'styled-components/macro'

export const SpacingHeightLarge = styled.div`
  height: 30px;
`

export const SpacingHeightMedium = styled.div`
  height: 20px;
`
export const SpacingHeightSmall = styled.div`
  height: 10px;
`

export const SpacingHeightXSmall = styled.div`
  height: 5px;
`
