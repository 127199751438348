import React from 'react'

interface Props {
  size?: number
}

const Search = ({ size = 20 }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0 0H20V20H0V0Z"
      fill="currentColor"
      fillOpacity="0.01"
    />
    <path
      d="M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
      transform="translate(1 1)"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M0 0L5 5"
      transform="translate(12 12)"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)

export default Search
