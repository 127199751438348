import React, { FC, useContext } from 'react'
import { Route, RouteProps } from 'react-router'
import { AuthTokenContext } from 'providers/AuthTokenProvider'
import { RedirectHome, RedirectLogin } from './redirects'
import { AuthenticatedProviders } from 'providers/AuthenticatedProviders'
import { isUserAuthenticated } from './auth'
import { arrayHasElementsIn } from 'utils/arrayUtils'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'

export type RouteRestriction =
  | 'authenticated'
  | 'unauthenticated'
  | 'pro'
  | 'basic'
  | 'non-shared-account'
interface Props extends Omit<RouteProps, 'render'> {
  restrict: RouteRestriction[]
}
export const RestrictedRoute: FC<Props> = ({
  restrict,
  children,
  component,
  ...rest
}) => {
  const { token } = useContext(AuthTokenContext)
  return (
    <Route
      {...rest}
      render={() => {
        const isAuthenticated = token ? isUserAuthenticated(token) : false
        // @ts-ignore
        const toRender = component ? React.createElement(component) : children
        const isAuthRoute =
          arrayHasElementsIn(restrict, ['authenticated']) &&
          restrict.length === 1

        const authenticatedOnlyRequirementNotMet =
          isAuthRoute && !isAuthenticated
        if (authenticatedOnlyRequirementNotMet) {
          return <RedirectLogin />
        }

        const locationRequirementExist = arrayHasElementsIn(restrict, [
          'pro',
          'basic',
        ])

        // We have location requirement but cannot verify it since we are not logged in
        if (locationRequirementExist && !isAuthenticated) {
          return <RedirectLogin />
        }

        //Only unauthorized route
        const isUnAuthRoute =
          arrayHasElementsIn(restrict, ['unauthenticated']) &&
          restrict.length === 1
        if (isUnAuthRoute) {
          if (isAuthenticated) {
            return (
              <AuthenticatedProviders>
                <RedirectHome />
              </AuthenticatedProviders>
            )
          } else {
            return toRender
          }
        }

        // Route depending on whether pro or basic location
        return (
          <AuthenticatedProviders>
            <ProtectedAuthRoute restrict={restrict} toRender={toRender} />
          </AuthenticatedProviders>
        )
      }}
    />
  )
}

interface ProtectedAuthRouteProps {
  toRender: any
  restrict: RouteRestriction[]
}
const ProtectedAuthRoute: React.FC<ProtectedAuthRouteProps> = ({
  restrict,
  toRender,
}) => {
  const { user } = useCurrentUser()
  // Restrict for shared accounts
  const isSharedAccountRestricted = arrayHasElementsIn(restrict, [
    'non-shared-account',
  ])
  if (isSharedAccountRestricted && user.isSharedAccount) {
    return <RedirectHome />
  }

  const proLocation = !user?.location?.karmaBasic
  const proOnlyRoute = arrayHasElementsIn(restrict, ['pro'])
  const basicOnlyRoute = arrayHasElementsIn(restrict, ['basic'])

  const shouldRedirectHome =
    (proOnlyRoute && !proLocation) || // basic location tries to access pro route
    (basicOnlyRoute && proLocation) // pro location tries to access basic route
  if (shouldRedirectHome) {
    return <RedirectHome />
  }

  return toRender
}
