import { ISODate } from '@karmalicious/ktl-iso-date'
import { randomString } from 'apollo/mocks/mockUtils'
import { DateTime, Interval } from 'luxon'
import { randomIntFromInterval } from 'utils/numberUtils'
import { MetricUnit } from '___generated___/globalTypes'
import { statsGraphCurrent_statisticsPrevious } from '___generated___/statsGraphCurrent'
import { statsPreview_statisticsPrevious } from '___generated___/statsPreview'

const METRIC = ({
  from,
  to,
  min = 0,
  max = 5000,
  unit = MetricUnit.COUNT,
}: {
  from: ISODate
  to: ISODate
  min?: number
  max?: number
  unit?: MetricUnit
}) => {
  const dates = Interval.fromDateTimes(
    DateTime.fromISO(from).startOf('day'),
    DateTime.fromISO(to).endOf('day')
  )
    .splitBy({ days: 1 })
    .map((d) => d.start)
  return {
    __typename: 'Metric' as 'Metric',
    unit,
    avg: Math.random() * 10,
    sum: Math.random() * 10,
    count: Math.random() * 10,
    values: dates.map((date) => {
      return {
        __typename: 'TimeSeriesData' as 'TimeSeriesData',
        date: date.toISODate() as ISODate,
        value: randomIntFromInterval(min, max),
      }
    }),
  }
}

const STATISTIC = ({
  from,
  to,
}: {
  from: ISODate
  to: ISODate
  lId?: string
}) => {
  return {
    __typename: 'Statistics' as 'Statistics',
    gmv: METRIC({ from, to }),
    soldCount: METRIC({ from, to, min: 0, max: 10 }),
    addedCount: METRIC({ from, to, min: 0, max: 20 }),
    ratingAverages: METRIC({ from, to, min: 1, max: 5 }),
    ratingCounts: METRIC({ from, to, min: 0, max: 25 }),
    sellThroughRate: METRIC({ from, to, min: 10, max: 90 }),
    newFollowers: METRIC({ from, to, min: 10, max: 300 }),
    co2Saved: METRIC({ from, to, min: 0, max: 10, unit: MetricUnit.GRAM_CO2 }),
    ordersCount: METRIC({ from, to, min: 0, max: 10 }),
    pushesSent: METRIC({ from, to, min: 0, max: 10 }),
    newCustomers: METRIC({ from, to, min: 0, max: 10 }),
    foodSaved: METRIC({
      from,
      to,
      min: 0,
      max: 10,
      unit: MetricUnit.GRAM_FOOD,
    }),
    saleDays: METRIC({ from, to, min: 0, max: 10 }),
    saleHours: METRIC({ from, to, min: 0, max: 10 }),
  }
}

const STATISTICS = ({
  from,
  to,
  locationIds,
}: {
  from: ISODate
  to: ISODate
  locationIds: string[]
}): statsPreview_statisticsPrevious & statsGraphCurrent_statisticsPrevious => {
  return {
    __typename: 'StatisticsQuery',
    aggregatedLocations: STATISTIC({
      from,
      to,
    }),
    locations: locationIds.map((lId) => {
      return {
        __typename: 'LocationStatistics',
        locationId: lId,
        locationName: randomString(),
        statistics: STATISTIC({ from, to }),
      }
    }),
  }
}

export default STATISTICS
