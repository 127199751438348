import React, { FC, MouseEventHandler } from 'react'
import { NewColor } from 'design-system/theme/newColors'
import { useTheme } from 'styled-components/macro'
import styled, { DefaultTheme } from 'styled-components/macro'
import { DesignSystemIcon, IconOrientation } from 'design-system/icons/types'
import { Spinner } from 'design-system/Spinner'

type Size = 'extrasmall' | 'small' | 'medium' | 'large'
type ColorScheme =
  | { type: 'gray' }
  | { type: 'white' }
  | { type: 'black' }
  | { type: 'transparent' }
  | {
      type: 'custom'
      bgColor: NewColor
      iconColor?: NewColor
      borderColor?: NewColor
    }
  | { type: 'image'; url: string; borderRadius?: 'small' | 'large' | 'none' }

const getIconColor = (color: ColorScheme): NewColor => {
  if (
    color.type === 'gray' ||
    color.type === 'transparent' ||
    color.type === 'white'
  ) {
    return 'black'
  }
  if (color.type === 'black') {
    return 'white'
  }
  if (color.type === 'custom' && color.iconColor) {
    return color.iconColor
  }
  return 'white'
}

const getBackgroundColor = (color: ColorScheme, theme: DefaultTheme) => {
  const {
    v3: { colors },
  } = theme
  if (color.type === 'gray') {
    return colors.lighterGrey
  }
  if (color.type === 'transparent' || color.type === 'image') {
    return colors.transparent
  }
  if (color.type === 'black') {
    return colors.black
  }
  if (color.type === 'custom') {
    return colors[color.bgColor]
  }
  if (color.type === 'white') {
    return colors.white
  }
  return colors.black
}

const getContainerStyle = (
  color: ColorScheme,
  theme: DefaultTheme
): React.CSSProperties => {
  const {
    v3: { colors, borderRadius },
  } = theme
  if (color.type === 'transparent') {
    return {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: colors.grey,
    }
  }
  if (color.type === 'image') {
    return {
      backgroundImage: `url(${color.url})`,
      backgroundSize: 'cover',
      borderRadius: color.borderRadius
        ? borderRadius[color.borderRadius]
        : '50%',
    }
  }
  if (color.type === 'custom' && color.borderColor) {
    return {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: color.borderColor,
    }
  }
  return {}
}

const getDivSize = (size: Size) => {
  if (size === 'large') {
    return 48
  }
  if (size === 'medium') {
    return 36
  }
  if (size === 'small') {
    return 28
  }
  if (size === 'extrasmall') {
    return 20
  }
  return 28
}

const StyledDiv = styled.div<{ clickable: boolean; displayOnly?: boolean }>`
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  opacity: ${({ clickable, displayOnly }) =>
    clickable || displayOnly ? '1' : '0.7'};
  cursor: ${({ clickable, displayOnly }) =>
    displayOnly ? 'inherit' : clickable ? 'pointer' : 'not-allowed'};
  &:hover {
    filter: ${({ clickable, displayOnly }) =>
      clickable && !displayOnly && 'brightness(95%)'};
  }
  &:active {
    filter: ${({ clickable, displayOnly }) =>
      clickable && !displayOnly && 'brightness(90%)'};
  }
`

interface Props {
  size: Size
  color: ColorScheme
  icon?: DesignSystemIcon
  onClick?: MouseEventHandler
  iconOrientation?: IconOrientation
  disabled?: boolean
  loading?: boolean
  displayOnly?: boolean
  className?: string
  children?: null
}

export const ActionButton: FC<Props> = ({
  icon: Icon,
  size,
  color,
  onClick,
  iconOrientation,
  disabled,
  loading,
  displayOnly,
  className,
}) => {
  const isDisabled = disabled || displayOnly
  const theme: DefaultTheme = useTheme()
  const clickable = Boolean(onClick && !loading && !isDisabled)
  const clickHandler: MouseEventHandler = (e) => {
    if (!clickable) {
      return
    }
    if (!onClick) {
      return
    }
    onClick(e)
  }
  const divSize = getDivSize(size)
  const iconColor = getIconColor(color)
  const iconColorHex = theme.v3.colors[iconColor]
  const backgroundColor = getBackgroundColor(color, theme)
  const containerStyle = getContainerStyle(color, theme)
  const iconSize = divSize / 2
  const divProps: React.CSSProperties = {
    height: divSize,
    width: divSize,
    backgroundColor,
    ...containerStyle,
  }
  return (
    <StyledDiv
      style={divProps}
      clickable={clickable}
      onClick={clickHandler}
      displayOnly={displayOnly}
      className={className}
    >
      {loading ? (
        <Spinner
          loading={true}
          style={{ width: iconSize }}
          color={iconColor}
          type="circle"
        />
      ) : (
        Icon && (
          <Icon
            size={iconSize}
            color={iconColorHex}
            orientation={iconOrientation}
          />
        )
      )}
    </StyledDiv>
  )
}
