import { gql } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { QueryHookResult } from 'types/queryHooks'
import { ReleaseGuideWithContent } from './types'
import { getAllReleaseGuides } from '___generated___/getAllReleaseGuides'

const GET_ALL_RELEASE_GUIDES = gql`
  query getAllReleaseGuides {
    allReleaseGuides {
      id
      createdAt
      read
      bodies {
        sv
        en
        fr
      }
      titles {
        sv
        fr
        en
      }
      slides {
        index
        markdowns {
          sv
          fr
          en
        }
      }
    }
  }
`

export const useGetAllReleaseGuides = (): QueryHookResult<
  ReleaseGuideWithContent[]
> => {
  const { data, error, loading } = useQuery<getAllReleaseGuides>(
    GET_ALL_RELEASE_GUIDES
  )
  if (error) {
    return { state: 'ERROR', error }
  }
  if (loading || !data) {
    return { state: 'LOADING' }
  }
  return { state: 'DATA', data: data.allReleaseGuides }
}
