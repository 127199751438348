import { stringify } from 'query-string'
import { NoExtraProperties, PathPart, PathParam } from './types'
import { isParam } from './param'

/**
 * Extracts the params from path parts:
 * type Parts = ['part1', { param: 'id' }, 'part2', { param: 'otherId' }]
 * type Params = ParamsFromPathParts<Parts>
 *   =>  "id" | "otherId"
 */
export type ParamsFromPathParts<T extends PathPart[]> = {
  [K in keyof T]: T[K] extends PathParam<infer ParamName> ? ParamName : never
}[number]

/**
 * From a list of (string) query params, extracts the strings and uses as keys for
 * an query object:
 * type QueryList = ['search', 'sort']
 * type QueryObject = Queries<QueryList>
 *  => { queries?: Record<'search' | 'sort', string>}
 *
 * If QueryList is empty:
 *  => { queries?: Record<string, never>}
 */
type Queries<Q extends string[]> = Q[number] extends never
  ? { queries?: Record<string, never> }
  : { queries?: Partial<Record<Q[number], string | null>> }

type Params<T extends PathPart[]> = ParamsFromPathParts<T> extends never
  ? NoExtraProperties<Record<ParamsFromPathParts<T>, string>>
  : Record<ParamsFromPathParts<T>, string>

export type RouteCreator = <K extends PathPart[]>(...args: K) => Route<K>

export const route: RouteCreator = (...pathParts: PathPart[]) => {
  return makeRoute(pathParts, [])
}

export interface Route<
  Parts extends PathPart[],
  QueryParams extends string[] = []
> {
  template(): string
  create(args: Params<Parts> & Queries<QueryParams>): string
  withQueryParams: <T extends string[]>(
    ...params: T
  ) => Route<Parts, [QueryParams[number] | T[number]]>
}

function makeRoute<T extends PathPart[], Q extends string[]>(
  pathParts: T,
  queryParams: Q
): Route<T> {
  return {
    template: () => {
      return (
        '/' +
        pathParts
          .map((part) => (isParam(part) ? `:${part.param}` : part))
          .join('/')
      )
    },
    create: (args: any) => {
      const baseUrl =
        '/' +
        pathParts
          .map((part) => {
            if (isParam(part)) {
              const { param } = part
              return args[param]
            }
            return part
          })
          .join('/')

      if (!args.queries || Object.keys(args.queries).length === 0) {
        return baseUrl
      }

      const queryString = stringify(args.queries, {
        skipNull: true,
      })

      return queryString === '' ? baseUrl : `${baseUrl}?${queryString}`
    },

    withQueryParams: <TQueryParams extends string[]>(
      ...params: TQueryParams
    ) => {
      return makeRoute(pathParts, [...params, ...queryParams])
    },
  }
}
