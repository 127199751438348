import { gql } from '@apollo/client'
import {
  getPendingDeliverectImports,
  getPendingDeliverectImportsVariables,
} from '___generated___/getPendingDeliverectImports'
import { handleDeliverectImportVariables } from '___generated___/handleDeliverectImport'

export const getPendingDeliverectImportsQuery = gql`
  query getPendingDeliverectImports($locationId: ID!) {
    location(locationId: $locationId) {
      pendingDeliverectImports {
        id
        title
        price
        description
        imageUrl
        tags
      }
    }
  }
`

export type Data = getPendingDeliverectImports
export type Vars = getPendingDeliverectImportsVariables

export const handleDeliverectImportQuery = gql`
  mutation handleDeliverectImport(
    $locationId: ID!
    $input: HandleDeliverectImportInput!
  ) {
    handleDeliverectMenuImport(locationId: $locationId, input: $input) {
      success
    }
  }
`
export type MutationVars = handleDeliverectImportVariables
