import { client } from 'apollo/client'
import { ThemeProvider } from 'styled-components/macro'
import { IntlProviderWrapper } from './IntlProviderWrapper'
import ErrorBoundaryPage from '../components/ErrorBoundary/Page'
import { AuthTokenProvider } from './AuthTokenProvider'
import {
  NotificationBar,
  NotificationProvider,
} from 'design-system/NotificationBar'
import { Router } from 'react-router-dom'
import Routes from '../routes'
import { ApolloProvider } from '@apollo/client'
import React, { FC } from 'react'
import { createBrowserHistory } from 'history'
import { createTheme } from 'design-system/theme'
import { LocalStorageProvider } from './LocalStorageProvider'
import { ModalProvider } from './modal/ModalProvider'
import { RecoilRoot } from 'recoil'
import { AppStateProvider } from './AppState'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const history = createBrowserHistory()
const enableSentry =
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '127.0.0.1'
const DENY_LIST_URLS = [/\/sso\//]

Sentry.init({
  dsn: enableSentry
    ? 'https://b56e2cdfc78f4064aa8ff4ddd48c3ea4@sentry.io/229135'
    : undefined,
  release: `merchant-web@${process.env.REACT_APP_ENGINE_VERSION || '1.0.0'}`,
  ignoreErrors: ['Network error', 'GraphQL error', 'ChunkLoadError'],
  environment: `${
    process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV
  }`,
  tracesSampleRate: 0.1,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      beforeNavigate(context) {
        const url = context.name
        const isInDenyListUrl = DENY_LIST_URLS.some((denyUrl) =>
          url.match(denyUrl)
        )
        if (isInDenyListUrl) {
          return undefined
        }
        return context
      },
    }),
  ],
})

export const Provider: FC = () => {
  const designSystemTheme = createTheme().theme
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={designSystemTheme}>
        <IntlProviderWrapper>
          <ErrorBoundaryPage>
            <RecoilRoot>
              <AuthTokenProvider>
                <NotificationProvider>
                  <LocalStorageProvider>
                    <Router history={history}>
                      <ModalProvider>
                        <NotificationBar>
                          <AppStateProvider>
                            <Routes />
                          </AppStateProvider>
                        </NotificationBar>
                      </ModalProvider>
                    </Router>
                  </LocalStorageProvider>
                </NotificationProvider>
              </AuthTokenProvider>
            </RecoilRoot>
          </ErrorBoundaryPage>
        </IntlProviderWrapper>
      </ThemeProvider>
    </ApolloProvider>
  )
}
