import React, { useState } from 'react'
import {
  FieldLabels,
  FieldLeading,
  FieldSize,
  FieldTrailing,
  FieldWrapper,
  Message,
  MessageContainer,
  StyledInput,
  StyledInputWrapper,
} from './styles'
import { Span } from 'design-system/Typography/Span'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type FieldType = 'password' | 'text' | 'email' | 'tel' | 'number'
type Ref = HTMLInputElement
export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  fieldSize?: FieldSize
  type?: FieldType
  label?: string
  error?: string | false
  message?: string
  dense?: boolean
  hint?: React.ReactNode
  leading?: React.ReactNode
  trailing?: React.ReactNode
  locked?: boolean
  onEnter?: VoidFunction
}

export const TextField = React.forwardRef<Ref, Props>(
  (
    {
      type = 'text',
      error,
      message,
      dense,
      fieldSize,
      size,
      label,
      hint,
      leading,
      trailing,
      onFocus,
      onBlur,
      locked,
      disabled,
      onEnter,
      ...rest
    },
    ref
  ) => {
    const [hasFocus, setHasFocus] = useState(false)
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setHasFocus(true)
      if (onFocus) {
        onFocus(event)
      }
    }
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setHasFocus(false)
      if (onBlur) {
        onBlur(event)
      }
    }
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && onEnter) {
        onEnter()
      }
    }
    return (
      <FieldWrapper fluid={size === undefined}>
        {(label || hint || error) && (
          <FieldLabels>
            {label && (
              <Span
                color={error ? 'salmon' : 'black'}
                variant="medium"
                fontWeight={600}
              >
                {label}
              </Span>
            )}
            {error ? (
              <Span color="salmon">{error}</Span>
            ) : (
              hint && <Span color="grey">{hint}</Span>
            )}
          </FieldLabels>
        )}
        <StyledInputWrapper locked={locked} hasFocus={hasFocus} size={size}>
          {leading && <FieldLeading>{leading}</FieldLeading>}
          <StyledInput
            onFocus={handleFocus}
            onBlur={handleBlur}
            fieldSize={fieldSize}
            type={type}
            hasLeading={!!leading}
            hasTrailing={!!trailing}
            disabled={disabled || locked}
            ref={ref}
            onKeyDown={onKeyDown}
            {...rest}
          />
          {locked ? (
            <FieldTrailing locked>
              <FontAwesomeIcon icon={faLock} />
            </FieldTrailing>
          ) : (
            trailing && <FieldTrailing locked={false}>{trailing}</FieldTrailing>
          )}
        </StyledInputWrapper>
        <MessageContainer>
          {message ? <Message>{message}</Message> : null}
          {!dense ? <span>&nbsp;</span> : null}
        </MessageContainer>
      </FieldWrapper>
    )
  }
)
