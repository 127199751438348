import styled from 'styled-components/macro'

export const Title = styled.h2`
  margin: 0;
  font-size: 1.2em;
  font-weight: 500;
  font-family: ${({ theme }) => theme.v2.fonts.medium};
`

export const SubTitle = styled.h3`
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  font-family: ${({ theme }) => theme.v2.fonts.default};
  color: ${({ theme }) => theme.v2.colors.grayscale[80]};
`
