import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons'
import { NavBarItem } from 'components/Navbar/NavBarItem'
import React from 'react'
import { ICON_STYLE } from 'components/Navbar/Navs'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { ORDERS } from 'routes/paths'

interface Props {
  navigateTo: (to: string) => void
}

const NavBarOrders: React.FC<Props> = ({ navigateTo }) => {
  const { pathname } = useLocation()
  const { formatMessage } = useIntl()
  return (
    <NavBarItem
      isActive={pathname === ORDERS}
      onClick={() => navigateTo(ORDERS)}
      icon={<FontAwesomeIcon icon={faConciergeBell} style={ICON_STYLE} />}
      label={formatMessage({
        id: 'transactions.title',
      })}
    />
  )
}
export { NavBarOrders }
