import styled from 'styled-components/macro'

export const CompletedUnseenAchievementWrapper = styled.div`
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.large};
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    margin-top: ${({ theme }) => theme.v3.margins.content.spacing.extraLarge};
  }
`

export const CompletedUnseenAchievementDescription = styled.div`
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.small};
`
