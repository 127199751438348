import React, { FC } from 'react'
import styled from 'styled-components/macro'
import ReactMarkdown from 'react-markdown'
import { NewColor } from 'design-system/theme/newColors'

export const MarkdownContainer = styled.div<{ color: NewColor }>`
  & a {
    color: ${({ theme, color }) => theme.v3.colors[color]} !important;
  }
  & p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    margin: 0;
  }
  & img {
    max-width: 80%;
    max-height: 80%;
  }
  & li {
    text-align: left;
  }
  & h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
  }
`
interface Props {
  markdown: string
  color?: NewColor
}
export const Markdown: FC<Props> = ({ markdown, color }) => (
  <MarkdownContainer color={color || 'black'}>
    <ReactMarkdown source={markdown} />
  </MarkdownContainer>
)
