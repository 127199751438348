import { ColorScale } from '..'

export const karma: ColorScale = {
  0: '#FFFFFF',
  10: '#FEEFF3',
  20: '#FDDFE7',
  30: '#FCCFDA',
  40: '#FBBFCE',
  50: '#F8A0B6',
  60: '#F6809D',
  70: '#F46085',
  80: '#D84A6D',
  90: ' #B12B4D',
  100: '#7D0825',
}
