import styled from 'styled-components/macro'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.v3.colorScales.grey[10]};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Container = styled.div`
  height: 70vh;
  width: 50vh;
`

export const UpdatePasswordCard = styled.div`
  padding: ${({ theme }) => theme.v3.margins.content.spacing.medium};
  background: ${({ theme }) => theme.v3.colors.white};
  border-radius: ${({ theme }) => theme.v3.borderRadius.large};
`

export const ContentContainer = styled.div`
  text-align: center;
`

export const LangSelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 50%;
  }
`
export const LockIconContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 50px;
    height: 50px;
  }
`
