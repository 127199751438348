import React, { FC, useState } from 'react'
import Menu from 'components/Menu/Menu'
import { NavBar } from 'components/Navbar/Navs'
import {
  DesktopContent,
  MobileContent,
  MobileSpacing,
  NavBarWrapper,
} from './styles'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { useIsDesktopScreen } from 'design-system/theme/breakpoints'
import { useRouteMatch } from 'react-router'
import * as paths from 'routes/paths'
import { MobileHeader } from 'components/Navbar/MobileHeader'
import { InvalidPayoutBanner } from 'components/Navbar/InvalidPayoutBanner'

const NavbarWrapper: FC = ({ children }) => {
  const { user } = useCurrentUser()
  const [menuOpen, setMenuOpen] = useState(false)
  const isDesktop = useIsDesktopScreen()
  const isOnInventoryPage = useRouteMatch({
    path: paths.INVENTORY.template(),
    strict: true,
    sensitive: true,
  })

  if (!user || !user.location) {
    return null
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }
  const openMenu = () => {
    setMenuOpen(true)
  }

  if (!isDesktop) {
    return (
      <NavBarWrapper>
        <InvalidPayoutBanner />
        <div>
          <Menu isOpen={menuOpen} close={closeMenu} />
        </div>
        {!isOnInventoryPage && <MobileHeader openMenu={openMenu} />}
        <MobileContent>{children}</MobileContent>
        {!isOnInventoryPage && (
          /*
           * This fix is only because navbar is absolute on tablet above the whole page
           * We should not wrap inventory page in navbar wrapper but it't not possible atm because it's so tightly bound to sale page
           * */
          <>
            <MobileSpacing />
            <NavBar />
          </>
        )}
      </NavBarWrapper>
    )
  }

  return (
    <NavBarWrapper>
      <InvalidPayoutBanner />
      <div>
        <Menu isOpen={true} close={() => null} />
      </div>
      <DesktopContent>{children}</DesktopContent>
    </NavBarWrapper>
  )
}

export default NavbarWrapper
