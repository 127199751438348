import { FC } from 'react'
import { useHistory } from 'react-router'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { SALE, SALES_PLANNER, LOGIN } from './paths'

export const RedirectLogin: FC = () => {
  const history = useHistory()
  history.push(LOGIN.template())
  return null
}

export const RedirectHome: FC = () => {
  const history = useHistory()
  const { user } = useCurrentUser()
  const proLocation = !user?.location?.karmaBasic
  history.push(proLocation ? SALE.template() : SALES_PLANNER)
  return null
}
