import { BorderRadius } from './styled'

export const createBorderRadius = (): BorderRadius => ({
  default: '5px',
  image: '8px',
  sheet: '20px',
})

export const newBorderRadius = {
  none: '0px',
  small: '8px',
  large: '24px',
}

export type NewBorderRadius = typeof newBorderRadius
