import styled from 'styled-components/macro'

interface Props {
  align?: string
}

export const Row = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => align || 'flex-start'};
  flex: 1;
  width: 100%;
`
