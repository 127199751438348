import { useContext, useEffect } from 'react'
import { ModalContext } from '../ModalProvider'
import { useValueDidChange } from 'utils/useValueDidChange'

interface ModalArgs {
  modalContent: React.ReactNode
  onModalClose?: () => void
}
export const useModal = ({ modalContent, onModalClose }: ModalArgs) => {
  const modalContext = useContext(ModalContext)
  const openDidChange = useValueDidChange(modalContext.isModalOpen)
  const openModal = () => {
    modalContext.openModal(modalContent)
  }
  useEffect(() => {
    if (openDidChange && !modalContext.isModalOpen) {
      onModalClose && onModalClose()
    }
  }, [onModalClose, openDidChange, modalContext.isModalOpen])
  return {
    openModal,
    closeModal: modalContext.closeModal,
    isModalOpen: modalContext.isModalOpen,
  }
}
