import { ColorScale } from '..'

export const flamingo: ColorScale = {
  0: '#FFFFFF',
  10: '#FFF3F3',
  20: '#FFE8E8',
  30: '#FFDCDC',
  40: '#FFD1D1',
  50: '#FFC5C5',
  60: '#FFAEAE',
  70: '#FF8B8B',
  80: '#E46F6F',
  90: ' #CB5858',
  100: '#811A1A',
}
