import React, { FC } from 'react'

interface Props {
  fill?: string
  stroke?: string
  transparent?: boolean
  scale?: number
}

const CheckMarkCircle: FC<Props> = ({
  fill: customFill,
  stroke: customStroke,
  transparent,
  scale = 1,
}) => {
  const fill = customFill || '#2EB668'
  const stroke = customStroke || 'white'
  const size = 16 * scale
  if (transparent) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="7.25" stroke={stroke} strokeWidth="1.5" />
        <path
          d="M5 7.75L6.875 9.625L11 5.5"
          stroke={stroke}
          strokeWidth="1.5"
        />
      </svg>
    )
  }
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" fill={fill} />
      <path
        d="M4 7.44444L5.54 9L9.5 5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckMarkCircle
