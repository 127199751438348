import styled from 'styled-components/macro'
import { LargeModal } from 'components/Modal/styles'
import { Spinner } from 'design-system/Spinner'
import { H2 } from 'design-system/Typography/H2'
import { Paragraph } from 'design-system/Typography/Paragraph'
import CheckMarkCircle from 'icons/checkMarkCircle'
import React from 'react'
import { SuccessTarget } from './reducer'
import { useIntl } from 'react-intl'

const CheckmarkContainer = styled.div`
  margin: ${({ theme }) => theme.v3.margins.content.spacing.small};
`

const Success: React.FC<{ target: SuccessTarget }> = ({ target }) => {
  const { formatMessage } = useIntl()
  return (
    <LargeModal style={{ textAlign: 'center' }}>
      <H2 textAlign="center">{formatMessage({ id: 'common.success' })}</H2>
      <CheckmarkContainer>
        <CheckMarkCircle scale={4} />
      </CheckmarkContainer>
      {target === 'IMPORT' && (
        <Paragraph>
          {formatMessage({ id: 'deliverect.import.status.success.body' })}
        </Paragraph>
      )}
    </LargeModal>
  )
}

const Failed: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <LargeModal style={{ textAlign: 'center' }}>
      <H2 textAlign="center">{formatMessage({ id: 'common.ohNo' })}</H2>
      <Paragraph>
        {formatMessage({ id: 'deliverect.import.status.failed.body' })}
      </Paragraph>
    </LargeModal>
  )
}

const Loading: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <LargeModal style={{ textAlign: 'center' }}>
      <H2 textAlign="center">
        {formatMessage({ id: 'deliverect.import.status.loading.body' })}
      </H2>
      <Spinner loading />
    </LargeModal>
  )
}

export const ImportStatus = {
  Success,
  Failed,
  Loading,
}
