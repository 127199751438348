import { ColorScale } from '..'

export const green: ColorScale = {
  0: '#FFFFFF',
  10: '#EAF8F0',
  20: '#D5F0E1',
  30: '#C0E9D2',
  40: '#ABE2C3',
  50: '#86D6A8',
  60: '#58C586',
  70: '#2EB668',
  80: '#36995C',
  90: ' #2B824D',
  100: '#055B2A',
}
