import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  height: 40px;
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.medium};
`

const Label = styled.span`
  display: flex;
  text-align: center;
`

interface OptionProps {
  selected: boolean
  locked: boolean
}

const ToggleOption = styled.div<OptionProps>`
  background-color: ${({ selected, theme, locked }) => {
    if (selected && locked) {
      return theme.v2.colors.purple[70]
    }
    if (selected) {
      return theme.v2.colors.green[70]
    }
    return theme.v2.colors.grayscale[0]
  }};

  color: ${({ selected, theme }) =>
    selected ? theme.v2.colors.grayscale[0] : theme.v2.colors.grayscale[50]};
  display: flex;

  flex: 1 0 0;
  flex-direction: column;
  justify-content: center;
  border: ${({ theme, locked }) =>
    `1px solid ${
      locked ? theme.v2.colors.purple[70] : theme.v2.colors.green[70]
    }`};
  align-items: center;
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.v3.borderRadius.small};
    border-bottom-left-radius: ${({ theme }) => theme.v3.borderRadius.small};
  }
  &:last-child {
    border-top-right-radius: ${({ theme }) => theme.v3.borderRadius.small};
    border-bottom-right-radius: ${({ theme }) => theme.v3.borderRadius.small};
  }
  &:not(:last-child) {
    border-right: none;
  }
  &:hover {
    opacity: 0.9;
    cursor: ${({ locked }) => (locked ? 'not-allowed' : 'pointer')};
  }
`
type OptionType = string | number

type Option<T extends OptionType> = {
  label: string
  value: T
}

interface Props<T extends OptionType> {
  options: Option<T>[]
  selected: T
  locked?: boolean
  onSelect: (selected: T) => any
}

export function PillToggle<T extends OptionType>(props: Props<T>) {
  const { options, selected, onSelect, locked } = props

  return (
    <Wrapper>
      {options.map((option) => (
        <ToggleOption
          locked={!!locked}
          selected={selected === option.value}
          onClick={() => {
            if (locked) {
              return
            }
            onSelect(option.value)
          }}
          key={option.label}
        >
          <Label>{option.label}</Label>
        </ToggleOption>
      ))}
    </Wrapper>
  )
}
