import React from 'react'
import styled from 'styled-components/macro'
import { useNotificationBar } from 'design-system/NotificationBar'
import { useListenForEvent } from '../hooks/useListenForEvent'
import { H4 } from 'design-system/Typography/H4'
import { useHistory } from 'react-router'
import { ORDERS } from 'routes/paths'
import { useIntl } from 'react-intl'

export const useListenForOrderRatings = () => {
  const { showInfoNotification } = useNotificationBar()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const onNotificationClick = () => {
    history.push(ORDERS)
  }
  useListenForEvent('LOCATION_RATING', 'global.notification', (message) => {
    const { payload } = message
    if (!payload.motivation && !payload.score) {
      return
    }
    const starString = payload.score ? '♥️'.repeat(payload.score) : null
    const MessageElement = (
      <MessageContainer>
        <H4>{formatMessage({ id: 'transactions.alert.newRating' })}</H4>
        {starString && <div>{starString}</div>}
        {payload.motivation && <Motivation>{payload.motivation}</Motivation>}
      </MessageContainer>
    )
    showInfoNotification({
      id: `locationRating.global.${payload.userId}`,
      message: MessageElement,
      onClick: onNotificationClick,
    })
  })
}

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Motivation = styled.div`
  margin: ${({ theme }) => theme.v4.margins.content.small} 0;
  padding: ${({ theme }) => theme.v4.margins.content.small};
  background-color: ${({ theme }) => theme.v3.colors.lightestGrey};
  font-style: italic;
`
