import { useMediaQuery } from 'react-responsive'

export type ScreenSize = 'small' | 'medium' | 'large' | 'extraLarge'

type Breakpoint = {
  minWidth: number
  maxWidth: number
  mediaQuery: string
  mediaQueryWithLimit: string
}

export type Breakpoints = { [S in ScreenSize]: Breakpoint }

export const breakpoints: Breakpoints = {
  small: {
    minWidth: 0,
    maxWidth: 755,
    mediaQuery: '@media (max-width: 755px)',
    mediaQueryWithLimit: '@media (min-width: 0px) and (max-width: 755px)',
  },
  medium: {
    minWidth: 756,
    maxWidth: 1279,
    mediaQuery: '@media (min-width: 756px)',
    mediaQueryWithLimit: '@media (min-width: 756px) and (max-width: 1279px)',
  },
  large: {
    minWidth: 1280,
    maxWidth: 50000,
    mediaQuery: '@media (min-width: 1280px)',
    mediaQueryWithLimit: '@media (min-width: 1280px) and (max-width: 1920px)',
  },
  extraLarge: {
    minWidth: 1920,
    maxWidth: 50000,
    mediaQuery: '@media (min-width: 1920px)',
    mediaQueryWithLimit: '@media (min-width: 1920px) and (max-width: 50000px)',
  },
}

export const useScreenSize = (): ScreenSize => {
  const isExtraLarge = useMediaQuery(breakpoints.extraLarge)
  const isLarge = useMediaQuery(breakpoints.large)
  const isMedium = useMediaQuery(breakpoints.medium)
  if (isExtraLarge) {
    return 'extraLarge'
  }
  if (isLarge) {
    return 'large'
  }
  if (isMedium) {
    return 'medium'
  }
  return 'small'
}

export const useIsDesktopScreen = () => {
  const screenSize = useScreenSize()
  return screenSize === 'large' || screenSize === 'extraLarge'
}
