import React, { useEffect, useState } from 'react'
import { useNotificationBar } from './hooks'
import { Notification } from './types'
import {
  NotificationWrapper,
  NotificationCard,
  Message,
  IconContainer,
} from './styles'
import Close from '../../icons/close'
import { DefaultTheme } from 'styled-components/macro'

const NOTIFICATION_TIMEOUT_S = 10

interface NotificationBarProps {
  theme?: DefaultTheme
}

export const NotificationBar: React.FunctionComponent<NotificationBarProps> = ({
  children,
  theme,
}) => {
  const { notifications, hideNotification } = useNotificationBar()
  return (
    <>
      <NotificationWrapper theme={theme}>
        {notifications.map((n) => (
          <NotificationItem
            theme={theme}
            key={n.id}
            notification={n}
            hideNotification={hideNotification}
          />
        ))}
      </NotificationWrapper>
      {children}
    </>
  )
}

interface NotificationProp {
  notification: Notification
  hideNotification: (id: string) => void
  theme?: DefaultTheme
}

const NotificationItem: React.FunctionComponent<NotificationProp> = ({
  hideNotification,
  notification,
  theme,
}) => {
  const [stopAutohide, setStopAutohide] = useState(false)
  const [hideCounter, setHideCounter] = useState(0)
  const onCloseClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    hideNotification(notification.id)
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (stopAutohide || notification.preventAutoHide) {
        return
      }
      if (hideCounter >= NOTIFICATION_TIMEOUT_S - 1) {
        hideNotification(notification.id)
        return
      }
      setHideCounter((hide) => hide + 1)
    }, 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [
    hideCounter,
    stopAutohide,
    hideNotification,
    notification.id,
    notification.preventAutoHide,
  ])
  const content =
    typeof notification.message === 'string' ? (
      <Message color="black">{notification.message}</Message>
    ) : (
      notification.message
    )

  return (
    <NotificationCard
      theme={theme}
      type={notification.type}
      onClick={() => notification.onClick && notification.onClick()}
      onMouseEnter={() => {
        setStopAutohide(true)
      }}
      onMouseLeave={() => setStopAutohide(false)}
    >
      {content}
      <IconContainer onClick={onCloseClick} type={notification.type}>
        <Close />
      </IconContainer>
    </NotificationCard>
  )
}
