import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { ReactComponent as CloseIcon } from '../../static/images/close-white.svg'
import { Span } from '../../design-system/Typography/Span'

const Banner = styled.div`
  position: relative;
  background: ${({ theme, type }) =>
    (type === 'info' && theme.v3.colors.accent) ||
    (type === 'warning' && theme.v3.colors.ginger) ||
    (type === 'success' && theme.v3.colors.jade)};
  flex: 1 1 0;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  @media (min-width: 1001px) {
    min-height: 80px;
  }
`

const BannerWrapper = styled.div`
  position: fixed;
  top: ${({ position }) => (position === 'bottom' ? null : '0px')};
  bottom: ${({ position }) => (position === 'bottom' ? '0px' : null)};
  left: 0px;
  z-index: 100;
  width: 100%;
  display: flex;
  min-height: 66px;
`

const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v3.colors.white};
`

const Close = styled(CloseIcon)`
  position: absolute;
  overflow: hidden;
  right: 20px;
  cursor: pointer;
`

export class InfoBanner extends PureComponent {
  state = { hidden: false }

  hideBanner = () => {
    this.setState({ hidden: true })
    this.props.onHide && this.props.onHide()
  }

  componentDidMount() {
    const { hideAfterMs } = this.props
    if (hideAfterMs) {
      this.timeout = setTimeout(() => this.hideBanner(), hideAfterMs)
    }
    if (this.props.hide !== undefined) {
      this.setState({ hidden: this.props.hide })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.hide !== this.props.hide) {
      this.setState({ hidden: this.props.hide })
    }
  }
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    return this.state.hidden ? null : (
      <BannerWrapper position={this.props.position}>
        <Banner type={this.props.type} position={this.props.position}>
          {this.props.text && <Span color="white">{this.props.text}</Span>}
          {this.props.children && (
            <ChildWrapper>{this.props.children}</ChildWrapper>
          )}

          <Close onClick={this.hideBanner} />
        </Banner>
      </BannerWrapper>
    )
  }
}

InfoBanner.propTypes = {
  text: PropTypes.string,
  position: PropTypes.string,
  hideAfterMs: PropTypes.number,
  hide: PropTypes.bool,
  onHide: PropTypes.func,
  type: PropTypes.oneOf(['warning', 'success', 'info']).isRequired,
}

InfoBanner.defaultProps = {
  position: 'top',
  type: 'warning',
}
