import { useQuery } from '@apollo/client'
import { GET_PROFILES } from 'components/LocationUserManagement/queries'
import { getProfiles, getProfilesVariables } from '___generated___/getProfiles'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'

interface Props {
  userId?: string
}

const useGetProfiles = ({ userId }: Props) => {
  const { locationId, user } = useCurrentUser()
  const { data, loading, error } = useQuery<getProfiles, getProfilesVariables>(
    GET_PROFILES,
    {
      variables: { locationId, userId: userId ?? '' },
    }
  )
  const profiles = data?.location?.profiles
  const defaultProfile = profiles?.find((profile) => !profile.isSharedAccount)
  return {
    canChangeProfile: user.isSharedAccount,
    defaultProfile,
    profiles,
    loading: loading || (!data && !error),
    error,
  }
}

export default useGetProfiles
