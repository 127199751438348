import { gql, useQuery } from '@apollo/client'
import { getUserLang } from '___generated___/getUserLang'
import localForage from 'localforage'
import { useEffect, useState } from 'react'

const getUserLangQuery = gql`
  query getUserLang {
    current {
      user {
        language
      }
    }
  }
`

type Data = getUserLang

export const useGetUserLang = () => {
  const [token, setToken] = useState<string>()
  useEffect(() => {
    localForage.getItem<string>('token').then((t) => {
      if (t) {
        setToken(t)
      }
    })
  }, [])
  const { data } = useQuery<Data>(getUserLangQuery, {
    skip: !token,
  })

  return data?.current.user.language
}
