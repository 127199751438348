import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client'
import { Formik } from 'formik'
import { injectIntl } from 'react-intl'
import { intlShape } from 'utils/intlShape'
import { Mutation } from '@apollo/client/react/components'
import { GET_USER } from '../UserState'
import { Alert } from '../Alert'
import {
  ButtonContainer,
  Container,
  ContentContainer,
  LockIconContainer,
  UpdatePasswordCard,
  Wrapper,
} from './styles'
import LocaleSelector from '../LocaleSelector'
import logo from '../../static/Logo/KarmaK.svg'
import lock from '../../static/images/lock-gray.svg'
import {
  SpacingHeightLarge,
  SpacingHeightMedium,
} from '../../design-system/Spacing/styles'
import { Button } from '../../design-system/Button'
import { TextField } from '../../design-system/TextField'
import { H1 } from '../../design-system/Typography/H1'
import { Span } from '../../design-system/Typography/Span'
import { KarmaHead } from '../../KarmaHead'

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UPDATE_USER_PASSWORD($id: ID!, $password: String!) {
    updateUserPassword(id: $id, password: $password) {
      message
    }
  }
`

class UpdatePassword extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    id: PropTypes.string,
  }

  state = {
    modalIsOpen: true,
  }

  render() {
    const { intl } = this.props
    return (
      <>
        <KarmaHead
          title={intl.formatMessage({ id: 'update.password.title' })}
        />
        <Mutation
          mutation={UPDATE_PASSWORD_MUTATION}
          refetchQueries={[
            { query: GET_USER, variables: { id: this.props.id } },
          ]}
        >
          {(updatePassword, { error, loading }) => {
            return (
              <Wrapper>
                <Container>
                  <UpdatePasswordCard>
                    <ContentContainer>
                      <img src={logo} className="logo" alt="Karma" />
                      <H1 className="h1 text-center chocolate">
                        {intl.formatMessage({ id: 'update.password.title' })}
                      </H1>
                      <p className="m0 mb50 text-center chocolate">
                        {intl.formatMessage({ id: 'update.password.subtitle' })}
                      </p>
                    </ContentContainer>
                    {error && (
                      <Alert color="danger">
                        <strong>Shoot!</strong>
                        <p>{error.message.replace('GraphQL error: ', '')}</p>
                      </Alert>
                    )}
                    <Formik
                      initialValues={{ password: '' }}
                      validate={(values) => {
                        let errors = {}
                        if (!values.password) {
                          errors.password = 'Required'
                        }
                        return errors
                      }}
                      onSubmit={async (values, { setSubmitting }) => {
                        await updatePassword({
                          variables: {
                            id: this.props.id,
                            password: values.password,
                          },
                        })
                        setSubmitting(false)
                      }}
                    >
                      {(props) => {
                        const {
                          values,
                          touched,
                          errors,
                          isSubmitting,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        } = props
                        return (
                          <form onSubmit={handleSubmit}>
                            <TextField
                              label={intl.formatMessage({
                                id: 'update.password.input.label',
                              })}
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              placeholder={intl.formatMessage({
                                id: 'update.password.input.placeholder',
                              })}
                              error={touched.password && errors.password}
                              hint={intl.formatMessage({
                                id: 'update.password.input.fudtext',
                              })}
                            />
                            <SpacingHeightLarge />
                            <ButtonContainer>
                              <Button
                                type="submit"
                                disabled={isSubmitting || loading}
                                colorScheme="positive"
                              >
                                <Span color="white">
                                  {intl.formatMessage({
                                    id: 'update.password.button.title',
                                  })}
                                </Span>
                              </Button>
                            </ButtonContainer>
                            <SpacingHeightMedium />
                            <LockIconContainer>
                              <img src={lock} alt="Lock" />
                            </LockIconContainer>
                          </form>
                        )
                      }}
                    </Formik>
                    <LocaleSelector />
                  </UpdatePasswordCard>
                </Container>
              </Wrapper>
            )
          }}
        </Mutation>
      </>
    )
  }
}

export const UpdatePasswordPrompt = injectIntl(UpdatePassword)

export default UpdatePasswordPrompt
