import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { NavWrapper, Section } from './styles'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIsDesktopScreen } from 'design-system/theme/breakpoints'
import { NavBarItem } from 'components/Navbar/NavBarItem'
import { NavBarSale } from 'components/Navbar/NavBarSale'
import { NavBarOrders } from 'components/Navbar/NavBarOrders'
import * as paths from 'routes/paths'
import { NavBarSalePlanner } from 'components/Navbar/NavBarSalePlanner'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'

export const ICON_STYLE = { width: 20, height: 20 }

export const NavBar: React.FC = () => {
  const { user } = useCurrentUser()
  const showSalesPlannerButton = user?.location?.karmaBasic
  const showSalesButton = !user?.location?.karmaBasic
  const isDesktopScreen = useIsDesktopScreen()
  const intl = useIntl()
  const { pathname } = useLocation()
  const history = useHistory()

  const navigateTo = (path: string) => () => {
    window.analytics.track('navigated_to', {
      path,
    })
    history.push(path)
  }
  const isMobile = !isDesktopScreen
  return (
    <NavWrapper noMargin={isMobile}>
      <Section>
        {showSalesButton && (
          <NavBarSale navigateTo={navigateTo(paths.SALE.template())} />
        )}
        {showSalesPlannerButton && (
          <NavBarSalePlanner navigateTo={navigateTo(paths.SALES_PLANNER)} />
        )}
        <NavBarOrders navigateTo={navigateTo(paths.ORDERS)} />
        <NavBarItem
          isActive={pathname.startsWith(paths.STATISTICS)}
          onClick={navigateTo(paths.STATISTICS)}
          icon={<FontAwesomeIcon icon={faChartArea} style={ICON_STYLE} />}
          label={intl.formatMessage({
            id: 'stats.title',
          })}
        />
      </Section>
    </NavWrapper>
  )
}
