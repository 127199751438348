import React, { useContext, useEffect, useState } from 'react'
import localForage from 'localforage'
import { iOSPostMessage, RNPostMessage } from 'utils/shellAppCommunication'
import { useApolloClient } from '@apollo/client'
import Loading from 'components/Loading'
import { resetSelectedProfile } from 'components/LocationUserManagement/hooks/useSelectProfile'

const Context = React.createContext<IAuthContext>({
  loading: true,
  setToken: () => Promise.reject('setToken() is not implemented'),
  resetOnLogout: () => {},
})

export interface IAuthContext {
  loading: boolean
  setToken: (token: string) => Promise<void>
  token?: string | null
  resetOnLogout: VoidFunction
}

export const useAuth = (): IAuthContext => useContext(Context)

const AuthTokenProvider: React.FC = ({ children }) => {
  const [loading, setIsLoading] = useState(true)
  const [token, setTokenState] = useState<string | null>()
  const client = useApolloClient()
  const loadTokenFromStorage = async () => {
    const token = await localForage.getItem<string>('token')
    setTokenState(token)
    setIsLoading(false)
  }
  useEffect(() => {
    loadTokenFromStorage()
  }, [])

  const setToken = async (token: string | null) => {
    await localForage.setItem('token', token)
    setTokenState(token)
  }

  const resetOnLogout = async () => {
    iOSPostMessage({ type: 'logout', data: {} })
    RNPostMessage('logoutRN')
    window.analytics.track('Logout', {})
    window.analytics.reset()
    // Write over token in localstorage
    if (setToken) {
      await setToken(null)
    }
    resetSelectedProfile()
    // Delete everything in the storage provider.
    await client.cache.reset()
    window.location.reload()
  }
  if (loading) {
    return <Loading />
  }
  return (
    <Context.Provider value={{ loading, token, setToken, resetOnLogout }}>
      {children}
    </Context.Provider>
  )
}

export { AuthTokenProvider, Context as AuthTokenContext }
