import React from 'react'

interface Props {
  stroke?: string
}
const Close: React.FunctionComponent<Props> = ({ stroke: customStroke }) => {
  const stroke = customStroke || 'currentColor'
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L11 11" stroke={stroke} strokeWidth="1.5" />
      <path d="M11 1L1 11" stroke={stroke} strokeWidth="1.5" />
    </svg>
  )
}

export default Close
