import { gql } from '@apollo/client'
import { useQuery, useMutation } from '@apollo/client'
import { getUnreadReleaseGuides } from '___generated___/getUnreadReleaseGuides'
import { ReleaseGuide } from './types'
import { markReleaseGuidesAsReadVariables } from '___generated___/markReleaseGuidesAsRead'
import { QueryHookResult } from 'types/queryHooks'

const GET_UNREAD_RELEASE_GUIDE_SLIDES = gql`
  query getUnreadReleaseGuides {
    current {
      user {
        unreadReleaseGuides {
          id
          titles {
            en
            fr
            sv
          }
          slides {
            index
            markdowns {
              en
              fr
              sv
            }
          }
        }
      }
    }
  }
`

export const useGetUnreadReleaseGuides = (): QueryHookResult<
  ReleaseGuide[]
> => {
  const { data, error, loading } = useQuery<getUnreadReleaseGuides>(
    GET_UNREAD_RELEASE_GUIDE_SLIDES
  )
  if (error) {
    return { state: 'ERROR', error }
  }
  if (loading || !data) {
    return { state: 'LOADING' }
  }

  return { state: 'DATA', data: data?.current.user.unreadReleaseGuides }
}

const MARK_RELEASE_GUIDES_AS_READ = gql`
  mutation markReleaseGuidesAsRead($guideIds: [ID!]!) {
    markReleaseGuidesAsRead(releaseGuideIds: $guideIds) {
      success
    }
  }
`
export const useMarkGuidesAsRead = () => {
  const [mutation] = useMutation<{}, markReleaseGuidesAsReadVariables>(
    MARK_RELEASE_GUIDES_AS_READ
  )
  const markGuidesAsRead = async (
    variables: markReleaseGuidesAsReadVariables
  ) => {
    await mutation({ variables })
  }
  return markGuidesAsRead
}
