import React from 'react'
import { gql } from '@apollo/client'
import { useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { WarningText } from '../WarningText'
import SettingsCard from '../SettingsCard'
import { getAccessList } from '___generated___/getAccessList'
import './styles.css'
import { Select } from 'design-system/Select'
import useSetActiveLocation from 'components/Navbar/hooks/useSetActiveLocation'

const LOCATION_ACCESS_QUERY = gql`
  query getAccessList {
    access {
      locations {
        name
        id
        active
        karmaBasic
      }
    }
  }
`

type LocationRestriction = 'pro' | 'basic'
interface Props {
  restrict?: LocationRestriction
}

const LocationSelector: React.FC<Props> = ({ restrict }) => {
  const intl = useIntl()
  const { data, loading, error } = useQuery<getAccessList>(
    LOCATION_ACCESS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    }
  )
  const locations = data?.access?.locations ?? []
  const activeLocation = locations.find((location) => location?.active) ?? {
    id: '',
  }
  const { setActiveLocation, error: setlocationError } = useSetActiveLocation()
  if (error) {
    return (
      <>
        <SettingsCard
          title={intl.formatMessage({
            id: 'location.selector.label',
          })}
        >
          <WarningText
            text={intl.formatMessage({
              id: 'error.text',
            })}
          />
        </SettingsCard>
      </>
    )
  }
  if (setlocationError) {
    window.location.reload()
  }
  const handleChange = (locationId: string) => {
    setActiveLocation({
      variables: {
        locationId,
      },
    })
  }

  return (
    <SettingsCard loading={loading}>
      <Select
        label={intl.formatMessage({
          id: 'location.selector.label',
        })}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          event.preventDefault()
          handleChange(event.target.value)
        }}
        value={activeLocation.id}
      >
        {locations.map((location, key) => {
          const basicLocation = location?.karmaBasic ?? false
          const basicRestricted = basicLocation && restrict === 'pro'
          const proRestricted = !basicLocation && restrict === 'basic'
          return (
            <option
              value={location?.id}
              key={key}
              disabled={basicRestricted || proRestricted}
            >
              {location?.name}
            </option>
          )
        })}
      </Select>
    </SettingsCard>
  )
}

export { LocationSelector, LOCATION_ACCESS_QUERY }
