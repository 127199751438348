import React, { useCallback, useEffect, useState } from 'react'
import localForage from 'localforage'
import { IntlProvider } from 'react-intl'
import allLangData from '../../static/locales'
import config from '../../config'
// We have to use moment for react-dates
import 'moment/locale/sv'
import 'moment/locale/fr'
import 'moment/locale/en-gb'
import moment from 'moment'
import Loading from 'components/Loading'
import { ContextState, Locale, LocaleState } from './types'
import { polyfill } from './polyfill'
import { useGetUserLang } from './hooks/useGetUserLang'
import { useSetUserLang } from './hooks/useSetUserLang'
import { Settings } from 'luxon'
import keys from 'lodash/keys'
import { sentryCaptureExceptionInFunction } from 'utils/exceptionUtils'

const guessLang = () => {
  if (window.navigator) {
    if (window.navigator.languages) {
      const navLangMatch = window.navigator.languages.find(
        (navLangKey) => navLangKey in allLangData
      )
      return navLangMatch || config.defaultLocale
    }
    if (window.navigator.language in allLangData) {
      return window.navigator.language
    }
  }
  return config.defaultLocale
}

const Context = React.createContext<ContextState>({
  loading: true,
  supportedLocales: config.supportedLocales,
})

const IntlProviderWrapper: React.FC = ({ children }) => {
  const [localeState, setLocaleState] = useState<LocaleState>({
    state: 'LOADING',
  })
  const backendLanguage = useGetUserLang()
  const setBackendLanguage = useSetUserLang()

  const setLanguageLocally = useCallback(async (localeString: string) => {
    const localeIsSupported = keys(allLangData).includes(localeString)
    if (!localeIsSupported) {
      sentryCaptureExceptionInFunction(
        'localeError',
        Error(`Locale not supported ${localeString}`)
      )
      return false
    }
    const locale = allLangData[localeString as keyof typeof allLangData]
    await polyfill(locale).catch((error) => {
      sentryCaptureExceptionInFunction('polyfillCatch', error)
    })
    moment.locale(locale)
    Settings.defaultLocale = locale
    Settings.defaultZoneName = 'Europe/Stockholm'
    import(`static/locales/${locale}.js`).then((lang) => {
      setLocaleState({
        locale: locale,
        state: 'DONE',
        messages: lang.default.messages,
      })
    })
    return true
  }, [])

  useEffect(() => {
    if (backendLanguage) {
      setLanguageLocally(backendLanguage)
    }
  }, [backendLanguage, setLanguageLocally])
  useEffect(() => {
    localForage.getItem('locale').then((storedLang) => {
      if (storedLang && typeof storedLang === 'string') {
        const success = setLanguageLocally(storedLang)
        if (!success) {
          setLanguageLocally(config.defaultLocale)
        }
      } else {
        const success = setLanguageLocally(guessLang())
        if (!success) {
          setLanguageLocally(config.defaultLocale)
        }
      }
    })
  }, [setLanguageLocally])

  const setLocale = useCallback(
    async (localeString: string) => {
      const success = await setLanguageLocally(localeString)
      if (success) {
        setBackendLanguage(localeString as Locale)
      }
    },
    [setLanguageLocally, setBackendLanguage]
  )

  if (localeState.state === 'LOADING') {
    return <Loading />
  }
  const locale = localeState.locale
  const messages = localeState.messages

  const contextState: ContextState = {
    loading: false,
    setLocale,
    messages,
    locale,
    supportedLocales: config.supportedLocales,
  }

  return (
    <Context.Provider value={contextState}>
      <IntlProvider key={locale} locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
