import { useState, FC, ReactElement } from 'react'
import range from 'lodash/range'
import slice from 'lodash/slice'

const paginate = (collection: any[], pageSize: number) =>
  collection.reduce(
    (acc, item, index) => {
      const pageNumber = Math.floor(index / pageSize)
      const page = acc[pageNumber] || []
      return {
        ...acc,
        [pageNumber]: [...page, item],
      }
    },
    { 0: [] }
  )

const getShownPages = ({
  pageNumbers,
  maxPagesShown,
  currentPageNumber,
}: {
  pageNumbers: number[]
  maxPagesShown: number
  currentPageNumber: number
}) => {
  if (maxPagesShown >= pageNumbers.length) {
    return pageNumbers
  }
  const rightLeftWindow = Math.floor((maxPagesShown - 1) / 2)
  const leftIndex =
    Math.max(currentPageNumber - rightLeftWindow, 0) -
    Math.max(1 + currentPageNumber + rightLeftWindow - pageNumbers.length, 0)

  const rightIndex = leftIndex + maxPagesShown
  return slice(pageNumbers, leftIndex, rightIndex)
}

interface ChildProps {
  pageItems: any[]
  goToPreviousPage: () => void
  goToNextPage: () => void
  goToPage: (toPageNumber: number) => void
  pageNumbers: number[]
  currentPageNumber: number
  hasSeveralPages: boolean
  hasNextPage: boolean
  hasPreviousPage: boolean
}
interface Props {
  collection: any[]
  pageSize: number
  maxPagesShown: number
  children(childProps: ChildProps): ReactElement
}

const Pagination: FC<Props> = ({
  collection,
  pageSize,
  maxPagesShown,
  children,
}) => {
  const [currentPageNumber, setPageNumber] = useState<number>(0)

  const setPage = (page: number, pages: { [key: number]: object }) => {
    if (!pages[page]) {
      return
    }
    setPageNumber(page)
  }
  const page = currentPageNumber
  const pages = paginate(collection, pageSize)

  const lastPage =
    collection.length >= 1 ? Math.floor((collection.length - 1) / pageSize) : 0
  const pageNumbers = range(lastPage + 1)
  const hasSeveralPages = pageNumbers.length > 1

  const hasNextPage = page < lastPage
  const hasPreviousPage = page > 0
  const goToNextPage = () => {
    setPage(currentPageNumber + 1, pages)
  }
  const goToPreviousPage = () => {
    setPage(currentPageNumber - 1, pages)
  }
  const goToPage = (page: number) => {
    setPage(page, pages)
  }

  const pagesShown = getShownPages({
    pageNumbers,
    maxPagesShown,
    currentPageNumber: page,
  })

  return children({
    pageItems: pages[page] || [],
    goToNextPage,
    goToPreviousPage,
    goToPage,
    hasSeveralPages,
    currentPageNumber: page,
    pageNumbers: pagesShown,
    hasNextPage,
    hasPreviousPage,
  })
}

export default Pagination
