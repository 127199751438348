import React from 'react'
import { getSvgProps, IconOrientation } from './utils'
import { DesignSystemIcon } from './types'

export const Close: DesignSystemIcon = (props) => {
  return (
    <svg viewBox="0 0 18 18" {...getSvgProps(props)}>
      <IconOrientation {...props} viewBoxSize={8}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2932 13.7071C12.6837 14.0976 13.3169 14.0976 13.7074 13.7071C14.0979 13.3166 14.0979 12.6834 13.7074 12.2929L10.4145 9L13.7074 5.70711C14.0979 5.31658 14.0979 4.68342 13.7074 4.29289C13.3169 3.90237 12.6837 3.90237 12.2932 4.29289L9.00031 7.58579L5.70741 4.29289C5.31689 3.90237 4.68372 3.90237 4.2932 4.29289C3.90267 4.68342 3.90267 5.31658 4.2932 5.70711L7.58609 9L4.2932 12.2929C3.90267 12.6834 3.90267 13.3166 4.2932 13.7071C4.68372 14.0976 5.31689 14.0976 5.70741 13.7071L9.00031 10.4142L12.2932 13.7071Z"
          fill={props.color ?? 'black'}
        />
      </IconOrientation>
    </svg>
  )
}
