import useSelectProfile from 'components/LocationUserManagement/hooks/useSelectProfile'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import useUserManagement from 'components/LocationUserManagement/hooks/useUserManagement'
import { useQuery } from '@apollo/client'
import { GET_PROFILES_WITH_ACHIEVEMENTS } from 'components/LocationUserManagement/queries'
import {
  getProfilesWithAchievements,
  getProfilesWithAchievements_location_profiles_achievementGroups_achievements,
  getProfilesWithAchievementsVariables,
} from '___generated___/getProfilesWithAchievements'

const useGetActiveProfile = () => {
  const { locationId, user } = useCurrentUser()
  const { loading: loadingUsers } = useUserManagement()
  const { selectedProfileId } = useSelectProfile()
  const { data, loading, error, refetch } = useQuery<
    getProfilesWithAchievements,
    getProfilesWithAchievementsVariables
  >(GET_PROFILES_WITH_ACHIEVEMENTS, {
    variables: { locationId, userId: user.id },
  })
  const profiles = data?.location?.profiles
  const activeProfile = selectedProfileId
    ? profiles?.find((profile) => {
        return profile?.id === selectedProfileId
      })
    : undefined
  if (error) {
    throw error
  }
  const completedAchievements =
    activeProfile?.achievementGroups.reduce((acc, achievement) => {
      const achievements = achievement.achievements.filter(
        (ach) => ach.claimedAt
      )
      return [...acc, ...achievements]
    }, [] as getProfilesWithAchievements_location_profiles_achievementGroups_achievements[]) ??
    []
  const completedUnSeenAchievements = completedAchievements?.filter(
    (ach) => !ach.seenByCurrentProfile
  )
  return {
    profile: activeProfile,
    completedAchievements,
    completedUnSeenAchievements,
    loading: loadingUsers || loading,
    refetch,
  }
}

export default useGetActiveProfile
