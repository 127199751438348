import React, { FC } from 'react'
import styled from 'styled-components/macro'
import { CategoryTagKey } from '___generated___/globalTypes'
import { Bread } from 'design-system/icons/bread'
import { Drink } from 'design-system/icons/drink'
import { Meal } from 'design-system/icons/meal'
import { Snack } from 'design-system/icons/snack'
import { Groceries } from 'design-system/icons/groceries'
import { Pastry } from 'design-system/icons/pastry'
import { NewColor } from 'design-system/theme/newColors'
import { useTheme } from 'styled-components/macro'

interface Props {
  category: CategoryTagKey | null
  hasFocus?: boolean
  height?: string
  style?: React.CSSProperties
}

interface DivProps {
  backgroundColor: string
  hasFocus?: boolean
  height: string
}

const Fallback = styled.div<DivProps>`
  width: 100%;
  height: ${({ height }) => height};
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};

  background: ${({ backgroundColor }) => backgroundColor};
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ hasFocus, theme }) =>
    hasFocus && `box-shadow: 0 0 0 2px ${theme.v3.colors.black};`}
`

const getIcon = (category: CategoryTagKey) => {
  if (category === CategoryTagKey.BREAD) {
    return Bread
  }
  if (category === CategoryTagKey.DRINK) {
    return Drink
  }
  if (category === CategoryTagKey.FULL_MEAL) {
    return Meal
  }
  if (category === CategoryTagKey.GROCERY) {
    return Groceries
  }
  if (category === CategoryTagKey.SWEETS) {
    return Pastry
  }
  if (category === CategoryTagKey.SNACK) {
    return Snack
  }
  return Meal
}

const getBackgroundColor = (category: CategoryTagKey): NewColor => {
  if (category === CategoryTagKey.BREAD) {
    return 'turtle'
  }
  if (category === CategoryTagKey.DRINK) {
    return 'karma'
  }
  if (category === CategoryTagKey.FULL_MEAL) {
    return 'salmon'
  }
  if (category === CategoryTagKey.GROCERY) {
    return 'mint'
  }
  if (category === CategoryTagKey.SWEETS) {
    return 'ginger'
  }
  if (category === CategoryTagKey.SNACK) {
    return 'ginger'
  }
  return 'salmon'
}

export const ItemFallbackImage: FC<Props> = (props) => {
  const category = props.category || CategoryTagKey.FULL_MEAL

  const theme = useTheme()
  const { hasFocus, height = '120px', style } = props

  const Icon = getIcon(category)
  const color = getBackgroundColor(category)
  const colorHex = theme.v3.colors[color]
  return (
    <Fallback
      hasFocus={hasFocus}
      backgroundColor={colorHex}
      height={height}
      style={style}
    >
      <Icon size={28} color="white" />
    </Fallback>
  )
}
