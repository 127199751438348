import useGetActiveProfile from 'components/LocationUserManagement/hooks/useGetActiveProfile'
import React, { useState } from 'react'
import { LargeModal } from 'components/Modal/styles'
import { H2 } from 'design-system/Typography/H2'
import { Button, ButtonSpinner } from 'design-system/Button'
import { CompletedUnseenAchievement } from 'components/Achievement/CompletedUnseenAchievement'
import { Span } from 'design-system/Typography/Span'
import { useIntl } from 'react-intl'
import {
  CompletedAchievementActions,
  CompletedAchievementDot,
  CompletedAchievementDots,
} from 'components/Achievement/NewAchievementsModal.styles'
import useMarkCompletedAchievement from 'components/Achievement/hooks/useMarkCompletedAchievement'
import Loading from 'components/Loading'

const NewAchievementsModal = () => {
  const { formatMessage } = useIntl()
  const { completedUnSeenAchievements, loading } = useGetActiveProfile()
  const { markAsSeen } = useMarkCompletedAchievement()
  const [currentAchievementIndex, setCurrentAchievementIndex] = useState(0)
  const [isRefetching, setIsRefetching] = useState(false)
  const onNextClick = () => {
    setCurrentAchievementIndex(currentAchievementIndex + 1)
  }
  const onFinishClick = async () => {
    setIsRefetching(true)
    await markAsSeen()
    setCurrentAchievementIndex(0)
    setIsRefetching(false)
  }
  const isOnLastAchievement =
    currentAchievementIndex >= completedUnSeenAchievements.length - 1
  return (
    <LargeModal style={{ textAlign: 'center' }}>
      <H2>{formatMessage({ id: 'achievements.completed.title' })}</H2>
      {loading ? (
        <Loading />
      ) : (
        <>
          {completedUnSeenAchievements && (
            <CompletedUnseenAchievement
              achievement={completedUnSeenAchievements[currentAchievementIndex]}
            />
          )}
          <CompletedAchievementDots>
            {completedUnSeenAchievements.map((_, i) => {
              return (
                <CompletedAchievementDot
                  key={`achievement-dot-${i}`}
                  isActive={i === currentAchievementIndex}
                />
              )
            })}
          </CompletedAchievementDots>
          <CompletedAchievementActions>
            {isOnLastAchievement ? (
              <Button
                onClick={onFinishClick}
                colorScheme="positive"
                disabled={isRefetching}
              >
                {isRefetching && <ButtonSpinner />}
                <Span color="white">
                  {formatMessage({ id: 'common.close' })}
                </Span>
              </Button>
            ) : (
              <Button onClick={onNextClick} colorScheme="positive">
                <Span>{formatMessage({ id: 'common.next' })}</Span>
              </Button>
            )}
          </CompletedAchievementActions>
        </>
      )}
    </LargeModal>
  )
}

export { NewAchievementsModal }
