import React, { FC } from 'react'
import {
  BaseCardBody,
  BaseCardHeader,
  BaseCardWrapper,
} from 'design-system/BaseCard/BaseCard.styles'
import { FoodBadge } from 'components/CreateItemForm/FoodBadge'
import { FoodBadges } from 'components/CreateItemForm/FoodBadge.styles'

interface Props {
  overlay?: React.ReactNode
  header: React.ReactNode
  body: React.ReactNode
  onClick?: VoidFunction
  badges?: string[]
  active?: boolean
}

const BaseCard: FC<Props> = ({
  header,
  body,
  onClick,
  overlay,
  badges,
  active = false,
}) => {
  return (
    <BaseCardWrapper onClick={onClick} active={active}>
      {overlay}
      <BaseCardHeader>
        {badges?.length
          ? badges.length > 0 && (
              <FoodBadges>
                {badges?.map((badge, i) => {
                  return (
                    <FoodBadge key={`food-badge-${badge}-${i}`} text={badge} />
                  )
                })}
              </FoodBadges>
            )
          : null}
        {header}
      </BaseCardHeader>
      <BaseCardBody>{body}</BaseCardBody>
    </BaseCardWrapper>
  )
}

export { BaseCard }
