import ACHIEVEMENT from 'apollo/mocks/data/achievement'
import { AchievementKind } from '___generated___/globalTypes'

const PROFILE = ({
  id = Math.floor(Math.random() * 9999),
  name = `${Math.random().toString(36).substring(7)}_name`,
  isSharedAccountProfile = false,
}: {
  id?: number
  name?: string
  isSharedAccountProfile?: boolean
}) => {
  const achievementGroups = [
    {
      __typename: 'AchievementGroup',
      achievements: [
        ACHIEVEMENT({ kind: AchievementKind.STREAK }),
        ACHIEVEMENT({ kind: AchievementKind.STREAK }),
        ACHIEVEMENT({ kind: AchievementKind.STREAK }),
      ],
    },
    {
      __typename: 'AchievementGroup',
      achievements: [
        ACHIEVEMENT({
          kind: AchievementKind.MILESTONE,
          claimedAt: '2012-12-12',
        }),
      ],
    },
    {
      __typename: 'AchievementGroup',
      achievements: [
        ACHIEVEMENT({
          kind: AchievementKind.RECURRING_EVENT,
          claimedAt: '1950-01-01',
        }),
      ],
    },
    {
      __typename: 'AchievementGroup',
      achievements: [ACHIEVEMENT({ claimedAt: null })],
    },
  ]
  const accumulatedAchievementPoints = achievementGroups.reduce((acc, curr) => {
    const achievements = curr.achievements.reduce((curr, ach) => {
      return curr + (ach.claimedAt ? ach.points : 0)
    }, 0)
    return acc + achievements
  }, 0)
  return {
    __typename: 'Profile',
    id,
    name,
    isSharedAccountProfile,
    accumulatedAchievementPoints,
    remainingAchievementPoints: Math.floor(Math.random() * 12345),
    achievementGroups: achievementGroups,
  }
}

export default PROFILE
