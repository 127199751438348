/**
 * Make a message call towards the iOS shell app
 * @param {object} message
 */
export const iOSPostMessage = (message) => {
  if (typeof window.webkit !== 'undefined') {
    if (message) {
      window.webkit.messageHandlers.shellCall &&
        window.webkit.messageHandlers.shellCall.postMessage(message)
      return message
    } else {
      console.error(
        'iOSPostMessage() missing mandatory input message...come on!'
      )
      return undefined
    }
  } else {
    return undefined
  }
}

/**
 * Make a message call towards the React Native shell app
 * @param {string} message
 */
export const RNPostMessage = (message) => {
  if (message) {
    // new app
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(message)
    // old app
    window.postMessage(message, '*')
    return message
  } else {
    console.error('RNPostMessage() missing mandatory input message...come on!')
    return undefined
  }
}
