import React, { FC, useState } from 'react'
import flatMap from 'lodash/flatMap'
import { useGetUnreadReleaseGuides, useMarkGuidesAsRead } from './queries'
import { sentryCaptureException } from 'utils/exceptionUtils'
import { ReleaseGuide } from './types'
import { Slide } from 'design-system/SlideShow/types'
import { useLocale } from 'utils/useLocale'
import { SlideShow } from 'design-system/SlideShow'
import { Locale } from 'providers/IntlProviderWrapper/types'

const mapToSlides = (
  releaseGuides: ReleaseGuide[],
  locale: Locale
): Slide[] => {
  return flatMap(releaseGuides, (guide) => {
    const { slides, titles } = guide
    const title = titles[locale]
    const slideMarkdowns = slides.map((slide) => slide.markdowns[locale])
    return slideMarkdowns.map((markdown) => ({
      markdown,
      title,
    }))
  })
}

interface Props {}

export const ReleaseGuideSlideShowOverlay: FC<Props> = ({ children }) => {
  const [show, setShow] = useState(true)
  const guideQuery = useGetUnreadReleaseGuides()
  const markGuidesAsRead = useMarkGuidesAsRead()
  const locale = useLocale()
  if (guideQuery.state === 'ERROR') {
    sentryCaptureException(ReleaseGuideSlideShowOverlay, guideQuery.error)
    return <>{children}</>
  }
  if (guideQuery.state === 'LOADING') {
    return <>{children}</>
  }
  const releaseGuides = guideQuery.data
  const slides = mapToSlides(releaseGuides, locale)
  const filteredSlides = slides.filter(
    (slide) => slide.markdown.trim().length > 0 && slide.title.trim().length > 0
  )
  const onFinished = () => {
    const guideIds = releaseGuides.map((guide) => guide.id)
    setShow(false)
    markGuidesAsRead({ guideIds })
  }
  if (filteredSlides.length === 0) {
    return <>{children}</>
  }
  if (!show) {
    return <>{children}</>
  }
  return (
    <>
      <SlideShow slides={filteredSlides} onFinished={onFinished} />
      <div style={{ overflow: 'hidden' }}>{children}</div>
    </>
  )
}
