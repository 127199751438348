import PropTypes from 'prop-types'

/**
 * When migrating from react-intl 2->3 they removed
 * intlShape PropType. To facilitate type checking
 * on our most common props, this utility can be used
 * as a replacement.
 */
export const intlShape = PropTypes.shape({
  formatMessage: PropTypes.func,
  locale: PropTypes.string,
})
