import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import {
  TooltipPosition,
  TutorialToolTip,
} from 'components/Tutorial/TutorialToolTip'
import { getScrollParent } from 'utils/getScrollParent'
import {
  FullscreenTutorial,
  Tutorial,
  tutorialReducer,
} from './tutorialReducer'
import { useTutorialQuery } from './hooks/useTutorialQuery'
import { useWindowSize } from 'utils/useWindowSize'

const INTIAL_REDUCER_STATE = {
  allIds: [],
  unCompletedIds: [],
  completedIds: [],
  byId: {},
  loading: true,
}

export const getPosition = (element: HTMLElement | null): TooltipPosition => {
  if (!element) {
    return {
      vertical: 'below',
      horizontal: 'right',
      x: 0,
      y: 0,
    }
  }
  const { offsetHeight, offsetWidth } = element
  const scrollParent = getScrollParent(element)

  const bounds = element.getBoundingClientRect()
  const scrollParentBounds = scrollParent.getBoundingClientRect()

  const spaceLeft = bounds.left - scrollParentBounds.left
  const spaceAbove = bounds.top - scrollParentBounds.top
  const spaceRight = scrollParentBounds.right - bounds.right
  const spaceBelow = scrollParentBounds.bottom - bounds.bottom

  const vertical = spaceAbove > spaceBelow ? 'above' : 'below'
  const horizontal = spaceLeft > spaceRight ? 'left' : 'right'
  return {
    vertical,
    horizontal,
    x: horizontal === 'right' ? 0 : offsetWidth,
    y: vertical === 'above' ? 0 : offsetHeight,
  }
}

const initialContextState = {
  addTutorial: ({ tutorial }: { tutorial: Tutorial }) => {},
  completedTutorialIds: [] as string[],
  loading: true,
  completeTutorial: ({ id }: { id: string }) => {},
  addFullTutorial: ({ tutorial }: { tutorial: FullscreenTutorial }) => {},
}
export const Context = createContext(initialContextState)
const TutorialProvider: FC = ({ children }) => {
  const {
    loading: loadingCompletedTutorials,
    data: completedTutorialsData,
    completeTutorial: saveCompletedTutorial,
  } = useTutorialQuery()
  const [state, dispatch] = useReducer(tutorialReducer, INTIAL_REDUCER_STATE)

  useEffect(() => {
    if (loadingCompletedTutorials) {
      return
    }
    if (!state.loading) {
      return
    }
    dispatch({
      type: 'LOAD_COMPLETED_TUTORIALS',
      payload: {
        completedIds: completedTutorialsData.map(
          (completedTutorial) => completedTutorial.key
        ),
      },
    })
  }, [completedTutorialsData, loadingCompletedTutorials, state.loading])

  const addTutorial = useCallback(({ tutorial }: { tutorial: Tutorial }) => {
    dispatch({ type: 'ADD_TUTORIAL', payload: { tutorial } })
  }, [])
  const addFullTutorial = useCallback(
    ({ tutorial }: { tutorial: FullscreenTutorial }) => {
      dispatch({ type: 'ADD_FULLSCREEN_TUTORIAL', payload: { tutorial } })
    },
    []
  )
  const completeTutorial = useCallback(
    ({ id }: { id: string }) => {
      dispatch({ type: 'COMPLETE_TUTORIAL', payload: { id } })
      saveCompletedTutorial({ tutorialId: id })
    },
    [saveCompletedTutorial]
  )

  const currentTutorial = state.byId[state.unCompletedIds[0]]
  const createTooltipNextToThis = currentTutorial?.ref?.current
  const completedTutorialIds = state.completedIds ?? []

  useEffect(() => {
    if (createTooltipNextToThis) {
      createTooltipNextToThis.classList.toggle(
        'highlighted-tutorial-item',
        true
      )
    }
    const scrollAfterDelay = () => {
      if (createTooltipNextToThis) {
        return setTimeout(() => {
          createTooltipNextToThis.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          })
        }, 2000)
      }
    }
    const timeoutId = scrollAfterDelay()
    return () => {
      if (!!timeoutId) {
        clearTimeout(timeoutId)
      }
      if (createTooltipNextToThis) {
        createTooltipNextToThis.classList.remove('highlighted-tutorial-item')
      }
    }
  }, [createTooltipNextToThis])
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>(
    getPosition(createTooltipNextToThis || null)
  )
  const windowSize = useWindowSize()
  useEffect(() => {
    if (!createTooltipNextToThis) {
      return
    }
    setTooltipPosition(getPosition(createTooltipNextToThis))
  }, [windowSize.height, windowSize.width, createTooltipNextToThis])

  return (
    <Context.Provider
      value={{
        addTutorial,
        completedTutorialIds,
        loading: state.loading,
        completeTutorial,
        addFullTutorial,
      }}
    >
      {createTooltipNextToThis && (
        <TutorialToolTip
          {...tooltipPosition}
          target={createTooltipNextToThis}
          title={currentTutorial.title}
          paragraph={currentTutorial.paragraph}
          onClick={() => {
            if (currentTutorial.onClick) {
              currentTutorial.onClick()
            }
            completeTutorial({ id: currentTutorial.id })
          }}
          onCancelClick={() => {
            if (currentTutorial.onCancelClick) {
              currentTutorial.onCancelClick()
            }
            completeTutorial({ id: currentTutorial.id })
          }}
          positiveActionText={currentTutorial.positiveActionText}
          negativeActionText={currentTutorial.negativeActionText}
        />
      )}
      {children}
    </Context.Provider>
  )
}

export { TutorialProvider }
