import { InMemoryCache } from '@apollo/client'
import { defaults } from 'apollo/resolvers'
import get from 'lodash/get'
import { toObjectMap } from 'utils/dataStructures'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        location: {
          merge(existing: any, incoming: any) {
            return { ...existing, ...incoming }
          },
        },
        companyStats: {
          merge(existing: any, incoming: any) {
            return { ...existing, ...incoming }
          },
        },
        company: {
          merge(existing: any, incoming: any) {
            return { ...existing, ...incoming }
          },
        },
        locationStats: {
          merge(existing: any, incoming: any) {
            return { ...existing, ...incoming }
          },
        },
        current: {
          merge(existing: any, incoming: any) {
            return { ...existing, ...incoming }
          },
        },
        statistics: {
          merge(existing: any, incoming: any) {
            return { ...existing, ...incoming }
          },
        },
      },
    },
    Sale: {
      keyFields: ['id', 'day'],
      fields: {
        activeItems: {
          merge(_existing: unknown, incoming: any[]) {
            return [...incoming]
          },
        },
      },
    },
    Item: {
      fields: {
        saleInfo: {
          merge(
            existing: { saleItemId: string }[],
            incoming: { saleItemId: string }[]
          ) {
            const incomingIds = toObjectMap(
              incoming || [],
              (si) => si.saleItemId
            )
            // Exclude any "existing" entries that are included in "incoming"
            const existingWithoutIncoming = (existing || []).filter(
              (si) => incomingIds[si.saleItemId] === undefined
            )
            const merged = [...existingWithoutIncoming, ...(incoming || [])]
            return merged
          },
        },
      },
    },
    Location: {
      fields: {
        todaysSaleTimes: {
          merge(existing: any, incoming: any) {
            return { ...existing, ...incoming }
          },
        },
        transactions: {
          merge(_existing: unknown, incoming: unknown) {
            return incoming
          },
        },
        exceptionalSaleTimes: {
          merge(_existing: unknown, incoming: unknown) {
            return incoming
          },
        },
        queryInventory: {
          read(existing, opts) {
            const { queryInput } = opts?.variables ?? {}
            const { queryString, includeFullpriceItems } = queryInput ?? {}
            const key = `{"queryString":"${queryString}","fpi":${
              includeFullpriceItems || false
            },`
            return get(existing, [key])
          },
          merge(existing, incoming, { variables }) {
            const { queryInput } = variables ?? {}
            const { queryString, includeFullpriceItems } = queryInput ?? {}
            const key = `{"queryString":"${queryString}","fpi":${
              includeFullpriceItems || false
            },`

            let existingItems = []
            try {
              existingItems = get(existing, [key, 'items'], []) ?? []
              const mergedItems = existingItems ? existingItems.slice(0) : []
              const start = variables?.queryInput?.offset ?? mergedItems.length
              const end = start + incoming.items.length
              for (let i = start; i < end; ++i) {
                mergedItems[i] = incoming.items[i - start]
              }
              return {
                ...existing,
                [key]: {
                  items: mergedItems,
                  totalItems: incoming.totalItems,
                },
              }
            } catch (e) {
              return {
                ...existing,
                [key]: {
                  items: existingItems,
                  totalItems: incoming.totalItems,
                },
              }
            }
          },
        },
      },
    },
  },
}).restore(defaults)

const createApolloCache = () => cache

export { createApolloCache }
