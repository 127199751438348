import React from 'react'
import { useListenForDeliverectImports } from './globalHooks/useListenForDeliverectImports'
import { useListenForFollowers } from './globalHooks/useListenForFollowers'
import { useListenForOrderRatings } from './globalHooks/useListenForOrderRatings'
import { useListenForOrders } from './globalHooks/useListenForOrders'
import { useListenForRetailerMessages } from './globalHooks/useListenForRetailerMessages'

export const GlobalRealtimeListeners: React.FC = ({ children }) => {
  useListenForOrders()
  useListenForFollowers()
  useListenForOrderRatings()
  useListenForRetailerMessages()
  useListenForDeliverectImports()
  return <>{children}</>
}
