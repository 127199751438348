import React from 'react'
import ReactDOM from 'react-dom'
import clamp from 'lodash/clamp'
import {
  Container,
  Label,
  SlideBar,
  KnobCore,
  KnobWrapper,
  KnobHalo,
} from './styles'

export class Slider extends React.Component {
  state = {
    currentX: 0,
    dragging: false,
  }

  componentDidUpdate(prevProps) {
    const { max, min } = this.props
    if (prevProps.value !== this.props.value) {
      const x = (100 * (this.props.value - min)) / (max - min)
      this.setState({ currentX: x })
    }
  }

  slide = (clientX) => {
    const rect = ReactDOM.findDOMNode(
      this.refs['SliderRef']
    ).getBoundingClientRect()
    const x = clamp((clientX - rect.x) / rect.width, 0, 1) * 100
    this.setState({ currentX: x })
  }

  startDrag = () => {
    this.setState({ dragging: true })
    const mouseMove = (e) => this.slide(e.clientX)
    const touchMove = (e) => this.slide(e.changedTouches[0].clientX)
    const mouseUp = () => {
      this.setState({ dragging: false })
      this.releaseDrag()
      window.removeEventListener('mousemove', mouseMove)
      window.removeEventListener('touchmove', touchMove)
      window.removeEventListener('mouseup', mouseUp)
      window.removeEventListener('touchend', mouseUp)
    }
    window.addEventListener('mousemove', mouseMove)
    window.addEventListener('touchmove', touchMove)
    window.addEventListener('mouseup', mouseUp)
    window.addEventListener('touchend', mouseUp)
  }

  releaseDrag = () => {
    this.props.onChange(this.getSnappedValue())
  }

  getSnappedValue = () => {
    const { max, min, stepSize = 1 } = this.props
    const { currentX } = this.state
    const currentValue = min + (currentX / 100) * (max - min)
    return Math.floor(currentValue / stepSize) * stepSize
  }

  startDragAndSlide = (clientX) => {
    this.startDrag()
    this.slide(clientX)
  }

  render() {
    const { currentX, dragging } = this.state

    return (
      <Container>
        <Label>{this.getSnappedValue()}%</Label>
        <SlideBar
          onMouseDown={(e) => this.startDragAndSlide(e.clientX)}
          onTouchStart={(e) =>
            this.startDragAndSlide(e.changedTouches[0].clientX)
          }
          x={currentX}
          ref="SliderRef"
        >
          <KnobWrapper
            x={currentX}
            onMouseDown={this.startDrag}
            onTouchStart={this.startDrag}
          >
            <KnobCore x={currentX} />
            {dragging && <KnobHalo />}
          </KnobWrapper>
        </SlideBar>
      </Container>
    )
  }
}
