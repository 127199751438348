import {
  KarmaBasicLocationCategory,
  SalePeriod,
} from '___generated___/globalTypes'
import { randomValueFromArray } from 'utils/numberUtils'
import LOCATION from 'apollo/mocks/data/location'
import HOLIDAY from 'apollo/mocks/data/holiday'
import STATISTIC from 'apollo/mocks/data/statistic'
import CUSTOM_TAG from 'apollo/mocks/data/customTag'
import { ISODate } from '@karmalicious/ktl-iso-date'
import range from 'lodash/range'
import ITEM_SUGGESTION from 'apollo/mocks/data/itemSuggestion'

export const mockResolvers = {
  Query: {
    holidays: () => [
      HOLIDAY(),
      HOLIDAY({
        weeksFromNow: 10,
        name: 'Can edit and its active',
        isHandled: false,
      }),
      HOLIDAY({ weeksFromNow: 10, name: 'Cant edit', isHandled: true }),
    ],
    current: ({ current }: any) => {
      return {
        ...current,
        location: {
          ...current.location,
          itemSuggestions: [
            ITEM_SUGGESTION(),
            ITEM_SUGGESTION(),
            ITEM_SUGGESTION(),
            ITEM_SUGGESTION(),
            ITEM_SUGGESTION(),
            ITEM_SUGGESTION(),
          ],
        },
      }
    },
    location: async (parent: any, args: any) => {
      const delayTime = Math.random() * 2500
      await new Promise((resolve) => setTimeout(resolve, delayTime))
      const locationId =
        args?.locationId ||
        args?.id ||
        parent?.user?.location?.id ||
        parent?.location?.id ||
        null
      return LOCATION({ id: locationId })
    },
    customTagSearch: async (parent: unknown, args: any) => {
      const results = Math.floor(Math.random() * 11) + 1
      return range(0, results).map(() =>
        CUSTOM_TAG({
          categoryKey: args.input.categoryKey,
          prefix: args.input.input,
        })
      )
    },
    statistics: async (
      parent: any,
      args: {
        params: {
          timeRange: { start: ISODate; end: ISODate }
          locationIds: string[]
        }
      }
    ) => {
      const delayTime = Math.random() * 1000 * 2
      await new Promise((resolve) => setTimeout(resolve, delayTime))
      return STATISTIC({
        from: args.params.timeRange.start,
        to: args.params.timeRange.end,
        locationIds: args.params.locationIds ?? ['MOCK_LOCATION_ID'],
      })
    },
    user: async (parent: any) => {
      const delayTime = Math.random() * 2500
      await new Promise((resolve) => setTimeout(resolve, delayTime))
      return {
        ...parent,
        isSharedAccount: false, //TODO: use retailuser mock data
      }
    },
  },
  ItemSaleInfo: {
    period: () =>
      // null,
      Math.random() > 0.3
        ? SalePeriod.BREAKFAST
        : Math.random() > 0.3
        ? SalePeriod.AFTERNOON
        : null,
  },
  KarmaBasicItem: {
    includedInCategory: () => [
      randomValueFromArray(Object.values(KarmaBasicLocationCategory)),
    ],
  },
}
