// We have to use moment for react-dates
import 'moment/locale/sv'
import 'moment/locale/fr'
import 'moment/locale/en-gb'

import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillPlurals } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumbers } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTime } from '@formatjs/intl-relativetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillDateTime } from '@formatjs/intl-datetimeformat/should-polyfill'

import { Locale } from './types'

export const polyfill = async (locale: Locale) => {
  if (shouldPolyfillCanonicalLocales && shouldPolyfillCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill')
  }
  if (shouldPolyfillLocale && shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill')
  }

  if (shouldPolyfillPlurals && shouldPolyfillPlurals()) {
    await import('@formatjs/intl-pluralrules/polyfill' as any)
  }
  if ((Intl.PluralRules as any).polyfilled) {
    switch (locale) {
      default:
        await import('@formatjs/intl-pluralrules/locale-data/en' as any)
        break
      case 'fr':
        await import('@formatjs/intl-pluralrules/locale-data/fr' as any)
        break
      case 'sv':
        await import('@formatjs/intl-relativetimeformat/locale-data/sv' as any)
        break
    }
  }

  if (shouldPolyfillNumbers && shouldPolyfillNumbers()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-numberformat/polyfill')
  }

  if ((Intl.NumberFormat as any)?.polyfilled) {
    switch (locale) {
      default:
        await import('@formatjs/intl-numberformat/locale-data/en' as any)
        break
      case 'fr':
        await import('@formatjs/intl-numberformat/locale-data/fr' as any)
        break
      case 'sv':
        await import('@formatjs/intl-numberformat/locale-data/sv' as any)
        break
    }
  }
  if (shouldPolyfillRelativeTime && shouldPolyfillRelativeTime()) {
    await import('@formatjs/intl-relativetimeformat/polyfill')
  }
  if ((Intl.RelativeTimeFormat as any).polyfilled) {
    switch (locale) {
      default:
        await import('@formatjs/intl-relativetimeformat/locale-data/en' as any)
        break
      case 'fr':
        await import('@formatjs/intl-relativetimeformat/locale-data/fr' as any)
        break
      case 'sv':
        await import('@formatjs/intl-relativetimeformat/locale-data/sv' as any)
        break
    }
  }

  if (shouldPolyfillDateTime && shouldPolyfillDateTime()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-datetimeformat/polyfill')
  }

  if ((Intl.DateTimeFormat as any)?.polyfilled) {
    // Parallelize CLDR data loading
    // @ts-ignore
    const dataPolyfills = [import('@formatjs/intl-datetimeformat/add-all-tz')]

    switch (locale) {
      default:
        dataPolyfills.push(
          // @ts-ignore
          import('@formatjs/intl-datetimeformat/locale-data/en.js')
        )
        break
      case 'fr':
        dataPolyfills.push(
          // @ts-ignore
          import('@formatjs/intl-datetimeformat/locale-data/fr')
        )
        break
      case 'sv':
        dataPolyfills.push(
          // @ts-ignore
          import('@formatjs/intl-datetimeformat/locale-data/sv')
        )
        break
    }
    await Promise.all(dataPolyfills)
  }
}
