import React, { FC } from 'react'
import styled, {
  StyledComponentProps,
  DefaultTheme,
} from 'styled-components/macro'
import {
  useGridParams,
  GridParams,
  layoutGrids,
} from 'design-system/theme/grid'
import { ScreenSize } from 'design-system/theme/breakpoints'

interface Props extends StyledComponentProps<'div', DefaultTheme, {}, never> {
  noMargin?: boolean
  rowGap?: string | true
  forceSize?: ScreenSize
}

interface DivProps {
  params: GridParams
  noMargin?: boolean
  rowGap?: string
}

const GridDiv = styled.div<DivProps>`
  display: grid;
  grid-template-columns: ${({ params }) => `repeat(${params.columns}, 1fr)`};
  grid-column-gap: ${({ params }) => params.gutter};
  grid-row-gap: ${({ rowGap }) => rowGap};
  padding: ${({ params, noMargin }) => `0 ${noMargin ? 0 : params.margin}`};
`

export const LayoutGrid: FC<Props> = ({
  noMargin,
  rowGap,
  children,
  forceSize,
  ...props
}) => {
  let gridParams = useGridParams()
  if (forceSize) {
    gridParams = layoutGrids[forceSize]
  }
  if (typeof rowGap === 'boolean' && rowGap) {
    // Responsive rowGap
    return (
      <GridDiv
        params={gridParams}
        noMargin={noMargin}
        rowGap={gridParams.gutter}
        {...props}
      >
        {children}
      </GridDiv>
    )
  }
  return (
    <GridDiv params={gridParams} noMargin={noMargin} rowGap={rowGap} {...props}>
      {children}
    </GridDiv>
  )
}
