import { useTheme } from 'styled-components/macro'
import { grayscale } from '../colors/grayscale'

export const v3ColorScales = {
  pink: {
    100: '#6D0E2B',
    80: '#C7486E',
    70: '#FF82A7',
    50: '#FFB5CB',
    30: '#F8DBD5',
  },
  green: {
    100: '#173C35',
    80: '#006555',
    75: '#019D5F',
    70: '#29BA80',
    50: '#B4D3C7',
  },
  blue: {
    0: '#FFFFFF',
    10: '#EAF4FB',
    20: '#D5E8F7',
    30: '#BFDDF3',
    40: '#AAD2EF',
    50: '#9FBFF8',
    60: '#55A5DE',
    70: '#276EF1',
    80: '#174291',
    90: ' #206394',
    100: '#07395C',
  },
  yellow: {
    80: '#E28903',
    70: '#F8A30A',
    50: '#FABF53',
  },
  salmon: {
    80: '#9A5C53',
    70: '#F68877',
    50: '#EACBC6',
  },
  grey: {
    0: '#FFFFFF',
    10: '#F9F7F7',
    20: '#F4F1F1',
    30: '#EAE6E6',
    40: '#DFD9D9',
    50: '#D2CCCC',
    60: '#C1BABC',
    70: '#9F989A',
    80: '#736C6E',
    90: '#413C3D',
    100: '#302D2D',
  },
}

const newColors = {
  lightPink: v3ColorScales.pink[30],
  cherryBlossom: v3ColorScales.pink[50],
  karma: v3ColorScales.pink[70],
  plum: v3ColorScales.pink[80],
  jade: v3ColorScales.green[70],
  turtle: v3ColorScales.green[80],
  sacramento: v3ColorScales.green[100],
  action: v3ColorScales.green[75],
  mint: '#99bbb5',
  aqua: '#63c6cb',
  fulvous: v3ColorScales.yellow[80],
  ginger: v3ColorScales.yellow[70],
  banana: v3ColorScales.yellow[50],
  salmon: v3ColorScales.salmon[70],
  crepe: v3ColorScales.salmon[50],
  black: v3ColorScales.grey[100],
  grey: v3ColorScales.grey[70],
  accent: v3ColorScales.blue[70],
  celeste: v3ColorScales.blue[50],
  tertiary: v3ColorScales.grey[70],
  positive: v3ColorScales.green[70],
  negative: v3ColorScales.salmon[70],
  warning: v3ColorScales.yellow[70],
  lightGreen: v3ColorScales.green[50],
  purple: '#7D1FF4',
  locked: v3ColorScales.green[50],

  // Rouge colors
  white: v3ColorScales.grey[0],
  lightGrey: v3ColorScales.grey[40],
  darkGrey: v3ColorScales.grey[80],
  mediumGrey: v3ColorScales.grey[50],
  lighterGrey: grayscale['30'],
  lightestGrey: grayscale['20'],
  transparent: 'rgba(0,0,0,0)',
}

export type NewColor = keyof typeof newColors

export type NewColors = {
  [P in keyof typeof newColors]: string
}
export const createNewColors: () => NewColors = () => newColors

export const useColor = (color: NewColor): string => {
  const theme = useTheme()
  return theme.v3.colors[color]
}
