import styled from 'styled-components/macro'

export const UserRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  justify-content: stretch;
`
export const UserRowItem = styled.div`
  display: grid;
  grid-template-columns: 2fr auto;
  align-items: center;
  padding: ${({ theme }) => theme.v3.margins.content.spacing.small} 0;
  &:hover {
    background-color: ${({ theme }) => theme.v3.colorScales.grey[10]};
  }
`
export const UserRowItemPending = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 2fr auto;
    align-items: center;
    margin: ${({ theme }) => theme.v3.margins.content.spacing.small} 0;
    &:hover {
      background-color: ${({ theme }) => theme.v3.colorScales.grey[10]};
    }
  `,
  Info: styled.div`
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  `,
}

export const UserRowButtonContainer = styled.div`
  display: flex;
  justify-self: right;
  justify-content: end;
`

export const LoadingDots = styled.span`
  :after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgb(159, 152, 154);
      text-shadow: 0.25em 0 0 rgb(159, 152, 154), 0.5em 0 0 rgb(159, 152, 154);
    }
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgb(159, 152, 154), 0.5em 0 0 rgb(159, 152, 154);
    }
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgb(159, 152, 154);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }
`

export const EditUserDropdownItem = styled.button`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.v3.margins.content.spacing.small} 0;
  outline: none;
  margin: 0;
  background: transparent;
  border: none;
  cursor: pointer;
`

export const SmallModal = styled.div`
  overflow-y: auto;
  width: 100%;
  max-width: 500px;
  max-height: 95%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.v3.colors.white};
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  padding: ${({ theme }) => theme.v3.margins.content.spacing.large};
  ${({ theme }) => theme.v3.breakpoints.medium.mediaQuery} {
    width: 80%;
  }
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    width: 60%;
  }
`

export const ModalButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.medium};
  & > :nth-child(n + 2) {
    margin-left: ${({ theme }) => theme.v3.margins.content.spacing.small};
  }
`

export const ConnectedAccountsTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.v3.margins.typography.headings.medium};
`
