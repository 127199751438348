import React from 'react'
import styled from 'styled-components/macro'

interface Props {
  responsive?: boolean
  noMargin?: boolean
  scrollable?: boolean
}
interface ContextState extends Props {
  responsive: boolean
  noMargin: boolean
}
export const Context = React.createContext<ContextState>({
  responsive: true,
  noMargin: false,
})

const Layout = styled.div<{ scrollable: boolean }>`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  flex: 1;
  /* Fix for bottom nav bar in safari */
  &&::after {
    padding-bottom: 40px;
    content: '';
  }
  ${({ scrollable }) => scrollable && 'overflow: auto'};
  ${({ theme }) => theme.v3.breakpoints.medium.mediaQuery} {
    &&::after {
      padding-bottom: 0;
      content: '';
    }
  }
`

const ColumnLayout: React.FC<Props> = ({
  children,
  responsive = true,
  noMargin = false,
  scrollable = false,
}) => {
  return (
    <Context.Provider value={{ responsive, noMargin }}>
      <Layout scrollable={scrollable}>{children}</Layout>
    </Context.Provider>
  )
}

export { ColumnLayout }
