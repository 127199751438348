import { Panel } from 'design-system/Panel'
import styled from 'styled-components/macro'

export const CustomerCommunicationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const CustomerCommunicationPanel = styled(Panel)`
  background-color: ${({ theme }) => theme.v3.colorScales.pink[30]};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  flex-direction: column;
  border: 0;
`

export const CustomerCommunicationButtonRow = styled.div`
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.small};
  display: flex;
  width: 100%;
  justify-content: center;
  & > * {
    margin-right: ${({ theme }) => theme.v3.margins.content.spacing.small};
  }
`
export const CustomerCommunicationButtonWrapper = styled.div`
  display: flex;
  max-width: 300px;
`
export const CustomerCommunicationContent = styled.div``
