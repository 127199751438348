import { useRef, useEffect } from 'react'

export const useValueDidChange = <T>(value: T) => {
  const ref = useRef<T>()
  const changed = ref.current !== value && ref.current !== undefined
  useEffect(() => {
    ref.current = value
  })
  return changed
}
