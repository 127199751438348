export const PROD_SAVE_URL = 'your.karma.life'
export const PROD_SERVE_URL = 'serve.karma.life'
export const BETA_URL = 'beta.your.karma.life'

export const DEV_SAVE_URL = 'dev.your.karma.life'
export const DEV_SERVE_URL = 'serve.development.karma.life'

/*
   There are three urls for KfB prod:
  - your.karma.life – for Karma Save (aka Surplus)
  - serve.karma.life – for Karma Serve (aka Order & Pay
  - beta.your.karma.life – this is deprecated and should redirect to serve.karma.life
   And there are two urls for KfB dev:
   - dev.your.karma.life – for Karma Save (aka Surplus)
   - serve.development.karma.life – for Karma Serve (aka Order & Pay)

  To preserve compatibility with existing bookmarks and "add to home screen" on iPads (which might not
  follow redirects), we also provide beta.your.karma.life as an alias for serve.karma.life.
  This url should be considered deprecated.
 */

export const serveSaveRedirect = (
  hasServeAccess: boolean,
  currentHost: string
) => {
  if (currentHost === BETA_URL) {
    return PROD_SERVE_URL
  }
  if (currentHost === PROD_SERVE_URL && hasServeAccess) {
    return ''
  }
  if (currentHost === PROD_SERVE_URL && !hasServeAccess) {
    return PROD_SAVE_URL
  }
  if (currentHost === PROD_SAVE_URL && hasServeAccess) {
    return PROD_SERVE_URL
  }
  if (currentHost === PROD_SAVE_URL && !hasServeAccess) {
    return ''
  }
  if (currentHost === DEV_SAVE_URL && hasServeAccess) {
    return DEV_SERVE_URL
  }
  if (currentHost === DEV_SAVE_URL && !hasServeAccess) {
    return ''
  }
  if (currentHost === DEV_SERVE_URL && hasServeAccess) {
    return ''
  }
  if (currentHost === DEV_SERVE_URL && !hasServeAccess) {
    return DEV_SAVE_URL
  }
  if (currentHost === 'localhost' || currentHost === 'localhost:3000') {
    return ''
  }

  return currentHost
}
