import { PathParam, PathPart } from './types'

export function param<T extends string>(t: T): PathParam<T> {
  return { param: t }
}
export function isParam<T extends string>(
  pathPart: PathPart<T>
): pathPart is PathParam<T> {
  return !!(pathPart as PathParam<T>).param
}
