import React, { FC } from 'react'
import { createPortal } from 'react-dom'
import { ModalContainer } from './styles'
import useDisableBodyScroll from 'utils/useDisableBodyScroll'

interface ModalProps {
  withElement: HTMLElement
}

const ModalPortal: FC<ModalProps> = ({ children, withElement }) => {
  return createPortal(children, withElement)
}

const portalContainer = document.getElementById('modal-root')
interface Props {
  isOpen: boolean
  outsideClick?: VoidFunction
}
export const Modal: FC<Props> = ({ children, isOpen, outsideClick }) => {
  useDisableBodyScroll({
    shouldDisable: isOpen,
  })
  if (!isOpen) {
    return null
  }
  return (
    <ModalPortal withElement={portalContainer!}>
      <ModalContainer
        onClick={(e) => {
          if (e.target === e.currentTarget && !!outsideClick) {
            outsideClick()
          }
        }}
      >
        {children}
      </ModalContainer>
    </ModalPortal>
  )
}
