import styled from 'styled-components/macro'

export const Pane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 10px;
  padding: 15px;
  background-color: ${({
    attention,
    info,
    theme,
  }: {
    attention?: boolean
    info?: boolean
    theme: any
  }) => {
    if (attention) {
      return theme.v3.colors.purple
    }
    if (info) {
      return theme.v3.colors.accent
    }
    return theme.v3.colorScales.grey[10]
  }};
  color: ${({ attention, info }: { attention?: boolean; info?: boolean }) => {
    if (attention || info) {
      return '#fff'
    }
    return '#000'
  }};
  &:hover {
    cursor: ${({ onClick }) => onClick && 'pointer'};
    opacity: ${({ onClick }) => onClick && '0.8'};
  }
`
