import React, { FC } from 'react'
import {
  MobileNavHeader,
  MobileNavHeaderOpenMenu,
  NavBarLogo,
  NavBarLogoImage,
} from 'components/Navbar/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCog } from '@fortawesome/free-solid-svg-icons'
import { ICON_STYLE } from 'components/Navbar/Navs'
import { useQuery } from '@apollo/client'
import { getActiveLocationMenu } from '___generated___/getActiveLocationMenu'
import { GET_ACTIVE_LOCATION_MENU } from 'components/Menu/queries'
import { Spinner } from 'design-system/Spinner'
import { Span } from 'design-system/Typography/Span'
import { useHistory, useRouteMatch } from 'react-router'
import * as paths from 'routes/paths'

interface Props {
  openMenu: VoidFunction
}

const MobileHeader: FC<Props> = ({ openMenu }) => {
  const { data, loading } = useQuery<getActiveLocationMenu>(
    GET_ACTIVE_LOCATION_MENU
  )
  const history = useHistory()
  const locationLogo = data?.current?.location?.logo_image_url
  const goToSettings = () => {
    history.push(paths.SETTINGS)
  }
  const isOnSettingsPage = useRouteMatch({
    path: paths.SETTINGS,
  })
  return (
    <MobileNavHeader>
      <NavBarLogo>
        {loading ? (
          <Spinner
            loading={true}
            style={{ width: '60px', height: '60px' }}
            color="black"
            type="circle"
          />
        ) : locationLogo ? (
          <NavBarLogoImage
            src={locationLogo}
            alt="Karma For Business Karmeleon"
          />
        ) : (
          <Span color="white" variant="large">
            {data?.current?.location?.name?.substr(0, 1)}
          </Span>
        )}
      </NavBarLogo>
      <MobileNavHeaderOpenMenu>
        {isOnSettingsPage ? (
          <FontAwesomeIcon
            icon={faBars}
            style={ICON_STYLE}
            onClick={openMenu}
          />
        ) : (
          <FontAwesomeIcon
            icon={faCog}
            style={ICON_STYLE}
            onClick={goToSettings}
          />
        )}
      </MobileNavHeaderOpenMenu>
    </MobileNavHeader>
  )
}

export { MobileHeader }
