import './style/normalize.css'
import './style/base.css'
import './style/colors.css'
import './style/type.css'
import './static/fonts/inter.css'
import './style/space.css'
import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import './polyfill-resize-observer'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './style/react-calendar.css'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import App from './App'
import { unregister } from './serviceWorker'
import * as Sentry from '@sentry/react'
import { Amplitude, Analytics } from './types/analytics'
import amplitude from 'amplitude-js'

declare global {
  interface Window {
    analytics: Analytics
    amplitude: {
      getInstance: () => Amplitude
    }
    Intercom: (...args: unknown[]) => unknown
  }
}

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string)
}

// Polyfill
if (!Object.entries) {
  Object.entries = function (obj: any) {
    var ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]

    return resArray
  }
}

class Root extends Component {
  state = {
    restored: false,
  }

  render() {
    return <App />
  }
}

window.analytics = {
  track: (event, properties) => {
    amplitude.getInstance().logEvent(event, {
      ...properties,
    })
    if (window.Intercom) {
      window.Intercom('trackEvent', event, properties)
    }
  },
  identify: (userId) => {
    amplitude.getInstance().setUserId(userId)
    Sentry.setUser({
      id: userId,
    })
    if (window.Intercom) {
      console.log('booting intercom')
      window.Intercom('boot', {
        app_id: 'iyl1zixs',
        user_id: userId,
      })
    }
  },
  updateProfile: (properties) => {
    if (window.Intercom) {
      window.Intercom('update', properties)
    }
  },
  reset: () => {
    amplitude.getInstance().setUserId(null)
    amplitude.getInstance().regenerateDeviceId()
    Sentry.configureScope((scope) => scope.clear())
    if (window.Intercom) {
      window.Intercom('shutdown')
    }
  },
}

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister()
amplitude
  .getInstance()
  .init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`, undefined, {
    batchEvents: true,
  })
