import React, { FC, useContext } from 'react'
import jwtDecode from 'jwt-decode'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ACTIVE_USER, SET_USER } from 'apollo/queries/user'
import { AuthTokenContext, useAuth } from '../../providers/AuthTokenProvider'
import {
  setUser as TSetUser,
  setUserVariables as TSetUserVars,
} from '../../___generated___/setUser'
import { TLocalState, TokenContent } from './types'
import { getActiveUser } from '___generated___/getActiveUser'

interface Props {
  render: (localState: TLocalState) => JSX.Element | null | JSX.Element[]
}

const LocalState: FC<Props> = ({ render }) => {
  const [setUserMutation, { loading: loadingSetUser }] = useMutation<
    TSetUser,
    TSetUserVars
  >(SET_USER)
  const { data, error } = useQuery<getActiveUser>(GET_ACTIVE_USER)
  const { token } = useContext(AuthTokenContext)
  const { resetOnLogout } = useAuth()
  const handleInvalidState = async () => {
    try {
      if (!token) {
        // if the token is missing in localForage we reset state (logout)
        resetOnLogout()
        return
      } else {
        const decodedToken = TokenContent.decode(jwtDecode(token))

        if (decodedToken._tag === 'Left') {
          resetOnLogout()
          return
        }
        const decoded = decodedToken.right
        const valid = Date.now() / 1000 < decoded.exp
        if (!valid) {
          // if the token is invalid (non existent or expired) we reset state (logout)
          resetOnLogout()
          return
        } else {
          if (decoded && decoded.user_id) {
            await setUserMutation({ variables: { id: `${decoded.user_id}` } })
          } else {
            resetOnLogout()
            return
          }
        }
      }
    } catch (error) {
      console.error('LocalState handleInvalidState() error', error)
      resetOnLogout()
      return
    }
  }

  if (loadingSetUser || !data) {
    return (
      <>
        {render({
          loading: true,
        })}
      </>
    )
  }

  if (error) {
    throw error
  }

  if (!data.activeUser?.id) {
    // so if the comp isn't loading and we're still missing user id we just reset state (logout)
    handleInvalidState()
    return (
      <>
        {render({
          loading: true,
        })}
      </>
    )
  }

  return (
    <>
      {render({
        loading: false,
        user: {
          id: data.activeUser.id,
        },
      })}
    </>
  )
}

export default LocalState
