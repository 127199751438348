import { V3Spacings } from '.'

export function createSpacings() {
  return {}
}

export const createNewSpacings = () =>
  [
    '4px',
    '8px',
    '12px',
    '16px',
    '20px',
    '24px',
    '28px',
    '32px',
    '36px',
    '40px',
  ] as V3Spacings
