import React from 'react'
import {
  SidebarItemWrapper,
  SidebarItemLabel,
  SideBarItemBadge,
} from 'components/Menu/styles'
import { useLocation } from 'react-router-dom'
import { useMenu } from 'components/Menu/Menu'
import { Span } from 'design-system/Typography/Span'
import { NewColor } from 'design-system/theme/newColors'

type Props =
  | {
      onClick?: undefined
      toPath: string
      icon: React.ReactNode
      title: string
      badgeText?: React.ReactNode
      badgeColor?: NewColor
      indentedBadge?: boolean
      forwardRef?: React.RefObject<HTMLButtonElement>
    }
  | {
      onClick: VoidFunction
      toPath?: undefined
      icon: React.ReactNode
      title: string
      badgeText?: React.ReactNode
      badgeColor?: NewColor
      indentedBadge?: boolean
      forwardRef?: React.RefObject<HTMLButtonElement>
    }

export const SidebarItem = ({
  toPath,
  icon,
  title,
  onClick,
  badgeText,
  badgeColor,
  indentedBadge,
  forwardRef,
}: Props) => {
  const { navigateTo } = useMenu()
  const { pathname: currentUrl } = useLocation()
  const handleClick = onClick
    ? onClick
    : toPath
    ? navigateTo(toPath)
    : () => null
  const isActive = toPath && currentUrl.startsWith(toPath)
  return (
    <SidebarItemWrapper
      onClick={handleClick}
      active={!!isActive}
      ref={forwardRef}
    >
      {icon}
      <SidebarItemLabel title={title} color="inherit" fontWeight={600}>
        {title}
      </SidebarItemLabel>
      {!!badgeText && (
        <SideBarItemBadge
          isIndented={!!indentedBadge}
          backgroundColor={badgeColor}
        >
          <Span color="white" variant="small">
            {badgeText}
          </Span>
        </SideBarItemBadge>
      )}
    </SidebarItemWrapper>
  )
}
