import React from 'react'
import { useIntl } from 'react-intl'
import * as Sentry from '@sentry/react'
import { Span } from 'design-system/Typography/Span'
import { Button } from 'design-system/Button'
import { useAuth } from 'providers/AuthTokenProvider'
import { PageWrapper } from 'components/PageWrapper'
import {
  SpacingHeightLarge,
  SpacingHeightMedium,
} from 'design-system/Spacing/styles'
import { Spacer, ButtonContainer } from './styles'

const LogoutButton = () => {
  const { formatMessage } = useIntl()
  const { resetOnLogout } = useAuth()
  return (
    <Button onClick={resetOnLogout} colorScheme="negative">
      <Span color="white">
        {formatMessage({
          id: 'account.logout.button',
        })}
      </Span>
    </Button>
  )
}

const ErrorBoundaryPage: React.FC = ({ children }) => {
  const intl = useIntl()
  return (
    <Sentry.ErrorBoundary
      fallback={
        <PageWrapper>
          <SpacingHeightLarge />
          <h1>
            {intl.formatMessage({
              id: 'errorPage.title',
            })}
          </h1>
          <Span>
            {intl.formatMessage({
              id: 'errorPage.body',
            })}
          </Span>
          <SpacingHeightMedium />
          <ButtonContainer>
            <Button
              onClick={() => window.location.reload()}
              colorScheme="positive"
            >
              <Span color="white">
                {intl.formatMessage({
                  id: 'errorPage.body.reload',
                })}
              </Span>
            </Button>
            <Spacer />
            <LogoutButton />
          </ButtonContainer>
        </PageWrapper>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundaryPage
