export interface Paddings {
  button: {
    default: string
    dense: string
    fluffy: string
  }
  badge: {
    fluffy: '16px'
    default: '8px'
    dense: '4px'
  }
}

export const createPaddings = (): Paddings => ({
  button: {
    fluffy: '16px',
    default: '8px',
    dense: '4px',
  },
  badge: {
    fluffy: '16px',
    default: '8px',
    dense: '4px',
  },
})
