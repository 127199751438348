import React, { FC } from 'react'
import styled from 'styled-components/macro'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border: ${({ theme }) => `1px solid  ${theme.v3.colors.lighterGrey}`};
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  background-color: ${({ theme }) => theme.v3.colors.white};
  padding: ${({ theme }) => `15px ${theme.v3.paddings.button.fluffy}`};
`
interface Props extends React.HTMLAttributes<HTMLButtonElement> {}

export const Panel: FC<Props> = ({ children, ...props }) => (
  <StyledDiv {...(props as any)}>{children}</StyledDiv>
)
