import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { useSaleQuery } from 'pages/Sale/hooks/useSaleQuery'

const useHasNewDesign = () => {
  const { user } = useCurrentUser()
  const { hasShortExpiry, hasFullprice, hasBundleItemsFlag } = useSaleQuery()
  const isGroceryStore = user.location?.dvh
  return (
    !hasShortExpiry && !hasFullprice && !isGroceryStore && !hasBundleItemsFlag
  )
}

export default useHasNewDesign
