import React, { FC } from 'react'

import { IconProps } from './types'
export const getSvgProps = (props: IconProps) => {
  return {
    width: props.size,
    height: props.size,
    fill: 'none',
    xmlns: 'http://www.w3.org/2000/svg',
  }
}

export const getOrientation = ({
  orientation,
  viewBoxSize,
}: OrientationProps) => {
  const centerX = viewBoxSize / 2
  const centerY = viewBoxSize / 2

  if (orientation === 'down') return `rotate(90, ${centerX}, ${centerY})`
  if (orientation === 'left') return `rotate(180, ${centerX}, ${centerY})`
  if (orientation === 'up') return `rotate(270, ${centerX}, ${centerY})`
  if (orientation === 'diag-up-right')
    return `rotate(315, ${centerX}, ${centerY})`

  return `rotate(0, ${centerX}, ${centerY})`
}

interface OrientationProps extends IconProps {
  viewBoxSize: number
}

export const IconOrientation: FC<OrientationProps> = (props) => {
  const orientation = getOrientation(props)
  return <g transform={orientation}>{props.children}</g>
}
