import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { NavBarItem } from 'components/Navbar/NavBarItem'
import { ICON_STYLE } from 'components/Navbar/Navs'
import { SALE } from 'routes/paths'
import { useInventoryQuery } from 'pages/Sale/hooks/useInventoryQuery'
import { useHasCompletedTutorial } from 'providers/tutorial/hooks/useHasCompletedTutorial'
import { ALL_TUTORIALS } from 'providers/tutorial/TUTORIALS'
import { useTutorial } from 'providers/tutorial/hooks/useTutorial'
import { DateTime } from 'luxon'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  navigateTo: (to: string) => void
}
const ONE_MONTH_IN_DAYS = 30
const NavBarSale: React.FC<Props> = ({ navigateTo }) => {
  const { user } = useCurrentUser()
  const { pathname } = useLocation()
  const intl = useIntl()
  const isActive = pathname === SALE.template()
  const inventory = useInventoryQuery()
  const startSaleProRef = useRef(null)

  const hasCompletedPreviousTutorials = useHasCompletedTutorial(
    ALL_TUTORIALS.FIRST_TIME_CREATE_ITEM_START.id
  )
  const hasCompletedNextTutorial = useHasCompletedTutorial(
    ALL_TUTORIALS.FIRST_TIME_START_SALE_GO_LIVE.id
  )
  const isNewLocation =
    DateTime.fromISO(user.location?.created_date ?? '1970').diffNow('days')
      .days > -ONE_MONTH_IN_DAYS

  useTutorial({
    tutorial: {
      ref: startSaleProRef,
      id: ALL_TUTORIALS.FIRST_TIME_START_SALE_NAVIGATE.id,
      title: intl.formatMessage({
        id: `tutorial.${ALL_TUTORIALS.FIRST_TIME_START_SALE_NAVIGATE.id}.title`,
      }),
      paragraph: intl.formatMessage({
        id: `tutorial.${ALL_TUTORIALS.FIRST_TIME_START_SALE_NAVIGATE.id}.paragraph`,
      }),
      positiveActionText: intl.formatMessage({ id: 'common.got-it' }),
    },
    requirements:
      inventory.itemTotals > 0 &&
      hasCompletedPreviousTutorials &&
      !hasCompletedNextTutorial &&
      isNewLocation,
  })

  return (
    <NavBarItem
      isActive={isActive}
      onClick={() => navigateTo(SALE.template())}
      icon={<FontAwesomeIcon icon={faStore} style={ICON_STYLE} />}
      label={intl.formatMessage({
        id: 'navbar.sale',
      })}
      ref={startSaleProRef}
    />
  )
}

export { NavBarSale }
