import LOCATION from 'apollo/mocks/data/location'
import { roles as UserRoles } from 'utils/userRole'

const USER = ({ id = 999, locationId = '11111111' }) => ({
  __typename: 'RetailerUser',
  id: `${id}`,
  username: `${Math.random().toString(36).substring(7)}_username`,
  email: `${Math.random().toString(36).substring(7)}@karma.mock`,
  name: `${Math.random().toString(36).substring(7)}_username`,
  created_date: '2001-01-01',
  updated_password: true,
  isSharedAccount: false,
  access_schema: [
    {
      __typename: 'AccessRole',
      location_id: locationId,
      role: 'test',
    },
  ],
  location: LOCATION({ id: locationId }),
})

const USER_NO_LOCATION = ({
  id = 999,
  locationId = '11111111',
  isShared = false,
}) => ({
  __typename: 'RetailerUser',
  id: `${id}`,
  username: `${Math.random().toString(36).substring(7)}_username`,
  email: `${Math.random().toString(36).substring(7)}@karma.mock`,
  name: `${Math.random().toString(36).substring(7)}_name`,
  created_date: '2001-01-01',
  updated_password: true,
  isSharedAccount: isShared, //Math.random() > 0.9,
  access_schema: [
    {
      __typename: 'AccessRole',
      location_id: locationId,
      role: UserRoles.STAFF_MEMBER,
    },
  ],
})

export { USER_NO_LOCATION }
export default USER
