import { ColorScale } from '..'

export const purple: ColorScale = {
  0: '#FFFFFF',
  10: '#F4ECFE',
  20: '#EADBFE',
  30: '#D8BCFC',
  40: '#CCA6FB',
  50: '#C094F8',
  60: '#A96BF7',
  70: '#7D1FF4',
  80: '#690DDE',
  90: ' #4F0AA7',
  100: '#370676',
}
