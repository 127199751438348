import { useContext } from 'react'
import { Context } from '../TutorialProvider'
import { TutorialId } from 'providers/tutorial/TUTORIALS'

export const useHasCompletedTutorial = (id: TutorialId | TutorialId[]) => {
  const { completedTutorialIds, loading } = useContext(Context)
  if (loading) {
    return true
  }
  const ids = typeof id === 'string' ? [id] : id
  return ids.every((id) => completedTutorialIds.includes(id))
}
