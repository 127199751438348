import React, { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import reject from 'lodash/reject'
import filter from 'lodash/filter'
import {
  LocationPickerNoResult,
  PageNumber,
  PaginationRow,
  SidebarSearchWrapper,
} from './styles'
import Pagination from 'components/Pagination'
import Chevron from 'icons/chevron'
import { getLocations } from '___generated___/getLocations'
import { SearchInput } from 'design-system/SearchInput'
import { GET_LOCATIONS } from 'components/Menu/queries'
import { Span } from 'design-system/Typography/Span'
import useSetActiveLocation from 'components/Navbar/hooks/useSetActiveLocation'
import { H5 } from 'design-system/Typography/H5'
import { SidebarItem } from 'components/Menu/SidebarItem'
import { useMenu } from 'components/Menu/Menu'

const PAGINATION_SIZE = 3
const SEARCH_PAGINATION_SIZE = 7
const MAX_PAGES_SHOWN = 7

const LocationPicker: FC = () => {
  const { data, loading, error } = useQuery<getLocations>(GET_LOCATIONS)
  const { setActiveLocation } = useSetActiveLocation()
  const [searchString, setSearchString] = useState('')
  const intl = useIntl()
  const { closeMenu: closeSideBar } = useMenu()

  const onSearchChange = (newValue: string) => {
    setSearchString(newValue.toLowerCase())
  }
  const pickLocation = (locationId: string) => () => {
    setActiveLocation({ variables: { locationId } })
    closeSideBar()
  }

  if (loading) {
    return null
  }
  if (error || !data) {
    return null
  }
  const locations = data.access?.locations ?? []
  const activeLocation = locations.find((location) => location?.active)
  if (!activeLocation) {
    return null
  }
  const otherLocations = reject(
    locations,
    (location) => location?.id === activeLocation?.id
  )

  const filteredLocations =
    searchString.length === 0
      ? otherLocations
      : filter(
          otherLocations,
          (l) => l?.name?.toLowerCase()?.search(searchString) !== -1
        )
  const shouldShowSearch = locations.length > PAGINATION_SIZE
  return (
    <>
      {shouldShowSearch && (
        <SidebarSearchWrapper>
          <SearchInput
            value={searchString}
            onValueChange={onSearchChange}
            placeholder={intl.formatMessage({ id: 'navbar.menu.search' })}
          />
        </SidebarSearchWrapper>
      )}
      <Pagination
        collection={filteredLocations}
        maxPagesShown={MAX_PAGES_SHOWN}
        pageSize={
          searchString === '' ? PAGINATION_SIZE : SEARCH_PAGINATION_SIZE
        }
      >
        {({
          pageItems: locations,
          goToPreviousPage,
          goToNextPage,
          goToPage,
          pageNumbers,
          currentPageNumber,
          hasSeveralPages,
          hasNextPage,
          hasPreviousPage,
        }) => (
          <>
            {locations.length === 0 && searchString !== '' && (
              <LocationPickerNoResult>
                <H5>{intl.formatMessage({ id: 'location-picker.no-hits' })}</H5>
              </LocationPickerNoResult>
            )}
            {locations.map((location) => (
              <SidebarItem
                key={location.id}
                onClick={pickLocation(location.id)}
                icon={null}
                title={location.name}
              />
            ))}
            {hasSeveralPages && (
              <PaginationRow>
                {hasPreviousPage && (
                  <PageNumber onClick={goToPreviousPage}>
                    <Chevron orientation="left" />
                  </PageNumber>
                )}
                {pageNumbers.map((i) => (
                  <PageNumber key={i} onClick={() => goToPage(i)}>
                    <Span
                      variant="large"
                      color={i === currentPageNumber ? 'black' : 'grey'}
                    >
                      {i + 1}
                    </Span>
                  </PageNumber>
                ))}
                {hasNextPage && (
                  <PageNumber onClick={goToNextPage}>
                    <Chevron orientation="right" />
                  </PageNumber>
                )}
              </PaginationRow>
            )}
          </>
        )}
      </Pagination>
    </>
  )
}

export default LocationPicker
