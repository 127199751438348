import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
`

export const SlideBar = styled.div.attrs(({ x }) => ({
  style: {
    background: `linear-gradient(to right, #2b8ed6 ${x}%, #D2CCCC ${x}%)`,
  },
}))`
  display: flex;
  position: relative;
  height: 10px;
  width: 100%;
  border-radius: 5px;
`

export const Label = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.v3.colorScales.grey[60]};
  margin-bottom: 10px;
`

export const KnobHalo = styled.div`
  position: absolute;
  top: -19px;
  left: -19px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  opacity: 0.5;
  background-color: #fff;
  border: 4px solid rgba(43, 142, 214, 0.3);
`

export const KnobWrapper = styled.div.attrs(({ x }) => ({
  style: {
    left: `${x}%`,
  },
}))`
  position: absolute;
  top: 50%;
`

export const KnobCore = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: #fff;
  :hover {
    cursor: pointer;
  }
`
