import {
  KarmaScoreCategory as Category,
  KarmaScoreTaskKey as Key,
} from '___generated___/globalTypes'

const createScoreTask = (category: Category, key: Key, done: boolean) => ({
  __typename: 'KarmaScoreTask',
  category,
  done,
  key,
  points: 5,
  priority: -1,
})

const createBasicTasks = () => {
  const phone = createScoreTask(Category.START, Key.CONTACT_INFO_FILLED, true)
  const street = createScoreTask(Category.START, Key.BUSINESS_INFO_SET, true)
  const openingHours = createScoreTask(
    Category.START,
    Key.OPENING_HOURS_SET,
    true
  )
  return [phone, street, openingHours]
}

const createSellTasks = () => {
  const monthlySales = createScoreTask(
    Category.SELL,
    Key.SALES_PER_MONTH_THRESHOLD,
    true
  )
  const saleStreak = createScoreTask(Category.SELL, Key.SALE_STREAK, false)
  const inventory = createScoreTask(
    Category.SELL,
    Key.INVENTORY_COUNT_OVER_THRESHOLD,
    false
  )
  return [monthlySales, saleStreak, inventory]
}

export const completedBasicKarmaScore = () => {
  return {
    totalPoints: 100,
    tasks: [...createBasicTasks(), ...createSellTasks()],
  }
}
