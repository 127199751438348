import styled from 'styled-components/macro'
import { NotificationType } from './types'
import { Span } from 'design-system/Typography/Span'

export const NotificationWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 15px;
  ${({ theme }) => theme.v3.breakpoints.medium.mediaQuery} {
    max-width: 300px;
  }
`

export const NotificationCard = styled.div<{ type: NotificationType }>`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.v3.colors.white};
  border: 2px solid;
  border-color: ${({ theme, type }) => {
    if (type === 'info') {
      return theme.v3.colors.action
    }
    if (type === 'warning') {
      return theme.v3.colors.salmon
    }
  }};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  border-radius: ${({ theme }) => theme.v3.borderRadius.small};
  margin-top: ${({ theme }) => theme.v4.margins.content.medium};
  padding: ${({ theme }) => theme.v4.margins.content.medium};
  &:hover {
    border-color: ${({ theme }) => theme.v3.colors.positive};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }
`

export const Message = styled(Span)`
  flex: 1;
  padding-right: ${({ theme }) => theme.v4.margins.content.medium};
  text-align: left;
`

export const IconContainer = styled.div<{ type: NotificationType }>`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  height: auto;
  margin-left: ${({ theme }) => theme.v4.margins.content.small};
  color: ${({ theme }) => theme.v3.colors.black};
  &:hover {
    cursor: pointer;
    filter: brightness(110%);
  }
`
