const languages = {
  en: 'en',
  'en-GB': 'en',
  'en-US': 'en',
  'sv-FI': 'sv',
  'sv-SE': 'sv',
  sv: 'sv',
  fr: 'fr',
  'fr-BE': 'fr',
  'fr-CA': 'fr',
  'fr-FR': 'fr',
  'fr-LU': 'fr',
  'fr-CH': 'fr',
} as const
export default languages
