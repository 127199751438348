import {
  randomId,
  randomImage,
  randomString,
  randomTruth,
} from 'apollo/mocks/mockUtils'
import { randomIntFromInterval, randomValueFromArray } from 'utils/numberUtils'
import { getItemSuggestions_current_location_itemSuggestions } from '___generated___/getItemSuggestions'
import {
  AllergenTagKey,
  CategoryTagKey,
  PreferenceTagKey,
} from '___generated___/globalTypes'

const ITEM_SUGGESTION = (): getItemSuggestions_current_location_itemSuggestions => {
  return {
    __typename: 'ItemSuggestion',
    id: randomId(),
    category: randomValueFromArray(Object.keys(CategoryTagKey)),
    allergens: randomValueFromArray(Object.keys(AllergenTagKey)),
    preferences: randomValueFromArray(Object.keys(PreferenceTagKey)),
    customTags: ['customtags'],
    title: randomString({ suffix: '_itemSuggestion' }),
    basePrice: randomIntFromInterval(2, 100),
    image: {
      __typename: 'ItemSuggestionImage',
      url: randomImage(),
    },
    discountPercentage: randomIntFromInterval(50, 100),
    fullPrice: randomTruth(),
  }
}

export default ITEM_SUGGESTION
