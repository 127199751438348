import React, { useState } from 'react'
import { Slide } from './types'

import {
  SlideShowTitle,
  SlideShowMain,
  SlideShowFooter,
  ButtonWrapper,
  GlobalBackdropStyle,
  SlideShowOverlayWrapper,
  SlideShowOverlayContainer,
} from './styles'
import { H1 } from 'design-system/Typography/H1'
import { Button } from 'design-system/Button'
import { ProgressDots } from 'design-system/ProgressDots'
import { useIntl } from 'react-intl'
import { Markdown } from 'design-system/Markdown'
import { Span } from 'design-system/Typography/Span'

interface Props {
  slides: Slide[]
  onFinished?: () => void
}

export const SlideShow = (props: Props) => {
  const { slides, onFinished } = props
  const [slideIndex, setSlideIndex] = useState(0)
  const { formatMessage } = useIntl()

  const currentSlide = slides[slideIndex]
  const onLastSlide = slideIndex === slides.length - 1

  const { title } = currentSlide

  const progressSlide = () => {
    if (onLastSlide) {
      if (onFinished) {
        onFinished()
      }
      return
    }
    setSlideIndex(slideIndex + 1)
  }

  const nextText = formatMessage({ id: 'common.next' })
  const doneText = formatMessage({ id: 'common.done' })

  return (
    <>
      <GlobalBackdropStyle />
      <SlideShowOverlayWrapper>
        <SlideShowOverlayContainer>
          <SlideShowTitle>
            <H1 color="white">{title}</H1>
          </SlideShowTitle>
          <SlideShowMain>
            <Markdown markdown={currentSlide.markdown} color="white" />
          </SlideShowMain>
          <SlideShowFooter>
            <ButtonWrapper>
              <Button
                color="white"
                textColor="black"
                height="medium"
                onClick={progressSlide}
              >
                <Span>{onLastSlide ? doneText : nextText}</Span>
              </Button>
            </ButtonWrapper>
            <ProgressDots
              total={slides.length}
              current={slideIndex}
              dotSize={8}
            />
          </SlideShowFooter>
        </SlideShowOverlayContainer>
      </SlideShowOverlayWrapper>
    </>
  )
}
