import React, { FC } from 'react'
import { H1 } from 'design-system/Typography/H1'
import * as paths from 'routes/paths'
import { ACCOUNT } from 'routes/paths'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBoxOpen,
  faBuilding,
  faCalendarAlt,
  faClock,
  faGift,
  faGlobeEurope,
  faPizzaSlice,
  faPlug,
  faStore,
  faUser,
  faUsers,
  faWallet,
} from '@fortawesome/free-solid-svg-icons'
import { ICON_STYLE } from 'components/Menu/MainMenu'
import { useIntl } from 'react-intl'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { hasPayoutPermission } from 'pages/Payout/hasPayoutPermission'
import { useFeatureFlag } from 'components/UserState/hooks/useFeatureFlag'
import {
  SettingsMenuHeader,
  SettingsMenuList,
} from 'pages/Settings/SettingsMenu.styles'
import { SettingSidebarItem } from 'pages/Settings/SettingSidebarItem'
import { roles as UserRoles } from 'utils/userRole'
import { useGetAllReleaseGuides } from 'components/WhatsNew/queries'
import useHasNewDesign from 'utils/useHasNewDesign'
import { hasPermission } from 'utils/permissionTools'
import useActivePayoutAccounts from 'components/Payout/hooks/useActivePayoutAccounts'

const SettingsList: React.FC = () => {
  const { formatMessage } = useIntl()
  const { user } = useCurrentUser()
  const whatsNewReleases = useGetAllReleaseGuides()
  const hasNewDesign = useHasNewDesign()
  const { hasInvalidPayoutStatus } = useActivePayoutAccounts()
  const {
    hasInvalidPayoutStatus: hasInvalidPayoutNewStatus,
  } = useActivePayoutAccounts({
    storefront: true,
  })
  const hasPayoutAccess = hasPayoutPermission(user.permission_set)
  const basicLocation = user.location?.karmaBasic
  const hasSettingsAccess =
    !basicLocation && hasPermission(user.permission_set, ['LOCATION_UPDATE'])
  const hasSaleTimesAccess = !basicLocation
  const hasShortExpiry = useFeatureFlag('short_expiry')
  const companyAdmin =
    user.role === UserRoles.ADMIN || user.role === UserRoles.KARMELEON
  const hasCompanyAccess = companyAdmin && !basicLocation
  const cwiFeatureFlag = user?.location?.beta?.cwi ?? false
  const isAdyenReady =
    user?.location?.supportedPaymentProviders?.includes('adyen') ?? false

  return (
    <SettingsMenuList>
      <SettingSidebarItem
        title={formatMessage({ id: 'account.title' })}
        icon={<FontAwesomeIcon icon={faUser} style={ICON_STYLE} />}
        toPath={ACCOUNT}
        hasAccess
      />
      <SettingSidebarItem
        toPath={paths.LOCATION_DETAILS}
        icon={<FontAwesomeIcon icon={faStore} style={ICON_STYLE} />}
        title={formatMessage({ id: 'settings.location_details.title' })}
        hasAccess={hasSettingsAccess}
      />
      <SettingSidebarItem
        toPath={paths.MANAGE_INVENTORY.create({})}
        icon={<FontAwesomeIcon icon={faBoxOpen} style={ICON_STYLE} />}
        title={formatMessage({ id: 'sale.inventory.title' })}
        hasAccess={hasSettingsAccess && hasNewDesign}
      />
      <SettingSidebarItem
        toPath={paths.OPENING_HOURS}
        icon={<FontAwesomeIcon icon={faClock} style={ICON_STYLE} />}
        title={formatMessage({ id: 'settings.openingHours.title' })}
        hasAccess={hasSaleTimesAccess}
      />
      <SettingSidebarItem
        toPath={paths.OPENING_EXCEPTIONS}
        icon={<FontAwesomeIcon icon={faCalendarAlt} style={ICON_STYLE} />}
        title={formatMessage({ id: 'settings.sale-exceptions.title' })}
        hasAccess={!hasShortExpiry && hasSettingsAccess}
      />
      <SettingSidebarItem
        toPath={paths.ORGANISATION_USERS}
        icon={<FontAwesomeIcon icon={faUsers} style={ICON_STYLE} />}
        title={formatMessage({
          id: 'usermanagement.title',
        })}
        hasAccess={hasCompanyAccess}
      />
      <SettingSidebarItem
        toPath={paths.COMPANY_DETAILS}
        icon={<FontAwesomeIcon icon={faBuilding} style={ICON_STYLE} />}
        title={formatMessage({
          id: 'company.details.title',
        })}
        hasAccess={hasCompanyAccess}
      />
      <SettingSidebarItem
        toPath={paths.PLUGINS}
        icon={<FontAwesomeIcon icon={faPlug} style={ICON_STYLE} />}
        title={formatMessage({ id: 'settings.general' })}
        hasAccess={hasSettingsAccess}
      />
      <SettingSidebarItem
        toPath={paths.ORGANISATION_CWI}
        icon={<FontAwesomeIcon icon={faPizzaSlice} style={ICON_STYLE} />}
        title={formatMessage({
          id: 'organisation.cwi.title',
        })}
        hasAccess={hasCompanyAccess && cwiFeatureFlag}
      />
      <SettingSidebarItem
        toPath={paths.PAYOUT}
        icon={<FontAwesomeIcon icon={faWallet} style={ICON_STYLE} />}
        title={formatMessage({
          id: 'payout.navbar.title',
        })}
        hasAccess={hasPayoutAccess}
        badgeText={
          hasInvalidPayoutStatus
            ? formatMessage({ id: 'errors.invalid' })
            : null
        }
        badgeColor="plum"
      />
      {isAdyenReady && (
        <SettingSidebarItem
          toPath={paths.PAYOUT_NEW}
          icon={<FontAwesomeIcon icon={faWallet} style={ICON_STYLE} />}
          title={formatMessage({
            id: 'payout.new.navbar.title',
          })}
          hasAccess={hasPayoutAccess}
          badgeText={
            hasInvalidPayoutNewStatus
              ? formatMessage({ id: 'errors.invalid' })
              : null
          }
          badgeColor="plum"
        />
      )}
      <SettingSidebarItem
        toPath={paths.GROUPS}
        icon={<FontAwesomeIcon icon={faGlobeEurope} style={ICON_STYLE} />}
        title={formatMessage({
          id: 'groups.navbar.title',
        })}
        hasAccess={hasCompanyAccess}
      />
      <SettingSidebarItem
        toPath={paths.WHATS_NEW}
        icon={<FontAwesomeIcon icon={faGift} style={ICON_STYLE} />}
        title={formatMessage({ id: 'whatsNew.title' })}
        hasAccess={
          whatsNewReleases.state === 'DATA' && whatsNewReleases.data.length > 0
        }
      />
    </SettingsMenuList>
  )
}

const SettingsMenu: FC = () => {
  const { formatMessage } = useIntl()
  return (
    <>
      <SettingsMenuHeader>
        <H1>{formatMessage({ id: 'settings.title' })}</H1>
      </SettingsMenuHeader>
      <SettingsList />
    </>
  )
}

export { SettingsMenu, SettingsList }
