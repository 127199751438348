import React, { FC, useState } from 'react'
import axios from 'axios'
import { InfoBanner } from 'components/InfoBanner'
import logo from 'static/Logo/KarmaK.svg'
import { LOGIN } from 'routes/paths'
import { Container, Logo, SignupForm } from './styles'
import { H1 } from 'design-system/Typography/H1'
import { useIntl } from 'react-intl'
import { Paragraph } from 'design-system/Typography/Paragraph'
import { TextField } from 'design-system/TextField'
import { Button } from 'design-system/Button'
import { useHistory } from 'react-router'

const API_URL = process.env.REACT_APP_API_URL

interface Props {
  invitationToken: string
  email: string
  locationName: string
  name: string
}

export const NewSharedAccount: FC<Props> = ({
  invitationToken,
  email,
  name,
  locationName,
}) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [sending, setSendingStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()
  const intl = useIntl()
  const url = `${API_URL}/auth/accept-invitation-new-user`
  const passwordIsTooShort = password.length < 6
  const passwordsDontMatch = password !== passwordConfirmation
  const hasInvalidFields = passwordIsTooShort || passwordsDontMatch

  const buttonText = sending
    ? intl.formatMessage({ id: 'invitation.signup.button.sending' })
    : intl.formatMessage({ id: 'invitation.signup.button.register' })
  const errorText = errorMessage && intl.formatMessage({ id: errorMessage })

  const registerAndAccept = async () => {
    if (hasInvalidFields || sending) {
      return
    }

    setSendingStatus(true)
    const { data: body } = await axios(url, {
      method: 'POST',
      data: {
        name,
        email,
        invitationToken,
        password,
      },
    })
    if (body.success) {
      history.push(
        LOGIN.create({ queries: { banner: 'registration_success' } })
      )
    } else {
      setSendingStatus(false)
      const registerError = body?.error?.code ?? 'UNKNOWN'
      if (registerError === 'INVALID_TOKEN') {
        setErrorMessage('invitation.signup.errors.invalidToken')
      } else if (registerError === 'MISSING_VALUES') {
        setErrorMessage('invitation.signup.errors.missingValues')
      } else {
        setErrorMessage('errors.general.contactSupport')
      }
    }
  }
  return (
    <>
      {errorText && <InfoBanner text={errorText} />}
      <Container>
        <Logo src={logo} />
        <H1>
          {intl.formatMessage(
            { id: 'invitation.sharedAccount.header' },
            { locationName }
          )}
        </H1>
        <Paragraph color="grey" variant="medium">
          {intl.formatMessage(
            { id: 'invitation.sharedAccount.description' },
            { locationName }
          )}
        </Paragraph>
        <SignupForm onSubmit={(e) => e.preventDefault()}>
          <TextField
            color="gray"
            value={email}
            locked
            label={intl.formatMessage({ id: 'signup.form.mail' })}
          />
          <TextField
            color="gray"
            value={name}
            locked
            label={intl.formatMessage({ id: 'signup.form.name' })}
          />
          <TextField
            type="password"
            value={password}
            label={intl.formatMessage({ id: 'signup.form.password' })}
            error={
              password.length > 0 &&
              passwordIsTooShort &&
              intl.formatMessage({
                id: 'signup.errors.passwordlength',
              })
            }
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            type="password"
            value={passwordConfirmation}
            label={intl.formatMessage({
              id: 'signup.form.confirmpassword',
            })}
            error={
              passwordConfirmation.length > 0 &&
              passwordsDontMatch &&
              intl.formatMessage({
                id: 'signup.errors.nomatchconfirmpassword',
              })
            }
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <Button
            colorScheme="positive"
            onClick={registerAndAccept}
            disabled={hasInvalidFields || sending}
          >
            <Paragraph variant="medium" color="white">
              {buttonText}
            </Paragraph>
          </Button>
        </SignupForm>
      </Container>
    </>
  )
}
