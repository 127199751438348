import { gql } from '@apollo/client'

export const GET_INVITATIONS = gql`
  query getLocationInvitations($locationId: ID!) {
    location(locationId: $locationId) {
      id
      invitations {
        id
        email
        expiry_date
        isSharedAccount
      }
      users {
        id
        email
        name
        isSharedAccount
        access_schema {
          location_id
          role
        }
      }
    }
  }
`

export const GET_PROFILES = gql`
  query getProfiles($userId: ID!, $locationId: ID!) {
    location(locationId: $locationId) {
      id
      profiles(userId: $userId) {
        id
        name
        isSharedAccount
      }
    }
  }
`

export const GET_PROFILES_WITH_ACHIEVEMENTS = gql`
  query getProfilesWithAchievements($userId: ID!, $locationId: ID!) {
    location(locationId: $locationId) {
      id
      profiles(userId: $userId) {
        id
        name
        accumulatedAchievementPoints
        achievementGroups {
          achievements {
            key
            seenByCurrentProfile
            claimedAt
            achievementTarget
            description {
              en
              fr
              sv
            }
            id
            level
            instanceId
            achievementKind
            points
            title {
              en
              fr
              sv
            }
          }
        }
      }
    }
  }
`

export const SEND_INVITATION = gql`
  mutation sendInvitation(
    $locationId: ID!
    $invitation: LocationInvitationInput!
  ) {
    inviteUser(locationId: $locationId, invitation: $invitation) {
      success
    }
  }
`

export const CREATE_SHARED_ACCOUNT = gql`
  mutation createSharedAccount($name: String!, $email: String!) {
    createSharedAccount(name: $name, email: $email) {
      id
    }
  }
`

export const RESEND_INVITATION = gql`
  mutation resendInvitation($locationId: ID!, $invitationId: ID!) {
    resendInvitation(invitationId: $invitationId, locationId: $locationId) {
      success
    }
  }
`

export const RESEND_SHARED_INVITATION = gql`
  mutation resendSharedInvitation($locationId: ID!, $invitationId: ID!) {
    resendInvitation(invitationId: $invitationId, locationId: $locationId) {
      success
    }
  }
`

export const REMOVE_INVITATION = gql`
  mutation removeInvitation($invitationId: ID!) {
    removeInvitation(invitationId: $invitationId) {
      success
    }
  }
`

export const DELETE_USER_ACCESS = gql`
  mutation deleteUserAccess($locationId: ID!, $userId: ID!) {
    deleteUserAccess(locationId: $locationId, userId: $userId) {
      success
    }
  }
`

export const DELETE_PROFILES = gql`
  mutation deleteProfiles($profileIds: [ID!]!) {
    deleteProfiles(profileIds: $profileIds) {
      success
    }
  }
`

export const CREATE_PROFILE = gql`
  mutation createProfile(
    $locationId: ID!
    $input: ProfileInput!
    $userId: ID!
  ) {
    createProfile(
      locationId: $locationId
      profileInput: $input
      userId: $userId
    ) {
      id
    }
  }
`

export const EDIT_PROFILE = gql`
  mutation updateProfile($profileInput: ProfileInput!, $id: ID!) {
    updateProfile(profileInput: $profileInput, profileId: $id) {
      id
    }
  }
`
