import React from 'react'
import { getSvgProps } from './utils'
import { DesignSystemIcon } from './types'

export const Meal: DesignSystemIcon = (props) => {
  return (
    <svg viewBox="0 0 30 30" {...getSvgProps(props)}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8889 11.7778H11.6667V5.11111C11.6667 4.49746 11.1692 4 10.5555 4C9.9419 4 9.44444 4.49746 9.44444 5.11111V11.7778H7.22222V5.11111C7.22222 4.49746 6.72476 4 6.11111 4C5.49746 4 5 4.49746 5 5.11111V11.7778C5 14.1333 6.84444 16.0444 9.16666 16.1889V24.8333C9.16666 25.6004 9.78848 26.2222 10.5555 26.2222C11.3226 26.2222 11.9444 25.6004 11.9444 24.8333V16.1889C14.2666 16.0444 16.1111 14.1333 16.1111 11.7778V5.11111C16.1111 4.49746 15.6136 4 15 4C14.3863 4 13.8889 4.49746 13.8889 5.11111V11.7778ZM19.4444 8.44444V17.3333H22.2222V24.8333C22.2222 25.6004 22.844 26.2222 23.6111 26.2222C24.3782 26.2222 25 25.6004 25 24.8333V4C21.9333 4 19.4444 6.48889 19.4444 8.44444Z"
        style={{ stroke: 'transparent' }}
        fill={props.color}
      />
    </svg>
  )
}
