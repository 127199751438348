import styled from 'styled-components/macro'

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.v3.margins.content.spacing.medium};
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.v3.breakpoints.large.mediaQuery} {
    flex-direction: row;
    & > * {
      margin-right: ${({ theme }) => theme.v3.margins.content.spacing.small};
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
`
