import { useLocalStorageContext } from 'providers/LocalStorageProvider'
import useCurrentUser from 'components/UserState/hooks/useCurrentUser'
import { DateTime } from 'luxon'
import useGetProfiles from 'components/LocationUserManagement/hooks/useGetProfiles'
import localForage from 'localforage'
import { useCallback, useEffect } from 'react'

const PROFILE_IDLE_UPDATE_FREQUENCY_SECONDS = -30
const useSelectProfile = () => {
  const { locationId, user } = useCurrentUser()
  const { state, dispatch } = useLocalStorageContext()
  const {
    canChangeProfile,
    loading: loadingProfiles,
    profiles,
    defaultProfile,
  } = useGetProfiles({
    userId: user.id,
  })
  const loading = loadingProfiles || !state.hasInitialized
  const setSelectedProfile = useCallback(
    (profileId?: string) => {
      dispatch({
        type: 'SELECT_PROFILE',
        payload: {
          profileId: profileId ?? '',
          locationId,
        },
      })
    },
    [dispatch, locationId]
  )
  const { id: selectedProfileId, expiresAt, selectedAt } =
    (state.selectedProfileByLocation ?? {}).lastKnownProfileId ?? {}
  const selectedProfileExistsOnLocation =
    profiles?.some((profile) => {
      return profile.id === selectedProfileId
    }) ?? true
  const hasSelectionExpired =
    !expiresAt || DateTime.fromISO(expiresAt) < DateTime.utc()
  const isStillValid = expiresAt && !hasSelectionExpired
  const shouldUpdateIdleTimer =
    isStillValid &&
    DateTime.fromISO(selectedAt).diffNow('seconds').seconds <
      PROFILE_IDLE_UPDATE_FREQUENCY_SECONDS
  useEffect(() => {
    if (loading) {
      return
    }
    if (shouldUpdateIdleTimer) {
      // Update so that the idle timer doesn't run out
      setSelectedProfile(selectedProfileId)
      return
    }
    if (!canChangeProfile && hasSelectionExpired && defaultProfile) {
      // Update selected profile id for non shared account
      setSelectedProfile(defaultProfile.id)
      return
    }
    if (!selectedProfileExistsOnLocation && selectedProfileId) {
      const newProfileId = defaultProfile?.id ?? undefined
      setSelectedProfile(newProfileId)
      return
    }
  }, [
    shouldUpdateIdleTimer,
    selectedProfileId,
    setSelectedProfile,
    loading,
    canChangeProfile,
    hasSelectionExpired,
    defaultProfile,
    selectedProfileExistsOnLocation,
  ])
  return {
    selectedProfileId: isStillValid ? selectedProfileId : undefined,
    setSelectedProfile,
    loading,
  }
}

export const resetSelectedProfile = async () => {
  const karmaStorage = await localForage.getItem<{
    selectedProfileByLocation: {
      lastKnownProfiledId: {
        id: string
        selectedAt: string
        expiresAt: string
      }
    }
  }>('karma-storage')
  await localForage.setItem('karma-storage', {
    ...karmaStorage,
    selectedProfileByLocation: {
      ...karmaStorage?.selectedProfileByLocation,
      lastKnownProfileId: {
        id: '',
        selectedAt: '',
        expiresAt: '',
      },
    },
  })
}

export default useSelectProfile
