import { mockResolvers, mockTypeDefs } from './mocks'
import { gql, ApolloError } from '@apollo/client'
import { ApolloCache } from '@apollo/client/cache'

const localTypeDefs = gql`
  "Indicates that the client should resolve the field value locally as part of a query, e.g. in a React component"
  directive @client on FIELD
  directive @rest(
    path: String!
    method: String
    type: String!
    endpoint: String
  ) on FIELD
  type ActiveUser {
    id: ID
  }
  type LoginError {
    code: String
  }
  type LoggedInUser {
    success: Boolean
    token: String
    username: String
    userId: ID
    passwordHash: String
    error: LoginError
  }
  extend type Query {
    activeUser: ActiveUser
    transactionsDate: String
    activeLastDays(days: Int!): Boolean
  }
  extend type Mutation {
    setUser(id: ID!): Boolean
    loginUser(input: LoginInput!): LoggedInUser
    loginUserWithToken(input: LoginWithTokenInput!): LoggedInUser
  }

  input LoginInput {
    name: String!
    pass: String!
  }
  input LoginWithTokenInput {
    userId: ID!
    token: String!
  }
`

export const typeDefs = localTypeDefs + mockTypeDefs

export const defaults = {
  ROOT_QUERY: {
    activeUser: {
      __typename: 'ActiveUser',
      id: null,
    },
    transactionsDate: '',
  },
}

interface SetUserInput {
  id: string | number
}

const randomResponse = (delayMultiplier = 1) => async () => {
  const delayTime = Math.random() * 1000 * delayMultiplier
  await new Promise((resolve) => setTimeout(resolve, delayTime))
  const success = Math.random() > 0.5
  if (!success) {
    throw new ApolloError({ errorMessage: 'mock-error' })
  }
  return {
    __typename: 'ServerResponse',
    success,
  }
}

export const resolvers = {
  Mutation: {
    /* Mock mutation start */
    updateLocationCategory: randomResponse(),
    addExceptionalSaleTimes: randomResponse(5),
    updateExceptionalSaleTimes: randomResponse(10),
    removeExceptionalSaleTimes: randomResponse(10),
    createProfile: randomResponse(10),
    createSharedAccount: randomResponse(2),
    updateProfile: randomResponse(5),
    /* Mock end */
    setUser: (
      _: string,
      { id }: SetUserInput,
      { cache }: { cache: ApolloCache<{ _: string }> }
    ): boolean => {
      cache.modify({
        fields: {
          activeUser: () => {
            return {
              id: `${id}`,
            }
          },
        },
      })
      return true
    },
  },
  ...mockResolvers,
}
