/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AchievementKind {
  MILESTONE = "MILESTONE",
  RECURRING_EVENT = "RECURRING_EVENT",
  STREAK = "STREAK",
}

export enum AchievementTarget {
  LOCATION = "LOCATION",
  PROFILE = "PROFILE",
}

export enum ActionToggle {
  ACTIVATE = "ACTIVATE",
  DEACTIVATE = "DEACTIVATE",
}

export enum AllergenTagKey {
  ALLIUM = "ALLIUM",
  CASEIN = "CASEIN",
  CASHEW = "CASHEW",
  CELERY = "CELERY",
  CRUSTACEAN = "CRUSTACEAN",
  EGG = "EGG",
  FISH = "FISH",
  GLUTEN = "GLUTEN",
  LACTOSE = "LACTOSE",
  LEGUMES = "LEGUMES",
  LUPIN = "LUPIN",
  MOLLUSC = "MOLLUSC",
  MUSTARD = "MUSTARD",
  NO_ALLERGENS = "NO_ALLERGENS",
  NUTS = "NUTS",
  PEANUTS = "PEANUTS",
  PEAS = "PEAS",
  SESAME = "SESAME",
  SHELLFISH = "SHELLFISH",
  SOYA = "SOYA",
  SULPHITES = "SULPHITES",
  WHEAT = "WHEAT",
}

export enum CategoryTagKey {
  BREAD = "BREAD",
  DRINK = "DRINK",
  FULL_MEAL = "FULL_MEAL",
  GROCERY = "GROCERY",
  SNACK = "SNACK",
  SWEETS = "SWEETS",
}

export enum CompanyPlan {
  BASIC = "BASIC",
  PRO = "PRO",
}

export enum DayOfWeek {
  friday = "friday",
  monday = "monday",
  saturday = "saturday",
  sunday = "sunday",
  thursday = "thursday",
  tuesday = "tuesday",
  wednesday = "wednesday",
}

export enum DisplayUnit {
  G = "G",
  HG = "HG",
  KG = "KG",
}

export enum ItemBadge {
  FAST_SELLERS = "FAST_SELLERS",
  HIGHLY_RATED = "HIGHLY_RATED",
  NEWLY_ADDED = "NEWLY_ADDED",
  POPULAR = "POPULAR",
}

export enum ItemVariantType {
  DELIVERY = "DELIVERY",
  GENERIC = "GENERIC",
  TIP = "TIP",
  WEIGHTED = "WEIGHTED",
}

export enum KarmaBasicLocationCategory {
  BAKERY = "BAKERY",
  BUFFET_RESTAURANT = "BUFFET_RESTAURANT",
  BURGERS = "BURGERS",
  BUTCHER = "BUTCHER",
  COFFEE_SHOP = "COFFEE_SHOP",
  FISH_MONGER = "FISH_MONGER",
  GROCERY_STORE = "GROCERY_STORE",
  JUICE_BAR = "JUICE_BAR",
  POKE = "POKE",
  READY_TO_EAT_CAFE = "READY_TO_EAT_CAFE",
  RESTAURANT = "RESTAURANT",
  SUSHI = "SUSHI",
  VEGAN_CAFE = "VEGAN_CAFE",
}

export enum KarmaScoreCategory {
  CREATE = "CREATE",
  SELL = "SELL",
  START = "START",
}

export enum KarmaScoreTaskKey {
  ALL_ITEMS_HAVE_ALLERGENS = "ALL_ITEMS_HAVE_ALLERGENS",
  ALL_ITEMS_HAVE_IMAGES = "ALL_ITEMS_HAVE_IMAGES",
  BUSINESS_INFO_SET = "BUSINESS_INFO_SET",
  CONTACT_INFO_FILLED = "CONTACT_INFO_FILLED",
  INVENTORY_COUNT_OVER_THRESHOLD = "INVENTORY_COUNT_OVER_THRESHOLD",
  OPENING_HOURS_SET = "OPENING_HOURS_SET",
  PICKUP_TIME_THRESHOLD = "PICKUP_TIME_THRESHOLD",
  SALES_PER_MONTH_THRESHOLD = "SALES_PER_MONTH_THRESHOLD",
  SALE_STREAK = "SALE_STREAK",
  SALE_TIME_THRESHOLD = "SALE_TIME_THRESHOLD",
}

export enum Language {
  en = "en",
  fr = "fr",
  sv = "sv",
}

export enum LocationImageField {
  FEATURE = "FEATURE",
  LOGO = "LOGO",
}

export enum MetricUnit {
  COUNT = "COUNT",
  CURRENCY = "CURRENCY",
  GRAM_CO2 = "GRAM_CO2",
  GRAM_FOOD = "GRAM_FOOD",
  OTHER_NUMBER = "OTHER_NUMBER",
  PERCENTAGE = "PERCENTAGE",
}

export enum PaymentFlow {
  open_tab = "open_tab",
  pay_upfront = "pay_upfront",
}

export enum PaymentServiceProvider {
  adyen = "adyen",
  stripe = "stripe",
}

export enum PreferenceTagKey {
  BEEF = "BEEF",
  CHICKEN = "CHICKEN",
  FISH_PREF = "FISH_PREF",
  GLUTEN_FREE = "GLUTEN_FREE",
  LACTOSE_FREE = "LACTOSE_FREE",
  PORK = "PORK",
  SHELLFISH_PREF = "SHELLFISH_PREF",
  VEGAN = "VEGAN",
  VEGETARIAN = "VEGETARIAN",
}

export enum ProfileEventType {
  APP_LOADED = "APP_LOADED",
}

export enum SalePeriod {
  AFTERNOON = "AFTERNOON",
  BREAKFAST = "BREAKFAST",
}

export enum SalePlannedAction {
  DONT_START = "DONT_START",
  START = "START",
}

export enum Status {
  closed = "closed",
  delivering = "delivering",
  open = "open",
  paused = "paused",
  undecided = "undecided",
  waiting = "waiting",
}

export enum TestGroup {
  CONTROL = "CONTROL",
  VARIANT = "VARIANT",
}

export enum UrlType {
  custom_account_update = "custom_account_update",
  custom_account_verification = "custom_account_verification",
}

export enum UserRole {
  ADMIN = "ADMIN",
  KARMELEON = "KARMELEON",
  LIMITED_SINGLE_STORE_MANAGER = "LIMITED_SINGLE_STORE_MANAGER",
  SINGLE_STORE_MANAGER = "SINGLE_STORE_MANAGER",
  STAFF_MEMBER = "STAFF_MEMBER",
}

export interface AccountInput {
  phone?: string | null;
  email?: string | null;
  name?: string | null;
}

export interface AddLocationToBusinessUnitInput {
  locationIds: string[];
  businessUnitId: string;
}

export interface CreateBusinessUnitInput {
  companyId: string;
  locationId: string;
  name: string;
}

export interface CreateExternalPayoutAccountInput {
  payoutAccountId: string;
  accountNumber: string;
  routingNumber?: string | null;
  clearingNumber?: string | null;
}

export interface CreatePayoutAccountInput {
  businessUnitId?: string | null;
  locationId?: string | null;
  statement?: string | null;
  vatId?: string | null;
  taxId?: string | null;
  name?: string | null;
  kind?: PaymentServiceProvider | null;
}

export interface CustomTagHandleInput {
  locationId: string;
  itemId: string;
  tags: CustomTagHandleInputTag[];
}

export interface CustomTagHandleInputTag {
  key: string;
  categoryId: string;
}

export interface CustomTagSearchInput {
  searchTerm: string;
  categoryId: string;
  locationId: string;
}

export interface ExceptionalSaleTimesInput {
  pickup_start_local: ScalarHourMinute;
  pickup_end_local: ScalarHourMinute;
  sale_start_local: ScalarHourMinute;
  sale_end_local: ScalarHourMinute;
  closed: boolean;
  start_date: ScalarISODate;
  end_date: ScalarISODate;
  name?: string | null;
}

export interface HandleDeliverectImportInput {
  acceptedItems: HandleDeliverectImportInputAccepted[];
  rejectedItems: HandleDeliverectImportInputRejected[];
}

export interface HandleDeliverectImportInputAccepted {
  importId: string;
  category: CategoryTagKey;
}

export interface HandleDeliverectImportInputRejected {
  importId: string;
}

export interface Image {
  name: string;
  type: string;
  url: string;
  size: number;
}

export interface ImageObjectInput {
  name: string;
  type: string;
  url: string;
  size: number;
}

export interface InventoryQueryInput {
  offset: number;
  limit: number;
  includeFullpriceItems?: boolean | null;
  queryString?: string | null;
}

export interface ItemBucketInput {
  bucketId: string;
  itemId: string;
  itemOrder?: number | null;
}

export interface ItemInput {
  id?: string | null;
  title: string;
  isAlcohol?: boolean | null;
  ingredients?: string | null;
  discountPercentage: number;
  basePrice: number;
  imageUrl?: string | null;
  images?: (Image | null)[] | null;
  vatId?: string | null;
  tags?: number[] | null;
  category?: CategoryTagKey | null;
  preferences?: PreferenceTagKey[] | null;
  allergens?: AllergenTagKey[] | null;
  barcode?: string | null;
  ean?: string | null;
  variant?: ItemVariantInput | null;
  autosaleAmount?: number | null;
  fullPrice?: boolean | null;
  customTags?: CustomTagHandleInputTag[] | null;
  madeToOrder?: boolean | null;
  inBuckets?: string[] | null;
  itemBuckets?: (ItemBucketInput | null)[] | null;
  variantGroupIds?: string[] | null;
  printerGroupIds?: ScalarUUID[] | null;
}

export interface ItemVariantInput {
  type: ItemVariantType;
  displayUnit?: DisplayUnit | null;
}

export interface KarmaBasicItemUpdate {
  description: string;
  allergens: AllergenTagKey[];
  preferences: PreferenceTagKey[];
  priceOptionId: string;
  autosaleAmount?: number | null;
}

export interface LocationImageUpload {
  imageUpload: ScalarUpload;
  field: LocationImageField;
}

export interface LocationInvitationInput {
  email: string;
  role_id: string;
}

export interface LocationSettingsInput {
  phone?: string | null;
  pickupInformation?: string | null;
  website?: string | null;
  description?: string | null;
  defaultPreparationTimeSeconds?: number | null;
  paymentFlow?: PaymentFlow | null;
  autoacceptTakeaway?: boolean | null;
  smsNotifications?: boolean | null;
}

export interface LoginInput {
  name: string;
  pass: string;
}

export interface LoginWithTokenInput {
  userId: string;
  token: string;
}

export interface MatabasInputImage {
  id?: string | null;
  type?: string | null;
}

export interface MatabasInputProductData {
  title?: string | null;
  description?: string | null;
  category?: string | null;
  preferences?: (string | null)[] | null;
  allergens?: (string | null)[] | null;
  ean?: string | null;
  producer?: string | null;
  ingredients?: (string | null)[] | null;
  image?: MatabasInputImage | null;
  fullPrice?: number | null;
  discount?: number | null;
}

export interface NewSaleItemInput {
  itemId: string;
  addedCount: number;
  weight?: number | null;
  expiryDateIsoString?: string | null;
  type: ItemVariantType;
  displayUnit?: DisplayUnit | null;
}

export interface ProfileEvent {
  type: ProfileEventType;
}

export interface ProfileInput {
  name: string;
  profilePicture?: ImageObjectInput | null;
}

export interface RemoveLocationFromBusinessUnitInput {
  locationIds: string[];
  businessUnitId: string;
}

export interface SaleItem {
  id: string;
  count: number;
  date?: string | null;
  period?: SalePeriod | null;
  weight?: number | null;
  type?: ItemVariantType | null;
  displayUnit?: DisplayUnit | null;
}

export interface SaleItemsInput {
  items: (SaleItem | null)[];
}

export interface SaleReminderSettingsInput {
  locationId?: string | null;
  activeDays?: (DayOfWeek | null)[] | null;
  sendPush: boolean;
}

export interface SaleTimesInput {
  weekday: DayOfWeek;
  pickup_start_local: ScalarHourMinute;
  pickup_end_local: ScalarHourMinute;
  sale_start_local: ScalarHourMinute;
  sale_end_local: ScalarHourMinute;
  closed: boolean;
  period?: SalePeriod | null;
}

export interface TransactionStatusInput {
  pickedUp?: boolean | null;
}

export interface UpdateLocationPayoutAccountInput {
  payoutAccountId: string;
  locationId: string;
  statement?: string | null;
}

export interface UpdatePayoutAccountInput {
  id: string;
  vatId?: string | null;
  taxId?: string | null;
  name?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
