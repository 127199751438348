import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import { IntlContext } from '../../providers/IntlProviderWrapper'
import { Globe } from '../../design-system/icons/globe'
import { useTheme } from 'styled-components/macro'
import { LocaleSelect, LocaleSelectWrapper } from './styles'

const LocaleSelector = () => {
  const { formatMessage, locale } = useIntl()
  const theme = useTheme()
  const intlContext = useContext(IntlContext)
  const changeLocale = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (intlContext.loading) {
      return
    }
    intlContext.setLocale(event.target.value)
  }
  const supportedLocales = intlContext.supportedLocales
  return (
    <LocaleSelectWrapper>
      <Globe color={theme.v3.colors.black} size={18} />
      <LocaleSelect onChange={changeLocale} defaultValue={locale}>
        <option value={locale}>{formatMessage({ id: locale })}</option>
        {supportedLocales.map((lang: string, key: number) => {
          if (lang === locale) return false
          return (
            <option value={lang} key={key}>
              {formatMessage({ id: lang })}
            </option>
          )
        })}
      </LocaleSelect>
    </LocaleSelectWrapper>
  )
}

export default LocaleSelector
