import { gql } from '@apollo/client'

export const GET_ACTIVE_LOCATION_MENU = gql`
  query getActiveLocationMenu {
    current {
      location {
        id
        name
        logo_image_url
        feature_image_url
        city
        address
        no_of_followers
      }
    }
  }
`

export const GET_LOCATIONS = gql`
  query getLocations {
    access {
      locations {
        name
        active
        id
        logo_image_url
        address
        city
      }
    }
  }
`
