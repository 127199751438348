import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;
`
export const Icon = styled.img`
  height: 16px;
  width: 16px;
  padding-right: 5px;
`
