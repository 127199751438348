import React from 'react'
import { getSvgProps } from './utils'
import { DesignSystemIcon } from './types'

export const Drink: DesignSystemIcon = (props) => {
  return (
    <svg viewBox="0 0 30 30" {...getSvgProps(props)}>
      <g clipPath="url(#clip0)">
        <path
          d="M20.0254 -0.00390579C19.9351 -0.00496735 19.8451 0.00620184 19.7578 0.0292973L15.7578 1.0293C15.5817 1.0732 15.4209 1.16419 15.2925 1.29253C15.1642 1.42086 15.0732 1.58171 15.0293 1.75781L14.2188 5H7C6.448 5 6 5.448 6 6V8H24V6C24 5.448 23.552 5 23 5H16.2812L16.8242 2.82422L20.2422 1.9707C20.4809 1.91567 20.6911 1.77492 20.833 1.57517C20.9748 1.37541 21.0384 1.13054 21.0117 0.887016C20.9849 0.643489 20.8697 0.418248 20.6879 0.254025C20.5061 0.0898009 20.2704 -0.00198006 20.0254 -0.00390579ZM7 10L8.79297 25.2344C8.91197 26.2414 9.7643 27 10.7793 27H19.2227C20.2367 27 21.089 26.2414 21.207 25.2344L23 10H7ZM10.2305 14.0117C10.4832 14.0242 10.7424 14.0605 11.0059 14.127C12.4349 14.487 13.5883 15.6606 13.9023 17.1016C14.4363 19.5536 12.7188 21.7356 10.4238 21.9766L9.49023 14.043C9.73073 14.0105 9.97772 13.9992 10.2305 14.0117Z"
          fill={props.color}
          style={{ stroke: 'transparent' }}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="30" height="30" fill="white" stroke="transparent" />
        </clipPath>
      </defs>
    </svg>
  )
}
