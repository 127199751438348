import React from 'react'
import { LoadingContainer } from './styles'
import { Spinner } from 'design-system/Spinner'
import { NewColor } from 'design-system/theme/newColors'

const Loading = ({ color }: { color?: NewColor }) => {
  return (
    <LoadingContainer>
      <Spinner
        loading
        type="circle"
        style={{ width: 50, height: 50 }}
        color={color || 'black'}
      />
    </LoadingContainer>
  )
}

export default Loading
