import styled from 'styled-components/macro'

export const ActiveLocationWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${({ theme }) => theme.v4.margins.content.large};
`

export const ActiveLocationContact = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.v4.margins.content.small};
`

export const ActiveLocationLogo = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 5000px;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const ActiveLocationNoImage = styled.div`
  flex: 1;
  display: flex;
  background-color: ${({ theme }) => theme.v3.colors.plum};
  justify-content: center;
  align-items: center;
`

export const ActiveLocationTitles = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.v4.margins.content.small};
  flex: 1;
`
