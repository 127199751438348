import React, { FC } from 'react'
import * as paths from 'routes/paths'
import { NavBarItem } from 'components/Navbar/NavBarItem'
import { ICON_STYLE } from 'components/Navbar/Navs'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { faStore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  navigateTo: (to: string) => void
}

const NavBarSalePlanner: FC<Props> = ({ navigateTo }) => {
  const { pathname } = useLocation()
  const intl = useIntl()
  return (
    <NavBarItem
      isActive={pathname === paths.SALES_PLANNER}
      onClick={() => navigateTo(paths.SALES_PLANNER)}
      icon={<FontAwesomeIcon icon={faStore} style={ICON_STYLE} />}
      label={intl.formatMessage({
        id: 'navbar.sale',
      })}
    />
  )
}

export { NavBarSalePlanner }
