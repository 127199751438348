import React from 'react'
import { getSvgProps } from './utils'
import { DesignSystemIcon } from './types'

export const Bread: DesignSystemIcon = (props) => {
  return (
    <svg viewBox="0 0 30 30" {...getSvgProps(props)}>
      <path
        d="M13 4C10.4894 4 8.20209 4.08344 6.41992 4.47656C5.52884 4.67312 4.75275 4.93863 4.11523 5.40625C3.47772 5.87387 3 6.65265 3 7.5C3 8.55545 3.53114 9.12269 4 9.48438V24C4 25.0931 4.90694 26 6 26H20H24C25.103 26 26 25.103 26 24V9.19336C26.664 8.71136 27 8.143 27 7.5C27 4.737 20.714 4 17 4H13ZM13 6C15.4604 6 17.6734 6.10431 19.1484 6.42969C19.886 6.59238 20.4279 6.81909 20.7012 7.01953C20.9744 7.21997 21 7.28435 21 7.5C21 7.89088 20.5781 8.09375 20.5781 8.09375C20.4055 8.1741 20.2594 8.30206 20.157 8.4626C20.0545 8.62314 20.0001 8.80958 20 9V24H6V9C6.00014 8.805 5.94327 8.61422 5.83639 8.45113C5.72951 8.28803 5.57728 8.15974 5.39844 8.08203C5.39844 8.08203 5 7.93902 5 7.5C5 7.28435 5.02556 7.21997 5.29883 7.01953C5.5721 6.81909 6.11402 6.59238 6.85156 6.42969C8.32664 6.10431 10.5396 6 13 6ZM10.5 10C10.1022 10 9.72064 10.158 9.43934 10.4393C9.15804 10.7206 9 11.1022 9 11.5C9 11.8978 9.15804 12.2794 9.43934 12.5607C9.72064 12.842 10.1022 13 10.5 13C10.8978 13 11.2794 12.842 11.5607 12.5607C11.842 12.2794 12 11.8978 12 11.5C12 11.1022 11.842 10.7206 11.5607 10.4393C11.2794 10.158 10.8978 10 10.5 10ZM16 13C15.7348 13 15.4804 13.1054 15.2929 13.2929C15.1054 13.4804 15 13.7348 15 14C15 14.2652 15.1054 14.5196 15.2929 14.7071C15.4804 14.8946 15.7348 15 16 15C16.2652 15 16.5196 14.8946 16.7071 14.7071C16.8946 14.5196 17 14.2652 17 14C17 13.7348 16.8946 13.4804 16.7071 13.2929C16.5196 13.1054 16.2652 13 16 13ZM11 18C10.7348 18 10.4804 18.1054 10.2929 18.2929C10.1054 18.4804 10 18.7348 10 19C10 19.2652 10.1054 19.5196 10.2929 19.7071C10.4804 19.8946 10.7348 20 11 20C11.2652 20 11.5196 19.8946 11.7071 19.7071C11.8946 19.5196 12 19.2652 12 19C12 18.7348 11.8946 18.4804 11.7071 18.2929C11.5196 18.1054 11.2652 18 11 18Z"
        style={{ stroke: 'transparent' }}
        fill={props.color}
      />
    </svg>
  )
}
