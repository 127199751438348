import { withProfiler } from '@sentry/react'
import React, { useEffect } from 'react'
import { Provider } from './providers/Provider'

const App = () => {
  useEffect(() => {
    window.analytics.track('app_loaded', {
      path: window.location.pathname,
    })
  }, [])
  return <Provider />
}

export default withProfiler(App)
