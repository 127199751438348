import styled from 'styled-components/macro'

export const SelectProfileHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.v4.margins.typography.headings.extraLarge} 0;
  > h1 {
    text-align: center;
  }
`

export const SelectProfileLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: 96px;
  overflow: hidden;
  border-radius: 5000px;
  margin-bottom: ${({ theme }) => theme.v4.margins.content.large};
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`

export const SelectProfiles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

export const SelectProfileWrapper = styled.div`
  width: 160px;
  margin: ${({ theme }) => theme.v4.margins.content.extraLarge};
  ${({ theme }) => theme.v3.breakpoints.small.mediaQuery} {
    margin: ${({ theme }) => theme.v4.margins.content.medium};
  }
`

export const SelectProfileAdd = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.v4.margins.content.large} 0;
  & > * {
    max-width: 200px;
  }
`

export const ProfileSelectHeader = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  padding: ${({ theme }) => theme.v4.margins.content.medium} 0;
`

export const ProfileInitials = styled.div`
  background-color: ${({ theme }) => theme.v3.colors.white};
  border-radius: 5000px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px;
  width: 88px;
  > * {
    filter: brightness(0.5);
  }
`

export const ProfileSelectFullName = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`
