import { randomIntFromInterval } from 'utils/numberUtils'

export const randomString = ({ length = 7, suffix = '' } = {}) => {
  return `${Math.random().toString(36).substring(length)}${
    suffix && `_${suffix}`
  }`
}

export const randomSentence = (
  {
    amountOfWords,
    wordLength,
  }: {
    amountOfWords: number
    wordLength?: number
  } = { amountOfWords: 10 }
) => {
  return Array.from(Array(amountOfWords))
    .map(() =>
      randomString({ length: wordLength ?? randomIntFromInterval(5, 10) })
    )
    .join(' ')
}

export const randomId = () => {
  return Math.random().toString(36).substring(7)
}

export const randomTruth = (probability?: number) => {
  return Math.random() > (probability ?? 0.5)
}

export const randomImage = () => {
  const randomId = Math.random() * 19999
  return `https://source.unsplash.com/featured/?food,${randomId}`
}
