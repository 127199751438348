import { useContext, useEffect } from 'react'
import { useHasCompletedTutorial } from './useHasCompletedTutorial'
import { Context } from '../TutorialProvider'
import { Tutorial } from '../tutorialReducer'

interface useProps {
  requirements: boolean
  tutorial: Tutorial
}
export const useTutorial = ({ requirements, tutorial }: useProps) => {
  const { addTutorial, loading, completeTutorial } = useContext(Context)
  const hasCompletedTutorial = useHasCompletedTutorial(tutorial.id)
  useEffect(() => {
    if (loading) {
      return
    }
    if (!requirements) {
      return
    }
    if (hasCompletedTutorial) {
      return
    }
    addTutorial({ tutorial })
  }, [addTutorial, hasCompletedTutorial, loading, requirements, tutorial])
  const saveTutorial = () => {
    if (hasCompletedTutorial) {
      return
    }
    completeTutorial({ id: tutorial.id })
  }
  return {
    hasCompletedTutorial,
    loading,
    saveTutorial,
  }
}
